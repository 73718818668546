import styled, { css } from "styled-components"
import { AnimatePresence, motion } from "framer-motion"
import { Chip, CircularProgress } from "@material-ui/core"
import { theme } from "../../../theme"
import { Done, Error } from "@material-ui/icons"
import { useShowProgressNotification } from "../../hooks/useShowProgressNotification"
import { useLocale } from "../../hooks/useLocale"
import { introductionsElementIds } from "../../../customerportal/components/Introduction"

export type NavbarProgressNotificationProps = {
  className?: string
  color?: "default" | "primary"
}

export const NavbarProgressNotification = (props: NavbarProgressNotificationProps) => {
  const { className, color = "default" } = props
  const notification = useShowProgressNotification()
  const loc = useLocale()

  return (
    <AnimatePresence exitBeforeEnter>
      {notification.isSaving && (
        <ChipProgress
          key={notification.status}
          id={introductionsElementIds.encounterDetails.progressNotificationChip}
          className={className}
          label={loc.oneportal.navbarProgressNotification.saving.get()}
          icon={
            <IconWrapper>
              <ProgressIcon size={10} />
            </IconWrapper>
          }
          $color={color}
        />
      )}

      {notification.isSaved && (
        <ChipProgress
          key={notification.status}
          id={introductionsElementIds.encounterDetails.progressNotificationChip}
          className={className}
          label={loc.oneportal.navbarProgressNotification.saved.get()}
          icon={
            <IconWrapper>
              <SuccessIcon />
            </IconWrapper>
          }
          $color={color}
        />
      )}

      {notification.isError && (
        <ChipProgress
          key={notification.status}
          id={introductionsElementIds.encounterDetails.progressNotificationChip}
          className={className}
          label={loc.oneportal.navbarProgressNotification.error.get()}
          icon={
            <IconWrapper>
              <ErrorIcon />
            </IconWrapper>
          }
          $color={color}
        />
      )}
    </AnimatePresence>
  )
}

const ChipProgress = styled(motion(Chip))<{ $color: "default" | "primary" }>`
  background-color: ${theme.color.black5};
  color: ${theme.color.white3};
  height: ${theme.fontSize.l};
  font-size: ${theme.fontSize.s};
  font-family: ${theme.font.regular};

  ${(p) =>
    p.$color === "primary" &&
    css`
      background-color: ${theme.color.blue9};
      color: ${theme.color.primary};

      svg {
        color: ${theme.color.primary};
      }
    `}
`

const ProgressIcon = styled(motion(CircularProgress))`
  margin-left: ${theme.spacing.m};
  color: ${theme.color.white3};
`

const IconWrapper = styled(motion.div).attrs((props) => ({
  ...props,
  initial: { opacity: 0 },
  animate: { opacity: 1 },
}))`
  display: flex;
`

const SuccessIcon = styled(Done)`
  color: ${theme.color.white3};
  font-size: inherit;
  margin-left: ${theme.spacing.m};
`

const ErrorIcon = styled(Error)`
  color: ${theme.color.white3};
  font-size: inherit;
  margin-left: ${theme.spacing.m};
`

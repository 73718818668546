import { InvoiceType } from "../types/InvoiceType"
import { OnePortalDocument } from "../types/OnePortalDocument"
import { OnePortalInvoice } from "../types/OnePortalInvoice"

export const createInvoiceResponse = (documents: OnePortalDocument[]): OnePortalInvoice[] =>
  documents.map((d) => ({
    ...d,
    invoiceType: d.metadata ? InvoiceType[d.metadata.invoiceType] : InvoiceType.UNKNOWN,
    price: d.metadata?.invoiceTotalAmount ? parseFloat(d.metadata?.invoiceTotalAmount) : undefined,
  }))

import { ApiClient } from "../../../oneportal/services/api/ApiClient"
import { UserRegistrationRequestDTO } from "./types/requests/UserRegistrationRequestDTO"
import { AxiosResponse } from "axios"

export const createCustomerPortalNoAuthApiClient = (api: ApiClient) => {
  return {
    requestUserRegistration: requestUserRegistration(api),
  }
}

const requestUserRegistration = (api: ApiClient) => (
  request: UserRegistrationRequestDTO
): Promise<AxiosResponse<void>> => {
  return api.post<void>("/users/registration", toFormData(request))
}

const toFormData = (request: UserRegistrationRequestDTO) => {
  const formData = new FormData()

  Object.entries(request).forEach(([key, val]) => {
    if (key === "taxExemptFile" && !!val) {
      val = val as File
      formData.append("file", val, val.name)
    } else {
      formData.append(key, toString(val as string | boolean | undefined))
    }
  })

  return formData
}

const toString = (val: string | boolean | undefined): string => {
  if (val === undefined) return ""
  if (typeof val === "string") return val
  return val ? "true" : "false"
}

import { useState } from "react"
import { ProgressStatus } from "../../../helpers/types/ProgressStatus"
import { ObservableForm } from "../../../libraries/form"

export const useInputPopover = <TValue = any>(
  value: TValue | null,
  form: ObservableForm,
  onChange: (newValue: TValue) => void = () => null,
  onSubmit: (newValue: TValue) => Promise<void> = async () => undefined
) => {
  const [status, setStatus] = useState<ProgressStatus>({})
  const [anchorEl, setAnchorEl] = useState(null)

  const open = !!anchorEl

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget)
  }

  const handleChange = (newValue: TValue) => onChange(newValue)

  const handleChangeAndClose = async (newValue: TValue) => {
    setAnchorEl(null)

    const validationResult = await form.validate({ changed: true })

    if (newValue === value || !!validationResult) {
      return
    }

    try {
      setStatus({ loading: true })
      onChange(newValue)

      await onSubmit(newValue)

      setStatus({ success: true })
      clearFormAndClose()
    } catch (err) {
      setStatus({ error: true })
    }
  }

  const handleClose = () => {
    clearFormAndClose()
  }

  const clearFormAndClose = () => {
    form.setChangedFields([])
    form.clearErrors()
    setAnchorEl(null)
  }

  return {
    value,
    status,
    open,
    anchorEl,
    handleClick,
    handleClose,
    handleChange,
    handleChangeAndClose,
  }
}

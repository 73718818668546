import styled from "styled-components"
import { PreferencesInterfaceForm } from "../../../customerportal/components/PreferencesInterfaceForm/PreferencesInterfaceForm"
import {
  UpdateNotificationsForm,
  UpdateNotificationsFormProps,
} from "../../../customerportal/components/UpdateNotificationsForm/UpdateNotificationsForm"
import { theme } from "../../../theme"
import { useLocale } from "../../hooks/useLocale"

export const NotificationAndPreferencesSection = (props: UpdateNotificationsFormProps) => {
  const { isValidating, onValidate } = props
  const loc = useLocale()

  const handleValidateForm = (isValid: boolean) => {
    if (onValidate) onValidate(isValid)
  }

  return (
    <>
      <PreferencesSection>
        <SectionTitle>{loc.customerportal.myPreferences.labels.notifications.get()}</SectionTitle>
        <SectionDescription>{loc.customerportal.myPreferences.labels.notificationMessage.get()}</SectionDescription>
        <UpdateNotificationsForm isValidating={isValidating} onValidate={handleValidateForm} />
      </PreferencesSection>
      <PreferencesSection>
        <SectionTitle>{loc.customerportal.myPreferences.labels.generalPreferences.get()}</SectionTitle>
        <PreferencesInterfaceForm isLanguageEnabled={false} />
      </PreferencesSection>
    </>
  )
}

const PreferencesSection = styled.section``

const SectionTitle = styled.div`
  color: ${theme.color.black1};
  font-size: ${theme.fontSize.xl};
  font-family: ${theme.font.bold};
`

const SectionDescription = styled.div`
  color: ${theme.color.black4};
  font-size: ${theme.fontSize.s};
  margin-top: ${theme.spacing.m};
`

import { CheckCircle } from "@material-ui/icons"
import { ToggleButton, ToggleButtonGroup } from "@material-ui/lab"
import styled, { css, keyframes } from "styled-components"
import { theme } from "../../../theme"
import { useLocale } from "../../hooks/useLocale"
import { TextSmall } from "../text/TextSmall"

export type OrganisationNetworkSwitchProps = {
  isVisible: boolean
  disabled: boolean
  onChange: (val: boolean) => void
}

export const OrganisationNetworkSwitch = (props: OrganisationNetworkSwitchProps) => {
  const { isVisible, disabled, onChange } = props
  const loc = useLocale()
  const myLoc = loc.oneportal.newUserPreferenceStep2.labels

  return (
    <OrganisationNetworkToggle
      $disabled={disabled}
      value={isVisible}
      exclusive
      onChange={(_, value) => typeof value === "boolean" && onChange(value)}
    >
      <VisibleButton value={true}>
        <CheckCircle />
        <TextSmall>{myLoc.visibleInNetwork.get()}</TextSmall>
      </VisibleButton>
      <NotVisibleButton value={false}>
        <TextSmall>{myLoc.notVisibleInNetwork.get()}</TextSmall>
      </NotVisibleButton>
    </OrganisationNetworkToggle>
  )
}

const animateRightToLeft = keyframes`
  from {
    transform: translateX(148px);
  }

  to {
    transform: translateX(0px);
  }
`

const animateLeftToRight = keyframes`
  from {
    transform: translateX(-148px);
  }

  to {
    transform: translateX(0px);
  }
`

const OrganisationNetworkToggle = styled(ToggleButtonGroup)<{ $disabled: boolean }>`
  border-radius: 20px;
  background-color: ${theme.color.white1};
  display: flex;
  justify-content: center;
  height: 40px;
  white-space: nowrap;
  min-width: 168px;
  ${(p) =>
    p.$disabled === true &&
    css`
      opacity: 0.6;
      pointer-events: none;
    `}
`

const DeviceButton = styled(ToggleButton)`
  width: 50%;
  border-radius: 20px !important;
  border: 2px solid transparent !important;
  background-color: transparent;
  color: ${theme.color.black4};
  text-transform: unset;
  margin: 0;
  box-sizing: content-box;

  &.Mui-selected {
    background-color: ${theme.color.white};
    color: ${theme.color.black6};
    z-index: ${theme.zIndex.medium};
    box-shadow: ${theme.mui.shadows[4]};
    &:hover {
      background-color: ${theme.color.white};
    }
  }

  .MuiSvgIcon-root {
    font-size: ${theme.fontSize.s};
    margin-right: ${theme.spacing.s};
  }
`

const VisibleButton = styled(DeviceButton)`
  &.Mui-selected {
    color: ${theme.color.success};
    border-color: ${theme.color.success} !important;
    animation: ${animateRightToLeft} 0.2s linear;
  }
`

const NotVisibleButton = styled(DeviceButton)`
  &.Mui-selected {
    border-color: ${theme.color.error} !important;
    color: ${theme.color.error};
    animation: ${animateLeftToRight} 0.2s linear;
  }
`

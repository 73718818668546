export enum ToothPositionFHIR {
  Eighteen = "EIGHTEEN",
  Seventeen = "SEVENTEEN",
  Sixteen = "SIXTEEN",
  Fifteen = "FIFTEEN",
  Fourteen = "FOURTEEN",
  Thirteen = "THIRTEEN",
  Twelve = "TWELVE",
  Eleven = "ELEVEN",
  TwentyOne = "TWENTY_ONE",
  TwentyTwo = "TWENTY_TWO",
  TwentyThree = "TWENTY_THREE",
  TwentyFour = "TWENTY_FOUR",
  TwentyFive = "TWENTY_FIVE",
  TwentySix = "TWENTY_SIX",
  TwentySeven = "TWENTY_SEVEN",
  TwentyEight = "TWENTY_EIGHT",
  FortyEight = "FORTY_EIGHT",
  FortySeven = "FORTY_SEVEN",
  FortySix = "FORTY_SIX",
  FortyFive = "FORTY_FIVE",
  FortyFour = "FORTY_FOUR",
  FortyThree = "FORTY_THREE",
  FortyTwo = "FORTY_TWO",
  FortyOne = "FORTY_ONE",
  ThirtyOne = "THIRTY_ONE",
  ThirtyTwo = "THIRTY_TWO",
  ThirtyThree = "THIRTY_THREE",
  ThirtyFour = "THIRTY_FOUR",
  ThirtyFive = "THIRTY_FIVE",
  ThirtySix = "THIRTY_SIX",
  ThirtySeven = "THIRTY_SEVEN",
  ThirtyEight = "THIRTY_EIGHT",
}

export const toothPositionsFHIR = Object.values(ToothPositionFHIR)
export const toothPositionsFHIRUpperJaw = toothPositionsFHIR.slice(0, 16)
export const toothPositionsFHIRLowerJaw = toothPositionsFHIR.slice(16)

import { useLocale } from "../../../oneportal/hooks/useLocale"
import { createFormFromSchema } from "../../../oneportal/libraries/form"
import { object, value } from "../../../oneportal/libraries/schema"
import { UserPreferenceRecipient } from "../../services/api/types/UserPreferenceRecipient"

export type UpdateNotificationsFormType = {
  notifySms: boolean
  phoneNumbers?: UserPreferenceRecipient[]
  notifyEmail: boolean
  emails?: UserPreferenceRecipient[]
  notifyBrowser: boolean
  newPhoneNumber?: string
  newEmail?: string
}

export const createUpdateNotificationsForm = (
  loc: ReturnType<typeof useLocale>,
  initialData?: Partial<UpdateNotificationsFormType>
) => {
  const form = createFormFromSchema<UpdateNotificationsFormType>(
    object<UpdateNotificationsFormType>({
      notifySms: value(initialData?.notifySms ?? false).boolean(),
      notifyEmail: value(initialData?.notifyEmail ?? false).boolean(),
      notifyBrowser: value(initialData?.notifyBrowser ?? false).boolean(),
      phoneNumbers: value(initialData?.phoneNumbers ?? [])
        .array()
        .min(1, loc.customerportal.myPreferences.errors.atLeastOnePhoneNumber.get()),
      emails: value(initialData?.emails ?? [])
        .array()
        .min(1, loc.customerportal.myPreferences.errors.atLeastOneEmail.get()),
      newPhoneNumber: value(initialData?.newPhoneNumber ?? "")
        .string()
        .optional()
        .phoneNumber(loc.customerportal.myPreferences.errors.invalidPhoneFormat.get())
        .noneOf(() => form.get().phoneNumbers.map((r) => r.phoneNumber)),
      newEmail: value(initialData?.newEmail ?? "")
        .string()
        .optional()
        .email(loc.customerportal.myPreferences.errors.invalidEmailFormat.get())
        .noneOf(() => form.get().emails.map((r) => r.email)),
    })
  )

  return form
}

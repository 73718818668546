import { i18n, Resource, TOptions } from "i18next"

import en from "../locales/en.json"
import fr from "../locales/fr.json"
import { createAccessor } from "./oneportal/libraries/accessor/createAccessor"
import { AppConfig } from "./config"

export const getAppLocales = (config: AppConfig): Resource => {
  let languages = {
    en: { translation: en },
  }

  if (config.featureFlag.languageFrench) {
    languages["fr"] = { translation: fr }
  }

  return languages
}

export const createAccessibleLocale = (translator: i18n) => {
  return createAccessor(en, (source, key, options?: TOptions) => {
    return translator.t(key?.toString() ?? "-", options)
  })
}

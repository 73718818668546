import { Dialog, DialogContent, Grid } from "@material-ui/core"
import { useState } from "react"
import styled from "styled-components"
import { UserRole } from "../../../customerportal/services/api/types/UserRole"
import { theme } from "../../../theme"
import { useLocale } from "../../hooks/useLocale"
import { useMsalProfilePermissions } from "../../hooks/useMsalProfilePermissions"
import { useShowApiCallsIndicator } from "../../hooks/useShowApiCallsIndicator"
import { ButtonPrimary } from "../buttons/ButtonPrimary"
import { ButtonPrimaryOutlined } from "../buttons/ButtonPrimaryOutlined"
import { NotificationAndPreferencesSection } from "./NotificationAndPreferencesSection"
import { OrganisationNetworkSection } from "./OrganisationNetworkSection"

export type NewUserPreferencesDialogProps = {
  onClose: () => void
}

interface UserPreferencesStep {
  icon: string
  title: string
  description: string
}

export const NewUserPreferencesDialog = (props: NewUserPreferencesDialogProps) => {
  const { onClose } = props
  const loc = useLocale()
  const [show, setShow] = useState(true)
  const permissions = useMsalProfilePermissions()
  const [activeStep, setActiveStep] = useState(0)
  const [markToValidate, setMarkToValidate] = useState(false)
  const isLoading = useShowApiCallsIndicator().get()

  const showNetworkSettingScreen = permissions.hasRole(UserRole.Admin)
  const STEP_PREFERENCE_SETTING = 0
  const STEP_NETWORK_SETTING = 1

  const steps: UserPreferencesStep[] = [
    {
      icon: "👋",
      title: loc.oneportal.newUserPreference.title.get(),
      description: loc.oneportal.newUserPreference.description.get(),
    },
    ...(showNetworkSettingScreen
      ? [
          {
            icon: "🤝",
            title: loc.oneportal.newUserPreferenceStep2.title.get(),
            description: loc.oneportal.newUserPreferenceStep2.description.get(),
          },
        ]
      : []),
  ]

  const totalSteps = steps.length
  const isLastStep = activeStep === totalSteps - 1

  const handleClose = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation()

    if (activeStep === STEP_PREFERENCE_SETTING) {
      setMarkToValidate(true)
    } else {
      close()
    }
  }

  const handleBack = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation()
    setActiveStep(STEP_PREFERENCE_SETTING)
  }

  const handleNext = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation()
    setMarkToValidate(true)
  }

  const handleValidateNotificationForm = (isValid: boolean) => {
    setMarkToValidate(false)

    if (!!isValid) {
      if (isLastStep) {
        close()
      } else {
        setActiveStep(STEP_NETWORK_SETTING)
      }
    }
  }

  const close = () => {
    setShow(false)
    onClose()
  }

  const renderSection = () => {
    if (isLastStep && showNetworkSettingScreen) {
      return <OrganisationNetworkSection />
    }
    return (
      <NotificationAndPreferencesSection isValidating={markToValidate} onValidate={handleValidateNotificationForm} />
    )
  }

  const renderButtons = () => {
    return (
      <>
        {isLastStep && showNetworkSettingScreen && (
          <ButtonPrimaryOutlined onClick={(e) => handleBack(e)}>
            {loc.oneportal.newUserPreferenceStep2.actions.back.get()}
          </ButtonPrimaryOutlined>
        )}

        {isLastStep && (
          <ButtonPrimary onClick={(e) => handleClose(e)} disabled={isLoading}>
            {loc.oneportal.newUserPreference.actions.start.get()}
          </ButtonPrimary>
        )}

        {!isLastStep && showNetworkSettingScreen && (
          <ButtonPrimary onClick={(e) => handleNext(e)} disabled={isLoading}>
            {loc.oneportal.newUserPreference.actions.next.get()}
          </ButtonPrimary>
        )}
      </>
    )
  }

  return (
    <Root open={show} disableBackdropClick={true} transitionDuration={theme.transitionDuration} disableEnforceFocus>
      <DialogContentContainer>
        <Grid container>
          <WelcomeBanner item xs={5}>
            <WelcomeBannerIcon>{steps[activeStep].icon}</WelcomeBannerIcon>
            <WelcomeBannerTitle>{steps[activeStep].title}</WelcomeBannerTitle>
            <WelcomeBannerDescription>{steps[activeStep].description}</WelcomeBannerDescription>
          </WelcomeBanner>

          <Preferences item xs={7} direction="column">
            <PreferencesSectionContainer>{renderSection()}</PreferencesSectionContainer>
            <PreferencesAction>{renderButtons()}</PreferencesAction>
          </Preferences>
        </Grid>
      </DialogContentContainer>
    </Root>
  )
}

const Root = styled(Dialog)`
  .MuiDialog-paper {
    max-width: 100%;
    width: 956px;
    max-height: calc(100% - 64px);
    height: 677px;
  }
`

const DialogContentContainer = styled(DialogContent)`
  padding: 0;
  display: flex;
  &:first-child {
    padding: 0;
  }
`

const WelcomeBanner = styled(Grid)`
  background: ${theme.color.blue2};
  display: flex;
  flex-flow: column;
  justify-content: center;
  padding: ${theme.spacing.lx};
`

const WelcomeBannerIcon = styled.span`
  font-size: ${theme.fontSize.xxl};
  font-family: ${theme.font.bold};
`

const WelcomeBannerTitle = styled.div`
  font-size: ${theme.fontSize.xxl};
  font-family: ${theme.font.bold};
  color: ${theme.color.white};
`

const WelcomeBannerDescription = styled.div`
  font-size: ${theme.fontSize.m};
  margin-top: ${theme.spacing.xs};
  color: ${theme.color.white};
`

const Preferences = styled(Grid)`
  display: grid;
  grid-template-areas:
    "main"
    "footer";
  overflow: hidden;
  width: 100%;
  height: 100%;
  grid-template-rows: auto max-content;
`

const PreferencesSectionContainer = styled(Grid)`
  padding: ${theme.spacing.lx};
  grid-area: main;
  overflow: hidden;
  overflow-y: auto;
  display: flex;
  flex-flow: column;
`

const PreferencesAction = styled(Grid)`
  border-top: 1px solid ${theme.color.white1};
  grid-area: footer;
  padding: ${theme.spacing.lx};
  display: flex;
  justify-content: flex-end;
  gap: 10px;
`

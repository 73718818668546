import { getQueryString } from "../oneportal/helpers/getQueryString"
import { CaseDetailsQuery } from "./pages/types/CaseDetailsQuery"
import { CaseEditOrCheckOutQuery } from "./pages/types/CaseEditOrCheckOutQuery"
import { CaseQuery } from "./pages/types/CaseQuery"
import { CaseInstanceId } from "./services/api/types/tags/CaseInstanceId"
import { DraftId } from "./services/api/types/tags/DraftId"
import { TaskId } from "./services/api/types/tags/TaskId"

export type SiabLinks = ReturnType<typeof getSiabLinks>

// hide links inside a factory to prevent global imports
export const getSiabLinks = () => {
  return {
    cases: (query?: CaseQuery) => `/smile-in-a-box${getQueryString(query)}`,
    caseDetails: (caseId: CaseInstanceId = ":caseId" as CaseInstanceId, query?: CaseDetailsQuery) =>
      `/smile-in-a-box/${caseId}${getQueryString(query)}`,
    caseEditOrCheckout: (
      caseId: CaseInstanceId = ":caseId" as CaseInstanceId,
      taskId: TaskId = ":taskId" as TaskId,
      query?: CaseEditOrCheckOutQuery
    ) => `/smile-in-a-box/${caseId}/tasks/${taskId}${getQueryString(query)}`,
    caseSuccess: (caseId: CaseInstanceId = ":caseId" as CaseInstanceId) => `/smile-in-a-box/${caseId}/success`,

    // deprecated, shouldn't be used anywhere (only accessible from the url bar)
    caseCreateV1: () => "/smile-in-a-box/createv1",

    caseCreateV2: () => "/smile-in-a-box/createv2",
    caseCreate: () => "/smile-in-a-box/create",
    caseCreating: (caseDraftId: DraftId = ":caseDraftId" as DraftId) => `/smile-in-a-box/create/${caseDraftId}`,
    calendar: () => "/calendar",
  }
}

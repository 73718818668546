import { RoutePolicy } from "../types/RoutePolicy"
import { matchRoute } from "./matchRoute"
import { loadComponentForRoute } from "./loadComponentForRoute"
import { RouteDefinition } from "../types/RouteDefinition"
import { RequestToRedirect } from "../types/RequestToRedirect"
import { RequestToLoadRouteForDifferentPath } from "../types/RequestToLoadRouteForDifferentPath"
import { LoadedRouteWithComponentAndPreloader } from "../types/LoadedRouteWithComponentAndPreloader"
import { ComponentType } from "react"
import { GenericLogger } from "../../logger/GenericLogger"

export type LoadRouteForPathArgs<TContext> = {
  routes: RouteDefinition<TContext>[]
  path: string
  policy: RoutePolicy
  requestToRedirect: RequestToRedirect
  homePath: string
  loginPath: string
  NotFound: ComponentType
  logger?: GenericLogger
  context: TContext
}

export const loadRouteForPath = async <TContext>(
  args: LoadRouteForPathArgs<TContext>
): Promise<LoadedRouteWithComponentAndPreloader<TContext>> => {
  const { routes, path, policy, requestToRedirect, logger, homePath, loginPath, NotFound, context } = args
  const availableRoutes = routes.filter((route) => route.check?.(context) ?? true)

  const { route, match } = matchRoute(path, availableRoutes)
  const requestToLoadRouteForDifferentPath: RequestToLoadRouteForDifferentPath = async (path: string) => {
    const loadedRoute = await loadRouteForPath({
      routes: availableRoutes,
      context,
      path,
      policy,
      requestToRedirect,
      logger,
      homePath,
      loginPath,
      NotFound,
    })

    if (loadedRoute.component) {
      return { component: loadedRoute.component, preloader: loadedRoute.preloader }
    }
  }

  const elementWithPreloader = await loadComponentForRoute({
    route,
    policy,
    requestToLoadRouteForDifferentPath,
    requestToRedirect,
    logger,
    homePath,
    loginPath,
    NotFound,
  })

  return { route, component: elementWithPreloader?.component, preloader: elementWithPreloader?.preloader, match }
}

import styled, { css } from "styled-components"
import { theme } from "../../../theme"

export const PageHeader = styled.div<{ $mainPage?: boolean }>`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  padding: ${theme.spacing.xl};
  width: 100%;

  ${theme.mui.breakpoints.up("md")} {
    flex-direction: row;
  }

  ${(p) =>
    p.$mainPage &&
    css`
      box-shadow: 0 0 3px ${theme.color.grey1};
      padding-bottom: ${theme.spacing.xxl};
    `}
`

import { createFormFromSchema } from "../../../oneportal/libraries/form"
import { object, value } from "../../../oneportal/libraries/schema"
import { ToothPositionFormat } from "../../../registry/components/DentalChart/types/ToothPositionFormat"
import { UserLanguage } from "../../services/api/types/UserLanguage"
import { DateFormatPreference } from "../../services/api/types/DateFormatPreference"

export type PreferencesInterfaceFormType = {
  language?: string
  dateFormat?: string
  toothPositionFormat?: string
}

export const createPreferencesInterfaceForm = (initialData?: Partial<PreferencesInterfaceFormType>) => {
  const form = createFormFromSchema<PreferencesInterfaceFormType>(
    object<PreferencesInterfaceFormType>({
      language: value(initialData?.language ?? undefined)
        .string()
        .oneOf(Object.values(UserLanguage)),
      dateFormat: value(initialData?.dateFormat ?? undefined)
        .string()
        .oneOf(Object.values(DateFormatPreference)),
      toothPositionFormat: value(initialData?.toothPositionFormat ?? undefined)
        .string()
        .oneOf(Object.values(ToothPositionFormat)),
    })
  ).handler(() => form.get())

  return form
}

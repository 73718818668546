import { AppConfig } from "../config"
import { MsalProfilePermissions } from "../oneportal/hooks/useMsalProfilePermissions"
import { RouteDefinition } from "../oneportal/libraries/router/types/RouteDefinition"
import { RoutePolicy } from "../oneportal/libraries/router/types/RoutePolicy"
import { ClearCorrectLinks } from "./links"

const privateRoutes = <TContext>(routes: RouteDefinition<TContext>[]): RouteDefinition<TContext>[] =>
  routes.map((route) => ({ ...route, policy: RoutePolicy.ForAuthenticated }))

// hide routes inside a factory to prevent global imports
export const getClearCorrectRoutes = (
  config: AppConfig,
  links: ClearCorrectLinks
): RouteDefinition<MsalProfilePermissions>[] => {
  return [
    ...privateRoutes<MsalProfilePermissions>([
      {
        path: links.clearCorrectCases(),
        load: () => import("./pages/ClearCorrectCases"),
      },
    ]),
  ]
}

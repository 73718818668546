import { MsalProfileCountryCode, useMsalProfile } from "./useMsalProfile"
import {
  useIntegrationFeatureFlag,
  useNetworkFeatureFlag,
  useProfileV2FeatureFlag,
  useSiabAppFeatureFlag,
} from "./useProductionFeatureFlag"
import { UserRole } from "../../customerportal/services/api/types/UserRole"

const EUROPE_COUNTRY_CODES: MsalProfileCountryCode[] = [
  MsalProfileCountryCode.Austria,
  MsalProfileCountryCode.Belgium,
  MsalProfileCountryCode.Bulgaria,
  MsalProfileCountryCode.Croatia,
  MsalProfileCountryCode.Cyprus,
  MsalProfileCountryCode.CzechRepublic,
  MsalProfileCountryCode.Denmark,
  MsalProfileCountryCode.Estonia,
  MsalProfileCountryCode.Finland,
  MsalProfileCountryCode.France,
  MsalProfileCountryCode.Germany,
  MsalProfileCountryCode.Greece,
  MsalProfileCountryCode.Hungary,
  MsalProfileCountryCode.Iceland,
  MsalProfileCountryCode.Ireland,
  MsalProfileCountryCode.Italy,
  MsalProfileCountryCode.Latvia,
  MsalProfileCountryCode.Liechtenstein,
  MsalProfileCountryCode.Lithuania,
  MsalProfileCountryCode.Luxembourg,
  MsalProfileCountryCode.Malta,
  MsalProfileCountryCode.Netherlands,
  MsalProfileCountryCode.Norway,
  MsalProfileCountryCode.Poland,
  MsalProfileCountryCode.Portugal,
  MsalProfileCountryCode.Romania,
  MsalProfileCountryCode.Slovakia,
  MsalProfileCountryCode.Slovenia,
  MsalProfileCountryCode.Spain,
  MsalProfileCountryCode.Sweden,
  MsalProfileCountryCode.Switzerland,
]
const AMERICA_COUNTRY_CODES: MsalProfileCountryCode[] = [
  MsalProfileCountryCode.UnitedStates,
  MsalProfileCountryCode.Canada,
]

const ALLOWED_COUNTRY_CODES_FOR_REGISTRY: MsalProfileCountryCode[] = [...EUROPE_COUNTRY_CODES, ...AMERICA_COUNTRY_CODES]
const ALLOWED_COUNTRY_CODES_FOR_SIAB: MsalProfileCountryCode[] = [...AMERICA_COUNTRY_CODES]
const ALLOWED_COUNTRY_CODES_FOR_DASHBOARD: MsalProfileCountryCode[] = [
  ...EUROPE_COUNTRY_CODES,
  ...AMERICA_COUNTRY_CODES,
]

export type MsalProfilePermissions = {
  showRegistry: boolean
  showSiab: boolean
  showDashboard: boolean
  hasRole: (role: UserRole) => boolean
  hasRoleOrAbove: (role: UserRole) => boolean
  showNetwork: boolean
  showIntegrations: boolean
  showProfileV2: boolean
}

export const useMsalProfilePermissions = (): MsalProfilePermissions => {
  const profile = useMsalProfile()
  const networkFeatureFlag = useNetworkFeatureFlag()
  const integrationsFeatureFlag = useIntegrationFeatureFlag()
  const profileV2FeatureFlag = useProfileV2FeatureFlag()
  const siabAppFeatureFlag = useSiabAppFeatureFlag()

  const hasOneOfRoles = (roles: UserRole[]) => {
    return !!profile?.roles && roles.includes(profile?.roles)
  }

  const hasRole = (role: UserRole) => profile?.roles === role

  const hasRoleOrAbove = (role: UserRole) => {
    switch (role) {
      case UserRole.Staff:
        return hasOneOfRoles([UserRole.Staff, UserRole.Doctor, UserRole.Admin])
      case UserRole.Doctor:
        return hasOneOfRoles([UserRole.Doctor, UserRole.Admin])
      case UserRole.Admin:
        return profile?.roles === role
      default:
        return false
    }
  }

  return {
    showRegistry: ALLOWED_COUNTRY_CODES_FOR_REGISTRY.includes(profile?.countryCode as any),
    showSiab: siabAppFeatureFlag || ALLOWED_COUNTRY_CODES_FOR_SIAB.includes(profile?.countryCode as any),
    showDashboard: ALLOWED_COUNTRY_CODES_FOR_DASHBOARD.includes(profile?.countryCode as any),
    hasRole: hasRole,
    hasRoleOrAbove: hasRoleOrAbove,
    showNetwork: networkFeatureFlag,
    showIntegrations: integrationsFeatureFlag,
    showProfileV2: profileV2FeatureFlag,
  }
}

import { ChunkedRequest } from "../types/ChunkedRequest"
import { ChunkedResponse } from "../types/ChunkedResponse"

const defaultLimit = 100
const defaultOffset = 0

export const createChunkedResponse = <TData>(req: ChunkedRequest, data: TData[] = []): ChunkedResponse<TData> => {
  const hasMore = data && data.length >= (req.limit ?? defaultLimit)

  return {
    offset: req.offset ?? defaultOffset,
    limit: req.limit ?? defaultLimit,
    data,
    hasMore,
  }
}

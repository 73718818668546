import styled from "styled-components"
import { ButtonArea } from "./buttons/ButtonArea"
import { theme } from "../../theme"

export const Link = styled(ButtonArea)`
  font-family: ${theme.font.bold};
  color: ${theme.color.primary};
  width: fit-content;
  display: inline;
`

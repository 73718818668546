import { ApiClient } from "../../../oneportal/services/api/ApiClient"
import { ChunkedRequest } from "../../../oneportal/services/api/types/ChunkedRequest"
import { SortedRequest } from "../../../oneportal/services/api/types/SortedRequest"
import { ToothPositionFormat } from "../../../registry/components/DentalChart/types/ToothPositionFormat"
import { Connection } from "./types/Connection"
import { ConnectionId } from "./types/ConnectionId"
import { ConnectionStatus } from "./types/ConnectionStatus"
import { Integration, IntegrationName } from "./types/Integration"
import { Organisation } from "./types/Organisation"
import { OrganisationPreferences } from "./types/OrganisationPreferences"
import { RecipientPushType } from "./types/RecipientPushType"
import { SapOrganizationId } from "./types/SapOrganizationId"
import { SuggestedConnection } from "./types/SuggestedConnection"
import { SuggestedConnectionId } from "./types/SuggestedConnectionId"
import { UserCommunicationPreferences } from "./types/UserCommunicationPreferences"
import { UserId } from "./types/UserId"
import { UserPreferenceRecipient } from "./types/UserPreferenceRecipient"
import { UserRole } from "./types/UserRole"
import { UserSearchScope } from "../../../siab/services/api/types/UserSearchScope"
import { createChunkedResponse } from "../../../oneportal/services/api/helpers/createChunkedResponse"
import { ChunkedResponse } from "../../../oneportal/services/api/types/ChunkedResponse"
import { CustomerProfile } from "./types/CustomerProfile"
import { CustomerRef } from "./types/CustomerRef"
import { DateFormatPreference } from "./types/DateFormatPreference"
import { createBrowserHistory } from "history"
import { getAppLinks } from "../../../links"
import { useMsalIsAuthenticated } from "../../../oneportal/hooks/useMsalIsAuthenticated"

export const createCustomerPortalApiClient = (api: ApiClient) => {
  return {
    getMyProfile: getMyProfile(api),
    getMyAvatar: getMyAvatar(api),
    updateMyProfile: updateMyProfile(api),
    updateMyProfileAvatar: updateMyProfileAvatar(api),
    getMyOrganisation: getMyOrganisation(api),
    getMyOrganisationLogo: getMyOrganisationLogo(api),
    searchCustomers: searchCustomers(api),
    getMyOrganisationPreferences: getMyOrganisationPreferences(api),
    updateMyOrganization: updateMyOrganization(api),
    updateMyOrganizationAvatar: updateMyOrganizationAvatar(api),
    deleteMyOrganizationAvatar: deleteMyOrganizationAvatar(api),
    updateMemberRole: updateMemberRole(api),
    searchOrganizations: searchOrganizations(api),
    searchConnections: searchConnections(api),
    searchReceivedInvitations: searchReceivedInvitations(api),
    createConnectionFromSuggestion: createConnectionFromSuggestion(api),
    disconnectOrganization: disconnectOrganization(api),
    createConnection: createConnection(api),
    acceptConnection: acceptConnection(api),
    declineConnection: declineConnection(api),
    logout: logout(api),
    getMyNotificationPreferences: getMyNotificationPreferences(api),
    addRecipient: addRecipient(api),
    removeRecipient: removeRecipient(api),
    toggleRecipientGroup: toggleRecipientGroup(api),
    toggleRecipient: toggleRecipient(api),
    searchSuggestedConnections: searchSuggestedConnections(api),
    suggestConnection: suggestConnection(api),
    getIntegrations: getIntegrations(api),
    sendIntegrationCode: sendIntegrationCode(api),
    deleteIntegrations: deleteIntegrations(api),
  }
}

const getMyProfile = (api: ApiClient) => async (): Promise<CustomerProfile | undefined> => {
  const history = createBrowserHistory({ forceRefresh: true })
  try {
    const res = await api.get<CustomerProfile>("/users/me")

    return res.data
  } catch (err) {
    const isAuthenticated = useMsalIsAuthenticated()
    if (isAuthenticated) {
      history.push(getAppLinks().oneportal.authenticationError())
    }
  }
}

const getMyAvatar = (api: ApiClient) => async (): Promise<string | undefined> => {
  try {
    const res = await api.get<string>("/users/me/avatar")

    return res.data
  } catch (err) {}
}

export type UpdateMyProfileRequest = {
  language?: string
  jobTitle?: string
  degree?: string
  toothPositionFormat?: ToothPositionFormat
  dateFormat?: DateFormatPreference
}

const updateMyProfile = (api: ApiClient) => async (
  data: UpdateMyProfileRequest
): Promise<CustomerProfile | undefined> => {
  try {
    const res = await api.patch<CustomerProfile>("/users/me", data)
    return res.data
  } catch (err) {}
}

export type UpdateProfileAvatarRequest = {
  file: File
}

const updateMyProfileAvatar = (api: ApiClient) => async (
  req?: UpdateProfileAvatarRequest
): Promise<CustomerProfile | undefined> => {
  try {
    let response
    if (!!req) {
      const formData = new FormData()
      formData.append("file", req.file)
      response = await api.patch<CustomerProfile>(`/users/avatar`, formData)
    } else {
      response = await api.patch<CustomerProfile>(`/users/avatar`)
    }

    return response.data
  } catch (err) {}
}

const getMyOrganisation = (api: ApiClient) => async (): Promise<Organisation | undefined> => {
  try {
    const res = await api.get<Organisation>("/organizations/me")
    return res.data
  } catch (err) {}
}

const getMyOrganisationLogo = (api: ApiClient) => async (): Promise<string | undefined> => {
  try {
    const res = await api.get<string>("/organizations/me/logo")
    return res.data
  } catch (err) {}
}

export type SearchCustomersRequest = SortedRequest &
  ChunkedRequest & {
    name?: string
    ids?: string[]
    scope?: UserSearchScope
  }

const searchCustomers = (api: ApiClient) => async (
  req: SearchCustomersRequest
): Promise<ChunkedResponse<CustomerRef>> => {
  const res = await api.post<CustomerRef[]>(`/users/search`, req)
  return createChunkedResponse(req, res.data)
}

const getMyOrganisationPreferences = (api: ApiClient) => async (): Promise<OrganisationPreferences | undefined> => {
  try {
    const res = await api.get<OrganisationPreferences>(`/organizations/preferences`)
    return res.data
  } catch (err) {}
}

const getIntegrations = (api: ApiClient) => async (): Promise<Integration[] | undefined> => {
  try {
    const res = await api.get<Integration[]>(`/integrations`)
    return res.data
  } catch (err) {}
}

const deleteIntegrations = (api: ApiClient) => async (integrationName: string): Promise<Integration | undefined> => {
  try {
    const res = await api.delete<Integration>(`/integrations/${integrationName}`)
    return res.data
  } catch (err) {}
}

export type SendIntegrationCodeRequest = {
  integrationName: IntegrationName
  code: string
  redirectUri: string
}

const sendIntegrationCode = (api: ApiClient) => async (
  req: SendIntegrationCodeRequest
): Promise<Integration | undefined> => {
  try {
    const res = await api.post<Integration>(`/integrations`, req)
    return res.data
  } catch (err) {}
}

export type UpdateOrganizationRequest = {
  isVisibleInNetwork: boolean
}

const updateMyOrganization = (api: ApiClient) => async (
  req: UpdateOrganizationRequest
): Promise<Organisation | undefined> => {
  try {
    const res = await api.patch<Organisation>(`/organizations`, req)
    return res.data
  } catch (err) {}
}

export type UpdateOrganizationAvatarRequest = {
  file: File
}

const updateMyOrganizationAvatar = (api: ApiClient) => async (
  req: UpdateOrganizationAvatarRequest
): Promise<Organisation | undefined> => {
  try {
    const formData = new FormData()
    formData.append("file", req.file)
    const response = await api.patch<Organisation>(`/organizations/logo`, formData)
    return response.data
  } catch (err) {}
}

const deleteMyOrganizationAvatar = (api: ApiClient) => async (): Promise<Organisation | undefined> => {
  try {
    const response = await api.delete<Organisation>(`/organizations/logo`)
    return response.data
  } catch (err) {}
}

export type UpdateMemberRoleRequest = {
  role: UserRole
}
const updateMemberRole = (api: ApiClient) => async (
  memberId: UserId,
  data: UpdateMemberRoleRequest
): Promise<Pick<CustomerProfile, "id" | "role"> | undefined> => {
  try {
    const res = await api.patch<Pick<CustomerProfile, "id" | "role">>(`/users/${memberId}`, data)

    return res.data
  } catch (err) {}
}

const logout = (api: ApiClient) => async () => {
  try {
    await api.post("/users/logout")
  } catch (err) {}
}

export type CreateNotificationsFormRequest = {
  notifySms: boolean
  phoneNumbers?: string[]
  notifyEmail: boolean
  emails?: string[]
  notifyBrowser: boolean
}

export type SearchOrganizationsRequest = {
  keyword: string
}

const searchOrganizations = (api: ApiClient) => async (
  req: SearchOrganizationsRequest
): Promise<Organisation[] | undefined> => {
  try {
    const res = await api.get<Organisation[]>("/organizations/search", { params: req })

    return res.data
  } catch (err) {}
}

export type SearchConnectionsRequest = {
  name?: string
  status?: ConnectionStatus
}

const searchConnections = (api: ApiClient) => async (
  req?: SearchConnectionsRequest
): Promise<Connection[] | undefined> => {
  try {
    const response = await api.post<Connection[]>("/connections/search", req)

    return response.data
  } catch (err) {}
}

export type CreateConnectionRequest = {
  sapOrganizationId: SapOrganizationId
  comment?: string
}

const createConnection = (api: ApiClient) => async (req: CreateConnectionRequest): Promise<Connection | undefined> => {
  try {
    const response = await api.post<Connection>("/connections", req)

    return response.data
  } catch (err) {}
}

export type GetSearchInvitationRequest = {
  name: string
  status?: ConnectionStatus
}

const searchReceivedInvitations = (api: ApiClient) => async (
  req: GetSearchInvitationRequest
): Promise<Connection[] | undefined> => {
  try {
    const response = await api.post<Connection[]>("/connections/search/invitations", req)

    return response.data
  } catch (err) {}
}

export type CreateConnectionFromSuggestionRequest = {
  comment?: string
}

const createConnectionFromSuggestion = (api: ApiClient) => async (
  suggestedConnectionId: SuggestedConnectionId,
  req: CreateConnectionFromSuggestionRequest
): Promise<Connection | undefined> => {
  try {
    const response = await api.post<Connection>(`/connections/invitation/${suggestedConnectionId}`, req)

    return response.data
  } catch (err) {}
}

const disconnectOrganization = (api: ApiClient) => async (connectionId: ConnectionId): Promise<boolean | undefined> => {
  try {
    const response = await api.delete(`/connections/${connectionId}`)

    return response.status >= 200 && response.status <= 300
  } catch (err) {}
}

const acceptConnection = (api: ApiClient) => async (connectionId: ConnectionId): Promise<Connection | undefined> => {
  try {
    const response = await api.patch<Connection>(`/connections/${connectionId}/accept`, { connectionId })

    return response.data
  } catch (err) {}
}

const declineConnection = (api: ApiClient) => async (connectionId: ConnectionId): Promise<Connection | undefined> => {
  try {
    const response = await api.patch<Connection>(`/connections/${connectionId}/decline`, { connectionId })

    return response.data
  } catch (err) {}
}

const getMyNotificationPreferences = (api: ApiClient) => async (): Promise<
  UserCommunicationPreferences | undefined
> => {
  try {
    const res = await api.get<UserCommunicationPreferences>(`/recipients/me`)

    return res.data
  } catch (err) {}
}

export type AddRecipientRequest = {
  email?: string
  phoneNumber?: string
  pushToken?: string
}

const addRecipient = (api: ApiClient) => async (
  request: AddRecipientRequest
): Promise<UserPreferenceRecipient | undefined> => {
  try {
    const res = await api.post<UserPreferenceRecipient>(`/recipients`, request)

    return res.data
  } catch (err) {}
}

export type RemoveRecipientRequest = {
  id: string
}

const removeRecipient = (api: ApiClient) => async (request: RemoveRecipientRequest): Promise<boolean> => {
  try {
    await api.delete<UserPreferenceRecipient>(`/recipients/${request.id}`)

    return true
  } catch (err) {}
  return false
}

export type ToggleRecipientGroupRequest = {
  pushType: RecipientPushType
  enabled: boolean
}

const toggleRecipientGroup = (api: ApiClient) => async (request: ToggleRecipientGroupRequest): Promise<boolean> => {
  try {
    await api.put<UserPreferenceRecipient>(`/recipients/push-group`, request)

    return true
  } catch (err) {}
  return false
}

export type ToggleRecipientRequest = {
  recipientId: string
  enabled: boolean
}

const toggleRecipient = (api: ApiClient) => async (
  request: ToggleRecipientRequest
): Promise<UserPreferenceRecipient | undefined> => {
  try {
    const res = await api.put<UserPreferenceRecipient>(
      `/recipients/${request.recipientId}/${request.enabled ? "enable" : "disable"}`,
      request
    )

    return res.data
  } catch (err) {}
}

export type SearchSuggestedConnectionsRequest = {
  name: string
}

const searchSuggestedConnections = (api: ApiClient) => async (
  request: SearchSuggestedConnectionsRequest
): Promise<SuggestedConnection[] | undefined> => {
  try {
    const result = await api.post<SuggestedConnection[] | undefined>(`/suggested-connections/search`, request)

    return result.data
  } catch (err) {}
}

export type CreateSuggestConnectionRequest = {
  sapOrganizationId: string
  comment?: string
}

const suggestConnection = (api: ApiClient) => async (
  request: CreateSuggestConnectionRequest
): Promise<SuggestedConnection | undefined> => {
  try {
    const result = await api.post<SuggestedConnection>(`/suggested-connections`, request)

    return result.data
  } catch (err) {}
}

import {
  FormConfig,
  FormDepsOptions,
  FormHandler,
  FormListener,
  FormListenerUnsubscribe,
  FormListenOptions,
  FormSchemaProducer,
  FormSubmitOptions,
  FormValidateOptions,
  FormValidator,
  ObservableForm,
} from "./types"
import { debounce, difference, get, isEqual, merge, set, uniq } from "lodash-es"
import { isEmptyErrorsObject } from "./isEmptyErrorsObject"
import { isEmptyErrorsArray } from "./isEmptyErrorsArray"
import { createStore, ObservableStore } from "../store"
import { ObjectSchema, ValidationResult } from "../schema"
import { createValue, ObservableValue } from "../value"

export class Form<TValue extends object = any, TResult = any> implements ObservableForm<TValue, TResult> {
  initialValue: TValue
  configuration: ObservableStore<FormConfig<TValue, TResult>>
  value: ObservableStore<TValue>
  errors: ObservableStore<ValidationResult>
  result: ObservableValue<TResult | undefined>
  dirtyFields: ObservableValue<string[]>
  changedFields: ObservableValue<string[]>
  submitting: ObservableValue<boolean>
  submitted: ObservableValue<boolean>

  constructor(initialValue: TValue = {} as TValue) {
    this.configuration = createStore<FormConfig<TValue, TResult>>({
      handler: undefined,
      validator: undefined,
      schema: undefined,
      debounce: 10,
      reactive: true,
      validate: true,
      sanitize: true,
    })

    this.initialValue = initialValue
    this.value = createStore(initialValue)
    this.errors = createStore({})
    this.result = createValue<TResult | undefined>(undefined)
    this.dirtyFields = createValue<string[]>([])
    this.changedFields = createValue<string[]>([])
    this.submitting = createValue<boolean>(false)
    this.submitted = createValue<boolean>(false)

    this.setupReactiveBehaviour()
  }

  get(): TValue {
    return this.value.get()
  }

  getAt(path: string): any {
    return get(this.get(), path)
  }

  set(newValues: TValue): void {
    this.value.set(newValues)
  }

  setAt(path: string, newValue: any): void {
    const newValues = set(this.get(), path, newValue)

    this.set(newValues)

    const oldValue = get(this.initialValue, path)

    if (!isEqual(oldValue, newValue)) {
      this.addChangedField(path)
    } else {
      this.clearChangedField(path)
    }

    this.addDirtyFields(path)
  }

  setAtWithDefault(path: string, newValue: any, def: any): void {
    if ([undefined, null, ""].includes(newValue)) {
      this.setAt(path, def)
    } else {
      this.setAt(path, newValue)
    }
  }

  put(newValues: Partial<TValue>): void {
    this.value.put(newValues)
  }

  clear(initialValue?: TValue): void {
    if (initialValue) {
      this.initialValue = initialValue
    }

    this.value.set(this.initialValue)
    this.submitting.set(false)
    this.submitted.set(false)
    this.clearDirtyFields()
    this.clearChangedFields()
    this.clearErrors()
    this.clearResult()
  }

  getErrors(): ValidationResult | undefined {
    const errors = this.errors.get()

    if (isEmptyErrorsObject(errors)) {
      return undefined
    }

    return errors
  }

  getErrorsAt(path: string): string[] | undefined {
    const errors = get(this.getErrors(), path)

    if (isEmptyErrorsArray(errors)) {
      return undefined
    }

    return errors
  }

  getFirstFieldError(path: string): string {
    return (this.getErrorsAt(path) ?? [])[0]
  }

  setErrors(newErrors: ValidationResult | undefined): void {
    this.errors.set(newErrors || {})
  }

  setErrorsAt(path: string, newErrors: string | string[]): void {
    if (!Array.isArray(newErrors)) {
      newErrors = [newErrors]
    }

    const errors = this.getErrors() || {}
    errors[path] = newErrors

    this.setErrors(errors)
  }

  addErrors(newErrors: Partial<ValidationResult> | undefined): void {
    this.errors.put(newErrors || {})
  }

  addErrorsAt(path: string, newErrors: string | string[]): void {
    if (!Array.isArray(newErrors)) {
      newErrors = [newErrors]
    }

    const errors = this.getErrorsAt(path) || []
    errors.push(...newErrors)

    this.setErrorsAt(path, errors)
  }

  hasErrors(): boolean {
    return this.getErrors() !== undefined
  }

  hasErrorsAt(path: string): boolean {
    return this.getErrorsAt(path) !== undefined
  }

  clearErrors(): void {
    this.errors.set({})
  }

  clearErrorsAt(path: string | string[]): void {
    if (!Array.isArray(path)) {
      path = [path]
    }

    const errors = this.getErrors()

    if (errors) {
      path.forEach((p) => delete errors[p])

      this.setErrors(errors)
    }
  }

  isDirty(): boolean {
    return this.getDirtyFields().length > 0
  }

  isDirtyField(field: string): boolean {
    return this.getDirtyFields().includes(field)
  }

  getDirtyFields(): string[] {
    return this.dirtyFields.get()
  }

  setDirtyFields(newFields: string | string[]): void {
    if (!Array.isArray(newFields)) {
      newFields = [newFields]
    }

    this.dirtyFields.set(uniq(newFields))
  }

  addDirtyFields(newFields: string | string[]): void {
    if (!Array.isArray(newFields)) {
      newFields = [newFields]
    }

    this.setDirtyFields([...this.getDirtyFields(), ...newFields])
  }

  clearDirtyFields(): void {
    this.dirtyFields.set([])
  }

  clearDirtyField(fields: string | string[]): void {
    if (!Array.isArray(fields)) {
      fields = [fields]
    }

    this.setDirtyFields(difference(this.getDirtyFields(), fields))
  }

  isChanged(): boolean {
    return this.getChangedFields().length > 0
  }

  isChangedField(field: string): boolean {
    return this.getChangedFields().includes(field)
  }

  getChangedFields(): string[] {
    return this.changedFields.get()
  }

  setChangedFields(newFields: string | string[]): void {
    if (!Array.isArray(newFields)) {
      newFields = [newFields]
    }

    this.changedFields.set(uniq(newFields))
  }

  addChangedField(newFields: string | string[]): void {
    if (!Array.isArray(newFields)) {
      newFields = [newFields]
    }

    this.setChangedFields([...this.getChangedFields(), ...newFields])
  }

  clearChangedFields(): void {
    this.changedFields.set([])
  }

  clearChangedField(fields: string | string[]): void {
    if (!Array.isArray(fields)) {
      fields = [fields]
    }

    this.setChangedFields(difference(this.getChangedFields(), fields))
  }

  getResult(): TResult | undefined {
    return this.result.get()
  }

  setResult(newValue: TResult | undefined): void {
    this.result.set(newValue)
  }

  clearResult(): void {
    this.result.set(undefined)
  }

  isSubmitting(): boolean {
    return this.submitting.get()
  }

  setSubmitting(submitting: boolean) {
    this.submitting.set(submitting)
  }

  isSubmitted(): boolean {
    return this.submitted.get()
  }

  setSubmitted(submitted: boolean) {
    this.submitted.set(submitted)
  }

  listen(callback: FormListener<TValue, TResult>, options?: FormListenOptions): FormListenerUnsubscribe {
    const debounceChanges = options?.debounce ?? this.configuration.get().debounce
    const immediate = options?.immediate

    const listener = debounceChanges > 0 ? debounce(() => callback(this), debounceChanges) : () => callback(this)

    const unsubscribeCallbacks = [
      this.configuration.listen(listener, { immediate }),
      this.value.listen(() => callback(this), { immediate }),
      this.result.listen(listener, { immediate }),
      this.errors.listen(listener, { immediate }),
      this.dirtyFields.listen(listener, { immediate }),
      this.changedFields.listen(listener, { immediate }),
      this.submitting.listen(listener, { immediate }),
      this.submitted.listen(listener, { immediate }),
    ]

    return () => unsubscribeCallbacks.forEach((unsubscribeCallback) => unsubscribeCallback())
  }

  config(config: Partial<FormConfig<TValue, TResult>>): this {
    this.configuration.put(config)

    return this
  }

  handler(handler: FormHandler<TValue, TResult>): this {
    this.configuration.put({ handler })

    return this
  }

  validator(validator: FormValidator<TValue, TResult>): this {
    this.configuration.put({ validator })

    return this
  }

  schema(schema: ObjectSchema<TValue> | FormSchemaProducer<TValue, TResult>): this {
    this.configuration.put({ schema })

    return this
  }

  async submit(options?: FormSubmitOptions): Promise<TResult | undefined> {
    if (this.isSubmitting()) {
      return
    }

    const config = this.configuration.get()

    const validate = options?.validate ?? config.validate
    const sanitize = options?.sanitize ?? config.sanitize
    const changed = options?.changed ?? false

    this.clearResult()
    this.clearErrors()

    this.submitting.set(true)

    try {
      if (sanitize) {
        await this.runSchemaSanitizer(changed)
      }

      if (validate) {
        const errors = await this.validate({
          changed,
          persist: true,
          sanitize: false,
        })

        if (errors) {
          this.submitting.set(false)

          return
        }
      }
    } catch (err) {
      this.submitting.set(false)
      throw err
    }

    try {
      const result = await this.runHandler()

      this.setResult(result)
    } catch (err) {
      this.submitting.set(false)
      throw err
    }

    this.submitting.set(false)
    this.submitted.set(true)

    return this.getResult()
  }

  async validate(options?: FormValidateOptions): Promise<ValidationResult | undefined> {
    const config = this.configuration.get()

    const changed = options?.changed ?? false
    const sanitize = options?.sanitize ?? config.sanitize
    const persist = options?.persist ?? true

    if (sanitize) {
      await this.runSchemaSanitizer(changed)
    }

    const schemaErrors = await this.runSchemaValidator()
    const validatorErrors = await this.runValidator()
    const errorsFromDifferentSources = [validatorErrors, schemaErrors]
    const newErrors = errorsFromDifferentSources.reduce((errors, errorSet) => {
      return merge({}, errors, errorSet)
    }, {})!

    if (changed) {
      const oldErrorKeys = Object.keys(this.getErrors() || {})
      const newErrorKeys = Object.keys(newErrors)
      const changedFields = this.getChangedFields()

      newErrorKeys.forEach((key) => {
        const fieldHasChanged = changedFields.includes(key)
        const fieldAlreadyHadAnError = oldErrorKeys.includes(key)

        if (fieldHasChanged) {
          // keep
        } else if (fieldAlreadyHadAnError) {
          // keep
        } else {
          delete newErrors[key]
        }
      })
    }

    if (persist) {
      this.setErrors(newErrors)
    }

    return isEmptyErrorsObject(newErrors) ? undefined : newErrors
  }

  deps(field: string | string[], options: FormDepsOptions = {}): any[] {
    const fields = Array.isArray(field) ? field : [field]
    const values = options.values === false ? [] : fields.map((f) => this.getAt(f))
    const errors = options.errors === false ? [] : fields.map((f) => this.getErrorsAt(f))
    const result = options.result === false ? undefined : this.getResult()
    const dirtyFields = options.dirtyFields === false ? [] : fields.map((f) => this.isDirtyField(f))
    const changedFields = options.changedFields === false ? [] : fields.map((f) => this.isChangedField(f))
    const submitting = options.submitting === false ? undefined : this.isSubmitting()
    const submitted = options.submitted === false ? undefined : this.isSubmitted()

    const { validate, debounce, reactive, sanitize } = this.configuration.get()
    const config = options.config === false ? undefined : { validate, debounce, reactive, sanitize }

    return [
      JSON.stringify(values),
      JSON.stringify(errors),
      JSON.stringify(dirtyFields),
      JSON.stringify(changedFields),
      JSON.stringify(result),
      JSON.stringify(submitting),
      JSON.stringify(submitted),
      JSON.stringify(config),
    ]
  }

  protected async runValidator(): Promise<ValidationResult | undefined> {
    const configuration = this.configuration.get()

    if (!configuration.validator) return

    try {
      return configuration.validator(this)
    } catch (error) {
      console.error("There was an error in form validator:", error)
      throw error
    }
  }

  protected async runSchemaSanitizer(changedFieldsOnly: boolean): Promise<void> {
    const schema = this.getSchema()

    if (!schema) return

    try {
      if (changedFieldsOnly) {
        if (this.changedFields.get().length > 0) {
          const sanitizedValues = await schema.sanitizeAsync<TValue>(this.get())

          this.changedFields.get().forEach((field) => {
            this.setAt(field, get(sanitizedValues, field))
          })
        }
      } else {
        const sanitizedValues = await schema.sanitizeAsync<TValue>(this.get())
        this.set(sanitizedValues)
      }
    } catch (error) {
      console.error("There was an error in form schema sanitizer:", error)
      throw error
    }
  }

  protected async runSchemaValidator(): Promise<ValidationResult | undefined> {
    const schema = this.getSchema()

    if (!schema) return

    try {
      return await schema.validateAsync(this.get())
    } catch (error) {
      console.error("There was an error in form schema validator:", error)
      throw error
    }
  }

  protected async runHandler(): Promise<TResult | undefined> {
    const configuration = this.configuration.get()

    if (configuration.handler) {
      try {
        return await configuration.handler(this)
      } catch (error) {
        console.error("There was an error in form handler:", error)
        throw error
      }
    }
  }

  protected setupReactiveBehaviour() {
    this.value.listen(async () => {
      try {
        const reactive = this.configuration.get().reactive
        const validate = this.configuration.get().validate

        if (reactive && validate) {
          await this.validate({
            sanitize: false,
            persist: true,
            changed: true,
          })
        }
      } catch (err) {}
    })
  }

  protected getSchema(): ObjectSchema<TValue> | undefined {
    const config = this.configuration.get()

    if (!config.schema) return

    return typeof config.schema === "function" ? config.schema(this) : config.schema
  }
}

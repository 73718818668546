import { CreateCaseDraftRequest } from "../createSiabApiClient"
import { CaseInstanceDraftCreationRequestDTO } from "../types/CaseInstanceDraftCreationRequestDTO"
import { PatientData } from "../../../components/caseForms/CreateCaseFormV2/store/features/caseInit/caseInitInitialState"

const toNewPatient = (patientForm: PatientData): CaseInstanceDraftCreationRequestDTO["newPatient"] => ({
  birthDate: patientForm.birthDate,
  customPatientId: patientForm.customPatientId,
  firstName: patientForm.firstName,
  gender: patientForm.gender,
  lastName: patientForm.lastName,
  middleName: patientForm.middleName,
})

export const createCaseInstanceDraftCreationRequestDTO = (
  req: CreateCaseDraftRequest
): CaseInstanceDraftCreationRequestDTO => {
  return {
    caseOwnerB2cUserId: req.case.caseOwnerB2cUserId,
    contributorsB2cUserIds: req.case.contributorsB2cUserIds,
    caseType: req.case.caseType,
    surgeryDate: req.case.surgeryDate,
    patientId: !!req.patient.patientId ? req.patient.patientId : undefined,
    newPatient: !!req.patient.patientId ? undefined : toNewPatient(req.patient), // only set if no patientId
  }
}

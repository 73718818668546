import { useMediaQuery } from "@material-ui/core"
import { useState } from "react"
import {
  InstructionStorageKeys,
  setShowInstruction,
  showInstruction,
} from "../../customerportal/components/Introduction"
import { theme } from "../../theme"
import { NewUserPreferencesDialog } from "./NewUserPreferencesDialog/NewUserPreferencesDialog"

export const CheckFirstTimeRegister = () => {
  const isMobileScreen = useMediaQuery(theme.mui.breakpoints.down(768))

  const [firstTimeRegister, setFirstTimeRegister] = useState(showInstruction(InstructionStorageKeys.Register))
  const handleCloseNewUserPreference = () => {
    setFirstTimeRegister(false)
    setShowInstruction(InstructionStorageKeys.Register, false)
  }

  if (firstTimeRegister && !isMobileScreen) {
    return <NewUserPreferencesDialog onClose={handleCloseNewUserPreference} />
  }

  return null
}

import { MsalProfile } from "../hooks/useMsalProfile"

export enum GTMEvent {
  AssignProduct = "ASSIGN_PRODUCT",
  CreateNewPatient = "CREATE_NEW_PATIENT",
  CreateNewProcedure = "CREATE_NEW_PROCEDURE",
  DownloadFile = "DOWNLOAD_FILE",
  InitNewPatient = "INIT_NEW_PATIENT",
  Navigate = "NAVIGATE",
  SiabSubmitCase = "siab_submit_case",
  SiabSendApproveTreatmentPlan = "siab_send_approve_treatment_plan",
  SiabApproveValidateBox = "siab_approve_validate_box",
  InitNewComplication = "INIT_NEW_COMPLICATION",
  CreateNewComplication = "CREATE_NEW_COMPLICATION",
  ScanPatientQR = "SCAN_PATIENT_QR",
  ScanSingleProduct = "SCAN_SINGLE_PRODUCT",
  ScanMultipleProducts = "SCAN_MULTIPLE_PRODUCTS",
  DismissEncounterTour = "DISMISS_ENCOUNTER_TOUR",
  StartEncounterTour = "START_ENCOUNTER_TOUR",
  InitLabOrder = "INIT_LAB_ORDER",
  CreateLabOrder = "CREATE_LAB_ORDER",
  InitFollowUp = "INIT_FOLLOW_UP",
  CreateFollowUp = "CREATE_FOLLOW_UP",
  InitEncounter = "INIT_ENCOUNTER",
  showOnboardingMenu = "SHOW_ONBOARDING_MENU",
}

export const pushToDataLayer = (event: GTMEvent, profile: MsalProfile, data: Record<any, any> = {}) => {
  try {
    window["dataLayer"] = window["dataLayer"] || []
    window["dataLayer"].push({
      event,
      time: Date(),
      user: {
        user_id: profile.id,
        b2cUserId: profile.id,
        email: profile.email,
        profileLanguage: profile.language,
        country: profile.countryCode,
        firstName: profile.firstName,
        lastName: profile.lastName,
      },
      ...data,
    })
  } catch (err) {
    console.error("Could not push to dataLayer", err)
  }
}

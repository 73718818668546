import { Button, FormControlLabel, Popover, Radio, RadioGroup } from "@material-ui/core"
import { ReactNode, useEffect } from "react"
import styled, { ThemeProvider } from "styled-components"
import { theme } from "../../../../theme"
import { useLocale } from "../../../hooks/useLocale"
import { DropdownDarkTheme } from "../dropdownThemes"
import { ObservableForm } from "../../../libraries/form"
import { PopoverContainer } from "../PopoverContainer"
import { ChipColor, PopoverValue, TextAlign } from "./PopoverValue"
import { useInputPopover } from "../hooks/useInputPopover"
import { DropdownValueVariant } from "./DropdownValue"
import { Link } from "../../Link"

export type RadioGroupPopoverProps<TValue = string> = {
  name?: string
  value: TValue | undefined | null
  form: ObservableForm
  onChange: (newValue: TValue | undefined) => void
  onSubmit?: (newValue: TValue | undefined) => Promise<void>
  containsHtml?: boolean
  placeholder?: ReactNode
  error?: boolean
  helperText?: ReactNode
  children?: ReactNode
  options?: TValue[]
  formatOption?: (option: TValue) => string
  textAlign?: TextAlign
  readonly?: boolean
  showCustomAddressButton?: boolean
  onClickCustomAddress?: () => void
  variant?: DropdownValueVariant
  crossIcon?: boolean
  openAddressRadioGroup?: boolean
  openForm?: () => void
  isAddress?: boolean
  popoverColor?: ChipColor
}

export const RadioGroupPopover = (props: RadioGroupPopoverProps) => {
  const {
    value = "",
    form,
    onChange,
    onSubmit = async (newValue) => onChange(newValue),
    containsHtml = false,
    name,
    options = [],
    placeholder,
    formatOption = (option) => option,
    textAlign = "start",
    readonly = false,
    showCustomAddressButton = false,
    onClickCustomAddress = () => undefined,
    variant,
    crossIcon,
    openAddressRadioGroup = true,
    openForm = () => undefined,
    isAddress,
    popoverColor,
  } = props
  const { open, anchorEl, handleClick, handleClose, handleChangeAndClose } = useInputPopover(
    value,
    form,
    onChange,
    onSubmit
  )

  const loc = useLocale()

  useEffect(() => {
    if (openAddressRadioGroup) return
    handleChangeAndClose(value ?? "")
  })

  return (
    <>
      <PopoverValue
        placeholder={placeholder}
        value={value ? formatOption(value) : undefined}
        containsHtml={containsHtml}
        onClick={(event) => {
          !readonly && handleClick(event)
          openForm()
        }}
        textAlign={textAlign}
        readonly={readonly}
        variant={variant}
        isAddress={isAddress}
        popoverColor={popoverColor}
      />
      {isAddress && (
        <Link
          onClick={(event) => {
            !readonly && handleClick(event)
            openForm()
          }}
        >
          {loc.siab.createCaseForm.step4.labels.edit.get()}
        </Link>
      )}

      <ThemeProvider theme={DropdownDarkTheme}>
        <Popover
          open={open}
          anchorEl={anchorEl}
          onClose={handleClose}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "center",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "center",
          }}
        >
          <PopoverContainer
            title={loc.registry.patientProfile.labels.choose.get()}
            show={true}
            onClose={handleClose}
            crossIcon={crossIcon}
          >
            <DropdownRadioGroup name={name} value={value}>
              {options.map((option) => (
                <div key={option}>
                  {!containsHtml && (
                    <FormControlLabel
                      control={
                        <Radio name={name} value={option} onChange={(e) => handleChangeAndClose(e.target.value)} />
                      }
                      label={formatOption(option)}
                    />
                  )}
                  {containsHtml && (
                    <OptionContainer onClick={() => handleChangeAndClose(option)}>
                      <FormControlLabel
                        control={
                          <Radio name={name} value={option} onChange={(e) => handleChangeAndClose(e.target.value)} />
                        }
                        label={null}
                      />
                      <div dangerouslySetInnerHTML={{ __html: formatOption(option) }} />
                    </OptionContainer>
                  )}
                </div>
              ))}
            </DropdownRadioGroup>
            {showCustomAddressButton && (
              <CustomButton onClick={() => onClickCustomAddress()}>
                {loc.siab.formRenderer.labels.useAnotherAddress.get()}
              </CustomButton>
            )}
          </PopoverContainer>
        </Popover>
      </ThemeProvider>
    </>
  )
}

const OptionContainer = styled.div`
  display: flex;
  cursor: pointer;
  padding: ${theme.spacing.s} 0;
  align-items: center;
`

const DropdownRadioGroup = styled(RadioGroup)`
  & label {
    color: ${theme.color.white3};

    & .MuiRadio-root,
    & .MuiIconButton-label:not(.Mui-error) {
      color: ${theme.color.white3};
    }
  }
`

const CustomButton = styled(Button)`
  color: ${theme.color.white};
  text-transform: none;
  padding: ${theme.spacing.m};
`

import { Chip } from "@material-ui/core"
import AddIcon from "@material-ui/icons/Add"
import { TextBold } from "@op-str/op-component-library"
import { ReactNode } from "react"
import styled from "styled-components"
import { theme } from "../../../../theme"
import { ProgressStatus } from "../../../helpers/types/ProgressStatus"
import { useLocale } from "../../../hooks/useLocale"
import { ButtonArea } from "../../buttons/ButtonArea"

export enum DropdownValueVariant {
  LinkWithArrow = "LINK_WITH_ARROW",
  Link = "LINK",
  Chip = "CHIP",
}

export type DropdownValueProps = {
  placeholder?: ReactNode
  value: any
  disabled: boolean
  status?: ProgressStatus
  variant?: DropdownValueVariant
  onClick: () => void
  subValue?: ReactNode
  blankValue?: any
}

export const DropdownValue = (props: DropdownValueProps) => {
  const {
    placeholder,
    disabled,
    value,
    blankValue,
    variant = DropdownValueVariant.Link,
    onClick,
    subValue,
  } = props
  const loc = useLocale()
  const shouldShowAddLink = !value || value === blankValue

  const valueToShow =
    variant === DropdownValueVariant.Link ? (
      <span>
        <ListItemValueAddLink onClick={onClick} disabled={disabled}>
          <TextBold noWrap>
            {value} {!!subValue && subValue}
          </TextBold>
        </ListItemValueAddLink>
      </span>
    ) : (
      <Chip label={value} onClick={onClick} disabled={disabled} />
    )

  return (
    <ListItemValue>
      {shouldShowAddLink ? (
        <span>
          <ListItemValueAddLink onClick={onClick} disabled={disabled}>
            {placeholder || (
              <>
                <AddIcon />
                {loc.registry.patientProfile.actions.add.get()}
              </>
            )}
          </ListItemValueAddLink>
        </span>
      ) : (
        valueToShow
      )}
    </ListItemValue>
  )
}

const ListItemValue = styled.span`
  display: block;
  align-items: center;
  color: ${theme.color.primary};
  font-size: ${theme.fontSize.m};
  line-height: 24px;
  max-width: 100%;
  text-align: end;
  overflow: hidden;

  &:hover {
    cursor: pointer;
  }
`

const ListItemValueAddLink = styled(ButtonArea)`
  display: flex;
  max-width: 100%;

  align-items: center;
  color: ${theme.color.primary};

  p {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  svg {
    font-size: ${theme.fontSize.m};
  }

  &:disabled {
    color: rgba(0, 0, 0, 0.38);
  }
`

import axios from "axios"
import { ApiClient } from "./ApiClient"
import { GenericLogger } from "../../libraries/logger"
import { ObservableValue } from "../../libraries/value"
import { AccessTokenFetcher } from "../../helpers/types/AccessTokenFetcher"

export type CreateApiClientArgs = {
  endpoint: string
  fetchAccessToken: AccessTokenFetcher
  logger: GenericLogger
  indicator?: ObservableValue<boolean>
}

let indicatorVotes = 0

export const createApiClient = (args: CreateApiClientArgs): ApiClient => {
  const { endpoint, fetchAccessToken, logger, indicator } = args

  const api = axios.create({
    baseURL: endpoint,
  })

  api.interceptors.request.use(async (config) => {
    indicatorVotes++

    if (indicatorVotes > 0) {
      indicator?.set(true)
    }

    const token = await fetchAccessToken(logger)

    config.headers["Authorization"] = `Bearer ${token}`

    return config
  })

  api.interceptors.response.use(
    (res) => {
      indicatorVotes--

      if (indicatorVotes <= 0) {
        indicator?.set(false)
      }

      return res
    },
    (error) => {
      indicatorVotes--

      if (indicatorVotes <= 0) {
        indicator?.set(false)
      }
      logger.warn(error)

      throw error
    }
  )

  return api as ApiClient
}

import React, { useEffect } from "react"
import { MainLayout } from "../../oneportal/layouts/MainLayout"
import { useLocale } from "../../oneportal/hooks/useLocale"
import { ErrorNode } from "@op-str/op-component-library"
import { useHistory } from "react-router-dom"
import { useLinks } from "../../oneportal/hooks/useLinks"
import { useMsalIsAuthenticated } from "../../oneportal/hooks/useMsalIsAuthenticated"

export const PageNotFound = () => {
  const { title, labels } = useLocale().customerportal.resourceNotFound

  const links = useLinks()
  const history = useHistory()
  const isAuthenticated = useMsalIsAuthenticated()

  useEffect(() => {
    if (!isAuthenticated) {
      history.push(links.oneportal.login())
    }
  }, [])

  return (
    <MainLayout title={title.get()}>
      <ErrorNode
        title={labels.title.get()}
        description={labels.body.get()}
        button={{
          label: labels.button.get(),
          action: () => history.push(links.customerportal.dashboard()),
        }}
      />
    </MainLayout>
  )
}

import { ArrowBack } from "@material-ui/icons"
import clsx from "clsx"
import { AnimatePresence } from "framer-motion"
import { forwardRef, ReactNode } from "react"
import styled from "styled-components"
import { theme } from "../../../theme"
import { ButtonArea } from "../buttons/ButtonArea"
import { LegalInfo } from "../LegalInfo"

export type SidebarProps = {
  children?: ReactNode
  onToggle: () => void
  collapsed: boolean
}

export const Sidebar = forwardRef<HTMLDivElement, SidebarProps>((props, ref) => {
  const { children, onToggle, collapsed } = props

  return (
    <Root ref={ref} className={clsx(collapsed && "collapsed")}>
      <SidebarRoot>
        {children}
        <BottomContent>
          <AnimatePresence>{!collapsed && <LegalInfo />}</AnimatePresence>
          <ToggleButton onClick={onToggle}>
            <BackIcon color="primary" />
          </ToggleButton>
        </BottomContent>
      </SidebarRoot>
    </Root>
  )
})

const Root = styled.div`
  display: flex;
  width: ${theme.ui.sidebar.width};
  z-index: ${theme.zIndex.low};
  transition: width 0.2s linear;

  &.collapsed {
    width: ${theme.ui.sidebar.widthCollapsed};
  }
`

const SidebarRoot = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  flex-shrink: 0;
  overflow-y: auto;
  overflow-x: hidden;
  box-shadow: ${theme.mui.shadows[3]};
  background: ${theme.color.white};

  height: 100%;
  width: ${theme.ui.sidebar.width};
  transition: width 0.2s linear;

  .collapsed & {
    width: ${theme.ui.sidebar.widthCollapsed};
    transition: width 0.2s linear;
  }
`

const ToggleButton = styled(ButtonArea)`
  display: flex;
  width: 100%;
  border-top: 1px solid ${theme.color.white1};
  height: 49px;
  flex-grow: 0;
  overflow-x: hidden;
  justify-content: flex-end;
  align-items: center;
  padding-right: ${parseInt(theme.spacing.l) + parseInt(theme.spacing.m)}px;

  &:hover {
    background: ${theme.color.blue9};
  }

  .collapsed & {
    padding-right: 21px;
  }
`

const BackIcon = styled(ArrowBack)`
  transform: rotate(0deg);
  transition: transform 0.2s linear;

  .collapsed & {
    transform: rotate(180deg);
    transition: transform 0.2s linear;
  }
`

const BottomContent = styled.div`
  margin-top: auto;
`

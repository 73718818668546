import { Router as ReactRouter } from "react-router-dom"
import { ComponentType, ReactNode } from "react"
import { RouteDefinition } from "./types/RouteDefinition"
import { RoutePolicy } from "./types/RoutePolicy"
import { GenericLogger } from "../logger"
import { Routes } from "./Routes"
import { useContainer } from "../../hooks/useContainer"

export type RouterProps<TContext> = {
  context: TContext
  routes: RouteDefinition<TContext>[]
  policy: RoutePolicy
  homePath: string
  loginPath: string
  NotFound: ComponentType
  RenderErrorFallback: ComponentType
  logger?: GenericLogger
  children?: ReactNode
}

export const Router = <TContext,>(props: RouterProps<TContext>) => {
  const { children, ...rest } = props
  const container = useContainer()

  return (
    <ReactRouter history={container.browserHistory}>
      {children}
      <Routes {...rest} />
    </ReactRouter>
  )
}

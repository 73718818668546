export const submitInvisibleForm = (url: string, fields: Record<string, string>) => {
  const form = document.createElement("form")
  form.action = url
  form.method = "POST"

  Object.entries(fields).forEach((keyAndValue) => {
    const [key, value] = keyAndValue

    const input = document.createElement("input")
    input.type = "hidden"
    input.name = key
    input.value = value

    form.appendChild(input)
  })

  document.body.appendChild(form)

  form.submit()

  document.body.removeChild(form)
}

import { useEffect, useState } from "react"
import styled from "styled-components"
import { useCustomerPortalApiClient } from "../../../customerportal/hooks/useCustomerPortalApiClient"
import { theme } from "../../../theme"
import { useAlert } from "../../hooks/useAlert"
import { useLocale } from "../../hooks/useLocale"
import { useMsalProfile } from "../../hooks/useMsalProfile"
import { useAsync } from "../../libraries/user-async"
import { TextSmall } from "../text/TextSmall"
import { OrganisationNetworkSwitch } from "./OrganisationNetworkSwitch"

export const OrganisationNetworkSection = () => {
  const loc = useLocale()
  const api = useCustomerPortalApiClient()
  const alert = useAlert()
  const profile = useMsalProfile()
  const organisation = useAsync(() => loadOrganisation())

  const [toggle, setToggle] = useState(false)

  const handleChangeSwitch = (value: boolean) => handleUpdateOrganisation(value)

  const loadOrganisation = () => (profile ? api.getMyOrganisation() : undefined)

  useEffect(() => {
    setToggle(organisation.result?.isVisibleInNetwork ?? false)
  }, [organisation.result?.isVisibleInNetwork])

  const handleUpdateOrganisation = async (value: boolean) => {
    if (!profile) {
      return
    }

    const result = await api.updateMyOrganization({
      isVisibleInNetwork: value,
    })

    if (result) {
      setToggle(!!result.isVisibleInNetwork)
    } else {
      alert(loc.customerportal.organisationPreferences.messages.updateError.get(), { variant: "error" })
    }
  }

  return (
    <Root>
      <SectionDescription>
        {loc.oneportal.newUserPreferenceStep2.labels.visibleInNetworkDescription.get()}
      </SectionDescription>
      <OrganisationNetworkSwitch isVisible={toggle} onChange={handleChangeSwitch} disabled={organisation.loading} />
      <SectionNote>
        <TextSmall>{loc.oneportal.newUserPreferenceStep2.labels.visibleInNetworkNote.get()}</TextSmall>
        {!toggle && !organisation.loading && (
          <TextSmall>{loc.oneportal.newUserPreferenceStep2.labels.inVisibleInNetworkNote.get()}</TextSmall>
        )}
      </SectionNote>
    </Root>
  )
}

const Root = styled.div`
  display: flex;
  flex-flow: column;
  align-items: center;
  justify-content: center;
  height: 100%;
`

const SectionDescription = styled.div`
  margin-bottom: ${theme.spacing.lx};
`

const SectionNote = styled.div`
  color: ${theme.color.black4};
  margin-top: ${theme.spacing.l};
  min-height: ${theme.spacing.xl};
`

import styled from "styled-components"
import { Button } from "@material-ui/core"
import { theme } from "../../../theme"

export const ButtonPrimaryOutlined = styled(Button).attrs((props) => ({
  color: "primary",
  variant: "outlined",
  ...props,
}))`
  color: ${theme.color.primary} !important;
  box-shadow: ${theme.mui.shadows[1]};

  &:disabled {
    color: ${theme.color.grey3} !important;
    background-color: ${theme.color.white1};
    border: none;
  }
`

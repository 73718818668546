import { IPublicClientApplication } from "@azure/msal-browser"
import { GenericLogger } from "../libraries/logger"

export type FetchMsalAccessTokenArgs = {
  msal: IPublicClientApplication
  msalScopes: string[]
  logger?: GenericLogger
}

export const fetchMsalAccessToken = async (args: FetchMsalAccessTokenArgs): Promise<string> => {
  const { msal, msalScopes, logger } = args

  try {
    const account = msal.getAllAccounts()[0]!
    const res = await msal.acquireTokenSilent({
      scopes: msalScopes,
      account: account,
    })

    return res.accessToken
  } catch (err) {
    logger?.error("Could not retrieve msal access token")
    console.error(err)
    msal.logout()
    throw err
  }
}

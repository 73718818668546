import { getQueryString } from "../oneportal/helpers/getQueryString"
import { PatientId } from "../oneportal/services/api/types/PatientId"
import { PatientDetailsQuery } from "./components/PatientDetails/types/PatientDetailsQuery"
import { EncounterDetailsQuery } from "./pages/EncounterDetails"
import { SurgeryReportQuery } from "./pages/SurgeryReport"
import { ComplicationId } from "./services/api/types/ComplicationId"
import { EncounterId } from "./services/api/types/EncounterId"
import { ImportId } from "./services/api/types/ImportId"

export type RegistryLinks = ReturnType<typeof getRegistryLinks>

// hide links inside a factory to prevent global imports
export const getRegistryLinks = () => {
  return {
    patientDetails: (patientId: PatientId = ":id" as PatientId, query?: PatientDetailsQuery) =>
      `/patients/${patientId}${getQueryString(query)}`,
    complicationCreate: (
      patientId: PatientId = ":patientId" as PatientId,
      encounterId: EncounterId = ":encounterId" as EncounterId
    ) => `/patients/${patientId}/encounters/${encounterId}/complications`,
    complicationUpdate: (
      patientId: PatientId = ":patientId" as PatientId,
      encounterId: EncounterId = ":encounterId" as EncounterId,
      complicationId: ComplicationId = ":complicationId" as ComplicationId
    ) => `/patients/${patientId}/encounters/${encounterId}/complications/${complicationId}`,
    encounterDetails: (
      patientId = ":patientId" as PatientId,
      encounterId = ":encounterId" as EncounterId,
      query?: EncounterDetailsQuery
    ) => `/patients/${patientId}/encounters/${encounterId}${getQueryString(query)}`,
    surgeryReport: (patientId: PatientId = ":patientId" as PatientId, query?: SurgeryReportQuery) =>
      `/patients/${patientId}/surgery-report${getQueryString(query)}`,
    labOrder: (patientId: PatientId = ":patientId" as PatientId) => `/patients/${patientId}/lab-order`,
    registry: () => "/registry",
    patientsImportAnalysis: () => `/registry/analysis`,
    patientsDataExport: () => `/registry/export`,
    patientsImportResult: (importId: ImportId = ":importId" as ImportId, isAnalysis?: boolean) =>
      `/registry/analysisResult/${importId}${isAnalysis ? "?isAnalysis=true" : ""}`,
    patientsImport: () => `/registry/import`,
    encounterCreate: () => "/registry/encounters/create",
    followUp: (patientId = ":patientId" as PatientId, encounterId = ":encounterId" as EncounterId) =>
      `/patients/${patientId}/encounters/${encounterId}/follow-up`,
    stockManagement: () => "/registry/stock-management",
  }
}

import { AppConfig } from "../config"
import { MsalProfilePermissions } from "../oneportal/hooks/useMsalProfilePermissions"
import { RouteDefinition } from "../oneportal/libraries/router/types/RouteDefinition"
import { RoutePolicy } from "../oneportal/libraries/router/types/RoutePolicy"
import { SiabLinks } from "./links"

const privateRoutes = <TContext>(routes: RouteDefinition<TContext>[]): RouteDefinition<TContext>[] =>
  routes.map((route) => ({ ...route, policy: RoutePolicy.ForAuthenticated }))

// hide routes inside a factory to prevent global imports
export const getSiabRoutes = (config: AppConfig, links: SiabLinks): RouteDefinition<MsalProfilePermissions>[] => {
  return [
    ...privateRoutes<MsalProfilePermissions>([
      {
        exact: true,
        path: links.cases(),
        load: () => import("./pages/Cases"),
        check: (ctx) => ctx.showSiab,
      },

      // deprecated
      {
        exact: true,
        path: links.caseCreateV1(),
        load: () => import("./pages/CaseCreateV1"),
        check: (ctx) => ctx.showSiab,
      },

      {
        exact: true,
        path: links.caseCreateV2(),
        redirect: links.caseCreate(),
        check: (ctx) => ctx.showSiab,
      },

      {
        exact: true,
        path: links.caseCreating(),
        load: () => import("./pages/CaseCreate"),
        check: (ctx) => ctx.showSiab,
      },
      {
        exact: true,
        path: links.caseCreate(),
        load: () => import("./pages/CaseCreate"),
        check: (ctx) => ctx.showSiab,
      },
      {
        exact: true,
        path: links.caseDetails(),
        load: () => import("./pages/CaseDetails"),
        check: (ctx) => ctx.showSiab,
      },
      {
        path: links.caseEditOrCheckout(),
        load: () => import("./pages/CaseEditOrCheckout"),
        check: (ctx) => ctx.showSiab,
      },
      {
        path: links.caseSuccess(),
        load: () => import("./pages/CaseSuccess"),
        check: (ctx) => ctx.showSiab,
      },
    ]),
  ]
}

import { HomeOutlined, PeopleOutlineOutlined } from "@material-ui/icons"
import React from "react"
import { ConnectedServiceClearCorrectIcon, RegistryIcon, SmileInABoxIcon } from "../../../assets/icons"
import { SidebarSection } from "../../oneportal/components/Sidebar/SidebarSection"
import {
  useClearCorrectLinks,
  useLinks,
  usePatientsLinks,
  useRegistryLinks,
  useSiabLinks,
} from "../../oneportal/hooks/useLinks"
import { useLocale } from "../../oneportal/hooks/useLocale"
import { useMsalProfilePermissions } from "../../oneportal/hooks/useMsalProfilePermissions"

export const OneportalSidebar = () => {
  const links = useLinks()
  const siabLinks = useSiabLinks()
  const registryLinks = useRegistryLinks()
  const clearCorrectLinks = useClearCorrectLinks()
  const patientsLinks = usePatientsLinks()
  const loc = useLocale()
  const permissions = useMsalProfilePermissions()

  return (
    <SidebarSection
      title={loc.customerportal.oneportalSidebar.title.get()}
      items={[
        permissions.showDashboard && {
          text: loc.customerportal.oneportalSidebar.links.dashboard.get(),
          link: links.customerportal.dashboard(),
          icon: <HomeOutlined />,
        },
        {
          text: loc.customerportal.oneportalSidebar.links.patients.get(),
          link: patientsLinks.patients(),
          icon: <PeopleOutlineOutlined />,
          addLink: patientsLinks.patientCreate(),
          addLinkTooltip: loc.customerportal.oneportalSidebar.links.patientsAddTooltip.get(),
        },
      ]}
      myServices={[
        permissions.showSiab && {
          text: loc.customerportal.oneportalSidebar.links.smileInABox.get(),
          link: siabLinks.cases(),
          icon: <SmileInABoxIcon />,
          addLink: siabLinks.caseCreate(),
          addLinkTooltip: loc.customerportal.oneportalSidebar.links.smileInABoxAddTooltip.get(),
        },
        permissions.showRegistry && {
          text: loc.customerportal.oneportalSidebar.links.registry.get(),
          link: registryLinks.registry(),
          icon: <RegistryIcon />,
          addLink: registryLinks.encounterCreate(),
          addLinkTooltip: loc.customerportal.oneportalSidebar.links.registryAddTooltip.get(),
        },
      ]}
      connectedServices={[
        permissions.showSiab && {
          text: loc.customerportal.oneportalSidebar.links.clearCorrect.get(),
          link: clearCorrectLinks.clearCorrectCases(),
          icon: <ConnectedServiceClearCorrectIcon />,
        },
      ]}
    />
  )
}

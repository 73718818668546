import { useMsalAccount } from "./useMsalAccount"
import { UserRole } from "../../customerportal/services/api/types/UserRole"
import { useTranslator } from "./useTranslator"
import { SapOrganizationId } from "../../customerportal/services/api/types/SapOrganizationId"

export type MsalProfile = {
  id: string
  organisationId: SapOrganizationId
  organisationName: string
  username: string
  email: string
  language: string
  fullName?: string
  firstName?: string
  lastName?: string
  countryCode?: string
  roles?: UserRole
}

export enum MsalProfileCountryCode {
  UnitedStates = "US",
  Canada = "CA",
  Austria = "AT",
  Belgium = "BE",
  Bulgaria = "BG",
  Croatia = "HR",
  Cyprus = "CY",
  CzechRepublic = "CZ",
  Denmark = "DK",
  Estonia = "EE",
  Finland = "FI",
  France = "FR",
  Germany = "DE",
  Greece = "GR",
  Hungary = "HU",
  Iceland = "IS",
  Ireland = "IE",
  Italy = "IT",
  Latvia = "LV",
  Liechtenstein = "LI",
  Lithuania = "LT",
  Luxembourg = "LU",
  Malta = "MT",
  Netherlands = "NL",
  Norway = "NO",
  Poland = "PL",
  Portugal = "PT",
  Romania = "RO",
  Slovakia = "SK",
  Slovenia = "SI",
  Spain = "ES",
  Sweden = "SE",
  Switzerland = "CH",
}

export const useMsalProfile = (): MsalProfile | undefined => {
  const account = useMsalAccount()
  const translator = useTranslator()

  if (!account) return

  const claims = account.idTokenClaims as any

  return {
    id: account.localAccountId,
    organisationId: claims.selectedOrganizationId,
    organisationName: claims.selectedOrganizationName,
    countryCode: claims.selectedOrganizationCountryCode,
    username: account.username,
    email: claims.email,
    language: translator.language,
    fullName: account.name,
    firstName: claims.given_name,
    lastName: claims.family_name,
    roles: claims.roles,
  }
}

import { AutocompleteProps, RatingProps } from "@material-ui/lab"
import { CheckboxProps, RadioGroupProps, RadioProps, SelectProps, TextFieldProps } from "@material-ui/core"
import { DatePickerDropdownProps } from "../components/dropdowns/inputs/DatePickerDropdown"
import { DatePickerProps } from "../components/DatePicker"
import { FormHTMLAttributes } from "react"
import { NumberFieldDropdownProps } from "../components/dropdowns/inputs/NumberFieldDropdown"
import { ObservableForm } from "../libraries/form"
import { RadioChipProps } from "../components/RadioChip"
import { RadioGroupDropdownProps } from "../components/dropdowns/inputs/RadioGroupDropdown"
import { TextFieldDropdownProps } from "../components/dropdowns/inputs/TextFieldDropdown"

export type MuiFormBinder = {
  form: () => FormHTMLAttributes<HTMLFormElement>

  textField: (field: string) => TextFieldProps
  numberField: (field: string) => TextFieldProps
  rating: (field: string) => RatingProps
  datePicker: (field: string) => DatePickerProps

  radioGroup: (field: string) => RadioGroupProps
  booleanRadioGroup: (field: string) => RadioGroupProps

  switch: (field: string) => CheckboxProps
  checkbox: (field: string) => CheckboxProps
  radio: (field: string, value: string) => RadioProps
  radioChip: (field: string) => RadioChipProps
  select: (field: string) => SelectProps

  multiSwitch: (field: string, value: any) => CheckboxProps
  multiCheckbox: (field: string, value: any) => CheckboxProps

  autocomplete: (field: string) => Partial<AutocompleteProps<any, any, any, any>>
  autocompleteTextField: (field: string) => TextFieldProps

  textFieldDropdown: (field: string) => TextFieldDropdownProps
  numberFieldDropdown: (field: string) => NumberFieldDropdownProps
  datePickerDropdown: (field: string) => DatePickerDropdownProps
  radioGroupDropdown: (field: string) => RadioGroupDropdownProps
}

export const createMuiFormBinder = (form: ObservableForm): MuiFormBinder => ({
  form: createFormBinder(form),

  textField: createTextFieldBinder(form),
  numberField: createNumberFieldBinder(form),
  rating: createRatingFormBinder(form),
  datePicker: createDatePickerBinder(form),

  radioGroup: createRadioGroupBinder(form),
  booleanRadioGroup: createBooleanRadioGroupBinder(form),

  switch: createCheckboxBinder(form),
  checkbox: createCheckboxBinder(form),
  radio: createRadioBinder(form),
  radioChip: createRadioChipBinder(form),
  select: createSelectBinder(form),

  multiSwitch: createMultiCheckboxBinder(form),
  multiCheckbox: createMultiCheckboxBinder(form),

  autocomplete: createAutocompleteBinder(form),
  autocompleteTextField: createAutocompleteTextFieldBinder(form),

  textFieldDropdown: createTextFieldDropdownBinder(form),
  numberFieldDropdown: createNumberFieldDropdownBinder(form),
  datePickerDropdown: createDatePickerDropdownBinder(form),
  radioGroupDropdown: createRadioGroupDropdownBinder(form),
})

const createFormBinder = (form: ObservableForm) => (): FormHTMLAttributes<HTMLFormElement> => {
  return {
    onSubmit: (e) => {
      e.stopPropagation()
      e.preventDefault()

      form.submit()
    },
  }
}

const createTextFieldBinder = (form: ObservableForm) => (field: string): TextFieldProps => {
  const errors = form.getErrorsAt(field)

  return {
    name: field,
    value: form.getAt(field) ?? "",
    onChange: (event) => form.setAt(field, event.target.value),
    error: !!(errors && errors.length),
    helperText: form.getFirstFieldError(field),
  }
}

const createNumberFieldBinder = (form: ObservableForm) => (
  field: string,
  allowFloat = false,
  allowMinus = false,
  allowDot = false,
  allowEChar = false
): TextFieldProps => {
  const errors = form.getErrorsAt(field)

  return {
    name: field,
    value: form.getAt(field),
    onChange: (event) => {
      const value = event.target.value ?? ""
      const parsedNumber = allowFloat ? parseFloat(value) : parseInt(value, 10)

      isNaN(parsedNumber) ? form.setAt(field, undefined) : form.setAt(field, parsedNumber)
    },
    onPaste: (event) => {
      let value = event.clipboardData.getData("text") ?? ""
      const parsedNumber = allowFloat ? parseFloat(value) : parseInt(value, 10)

      if (isNaN(parsedNumber)) {
        event.preventDefault()
      }
    },
    onKeyPress: (event) => {
      if (!allowMinus && event.key === "-") {
        event.preventDefault()
      }
      if (!allowDot && event.key === ".") {
        event.preventDefault()
      }
      if (!allowEChar && (event.key === "e" || event.key === "E")) {
        event.preventDefault()
      }
    },
    error: !!(errors && errors.length),
    helperText: form.getFirstFieldError(field),
    type: "number",
  }
}

const createRatingFormBinder = (form: ObservableForm) => (field: string) => {
  return {
    name: field,
    value: form.getAt(field),
    onChange: (_, newValue: number | null) => {
      form.setAt(field, newValue)
    },
  }
}

const createDatePickerBinder = (form: ObservableForm) => (field: string): DatePickerProps => {
  return {
    name: field,
    value: form.getAt(field),
    onChange: (newValue) => form.setAt(field, newValue),
    error: form.hasErrorsAt(field),
    helperText: form.getFirstFieldError(field),
  }
}

const createRadioGroupBinder = (form: ObservableForm) => (field: string): RadioGroupProps => {
  return {
    name: field,
    value: form.getAt(field) ?? "",
  }
}

const createBooleanRadioGroupBinder = (form: ObservableForm) => (field: string): RadioGroupProps => {
  return {
    name: field,
    value: form.getAt(field),
    onChange: (_, value: string) => form.setAt(field, value === "true"),
  }
}

const createRadioBinder = (form: ObservableForm) => (field: string, value: string): RadioProps => {
  return {
    name: field,
    value,
    onChange: (e) => form.setAt(field, e.target.value),
  }
}

const createRadioChipBinder = (form: ObservableForm) => (field: string): RadioChipProps => {
  return {
    value: form.getAt(field),
    onChange: (value) => form.setAt(field, value),
  }
}

const createSelectBinder = (form: ObservableForm) => (field: string): SelectProps => {
  return {
    name: field,
    value: form.getAt(field) ?? "",
    onChange: (e) => form.setAt(field, e.target.value),
    error: form.hasErrorsAt(field),
  }
}

const createCheckboxBinder = (form: ObservableForm) => (field: string): CheckboxProps => {
  return {
    name: field,
    checked: isChecked(form.getAt(field)),
    onChange: (e) => form.setAt(field, isChecked(e.target.checked)),
  }
}

const createMultiCheckboxBinder = (form: ObservableForm) => (field: string, value: any): CheckboxProps => {
  const values: any[] = form.getAt(field) ?? []
  const checked = values.includes(value)
  const handleChange = () => {
    if (values.includes(value)) {
      form.setAt(
        field,
        values.filter((v) => v !== value)
      )
    } else {
      form.setAt(field, [...values, value])
    }
  }

  return {
    name: field,
    checked: checked,
    onChange: handleChange,
  }
}

const createAutocompleteBinder = (form: ObservableForm) => (
  field: string
): Partial<AutocompleteProps<any, any, any, any>> => {
  return {
    value: form.getAt(field) ?? "",
    onChange: (_, value) => form.setAt(field, value),
  }
}

const createAutocompleteTextFieldBinder = (form: ObservableForm) => (field: string): TextFieldProps => {
  const errors = form.getErrorsAt(field)

  return {
    name: field,
    value: form.getAt(field) ?? "",
    error: !!(errors && errors.length),
    helperText: form.getFirstFieldError(field),
  }
}

const createTextFieldDropdownBinder = (form: ObservableForm) => (field: string): TextFieldDropdownProps => {
  const errors = form.getErrorsAt(field)

  return {
    name: field,
    value: form.getAt(field),
    form: form,
    onChange: (newValue) => form.setAt(field, newValue),
    error: !!(errors && errors.length),
    helperText: form.getFirstFieldError(field),
  }
}

const createNumberFieldDropdownBinder = (form: ObservableForm) => (field: string): NumberFieldDropdownProps => {
  const errors = form.getErrorsAt(field)

  return {
    name: field,
    form,
    value: form.getAt(field),
    onChange: (newValue) => form.setAt(field, newValue),
    error: !!(errors && errors.length),
    helperText: form.getFirstFieldError(field),
  }
}

const createDatePickerDropdownBinder = (form: ObservableForm) => (field: string): DatePickerDropdownProps => {
  const errors = form.getErrorsAt(field)

  return {
    name: field,
    form,
    value: form.getAt(field),
    onChange: (newValue) => form.setAt(field, newValue),
    error: !!(errors && errors.length),
    helperText: form.getFirstFieldError(field),
  }
}

const createRadioGroupDropdownBinder = (form: ObservableForm) => (field: string): RadioGroupDropdownProps => {
  return {
    name: field,
    form,
    value: form.getAt(field),
    onChange: (newValue) => form.setAt(field, newValue),
  }
}

const isChecked = (value: any): boolean => [true, 1, "1", "true", "on"].includes(value)

import { CancelToken } from "axios"
import { AccessTokenFetcher } from "../../../oneportal/helpers/types/AccessTokenFetcher"
import { ISODate } from "../../../oneportal/helpers/types/ISODate"
import { ApiClient } from "../../../oneportal/services/api/ApiClient"
import { createChunkedRequest } from "../../../oneportal/services/api/helpers/createChunkedRequest"
import { createChunkedResponse } from "../../../oneportal/services/api/helpers/createChunkedResponse"
import { submitInvisibleForm } from "../../../oneportal/services/api/helpers/submitInvisibleForm"
import { ChunkedRequest } from "../../../oneportal/services/api/types/ChunkedRequest"
import { ChunkedResponse } from "../../../oneportal/services/api/types/ChunkedResponse"
import { CustomPatientId } from "../../../oneportal/services/api/types/CustomPatientId"
import { Patient } from "../../../oneportal/services/api/types/Patient"
import { PatientId } from "../../../oneportal/services/api/types/PatientId"
import { SortedRequest } from "../../../oneportal/services/api/types/SortedRequest"
import { ThreeShapeCase } from "../../../oneportal/services/api/types/ThreeShapeCase"
import { createCreateTaskFormParams } from "../../helpers/createCreateTaskFormParams"
import { CaseStateFilter } from "../../pages/types/CaseStateFilter"
import { createCaseInstanceDraftCreationRequestDTO } from "./helpers/createCaseInstanceDraftCreationRequestDTO"
import { createInvoiceResponse } from "./helpers/createInvoiceResponse"
import { Addresses } from "./types/Address"
import { Appointment } from "./types/Appointment"
import { CaseInstance } from "./types/CaseInstance"
import { CaseInstanceDraftResponse } from "./types/CaseInstanceDraftResponse"
import { CaseInstanceId } from "./types/tags/CaseInstanceId"
import { Delivery } from "./types/Delivery"
import { DocumentId } from "./types/tags/DocumentId"
import { DocumentType } from "./types/DocumentType"
import { FormDefinition } from "./types/FormDefinition"
import { OnePortalDocument } from "./types/OnePortalDocument"
import { OnePortalInvoice } from "./types/OnePortalInvoice"
import { TaskWithVariables } from "./types/TaskWithVariables"
import { TaskId } from "./types/tags/TaskId"
import { TaskState } from "./types/TaskState"
import { TaskWithCaseInfo } from "./types/TaskWithCaseInfo"
import { CaseType } from "./types/v2/CaseType"
import { UpdateDraftRequest } from "./types/UpdateDraftRequest"
import { DraftId } from "./types/tags/DraftId"
import { PatientData } from "../../components/caseForms/CreateCaseFormV2/store/features/caseInit/caseInitInitialState"

export const createSiabApiClient = (api: ApiClient, fetchAccessToken: AccessTokenFetcher) => {
  return {
    getCases: getCases(api),
    getCase: getCase(api),
    deleteCase: deleteCase(api),
    deleteDraftCase: deleteDraftCase(api),
    getTasks: getTasks(api),
    getPendingTasks: getPendingTasks(api),
    getTask: getTask(api),
    getAppointments: getAppointments(api),
    getCaseDetailsForm: getCaseDetailsForm(api),
    getDeliveries: getDeliveries(api),
    getTaskForm: getTaskForm(api),
    saveTaskForm: saveTaskForm(api),
    completeTaskForm: completeTaskForm(api),
    createCaseForm: createCaseForm(api),
    createCaseDraft: createCaseDraft(api),
    startCase: startCase(api),
    updateDraft: updateDraft(api),
    getCaseDraft: getCaseDraft(api),
    saveCaseForm: saveCaseForm(api),
    loadCaseForm: loadCaseForm(api),
    loadTaskForm: loadTaskForm(api),
    getDocuments: getDocuments(api),
    getDocumentInBlob: getDocumentInBlob(api),
    uploadDocuments: uploadDocuments(api),
    removeDocument: removeDocument(api),
    getDocumentData: getDocumentData(api, fetchAccessToken),
    getDocumentDataByContentStoreId: getDocumentDataByContentStoreId(api),
    getPatients: getPatients(api),
    getThreeShapeCases: getThreeShapeCases(api),
    getInvoices: getInvoices(api),
    downloadAllInvoiceInZip: downloadAllInvoiceInZip(api, fetchAccessToken),
    getDeliveryAddresses: getDeliveryAddresses(api),
  }
}

export type GetCasesRequest = ChunkedRequest &
  SortedRequest & {
    searchText: string
    state?: CaseStateFilter | null
    finished?: boolean
    havingPendingTasks?: boolean
  }

export type GetCasesResponse = {
  totalPendingTasks: number
  totalPendingCases: number
  caseInstances: CaseInstance[]
}

export type GetCasesChunkedResponse = {
  totalPendingTasks: number
  totalPendingCases: number
  caseInstances: ChunkedResponse<CaseInstance>
}

const getCases = (api: ApiClient) => async (args?: GetCasesRequest): Promise<GetCasesChunkedResponse | undefined> => {
  const req = createChunkedRequest(args)
  const res = await api.post<GetCasesResponse>("/case-instances/search", req)

  return {
    ...res.data,
    caseInstances: createChunkedResponse(req, res.data.caseInstances),
  }
}

const getCase = (api: ApiClient) => async (caseId: CaseInstanceId): Promise<CaseInstance | undefined> => {
  try {
    const res = await api.get<CaseInstance>(`/case-instances/${caseId}`)
    return res.data
  } catch (err) {}
}

export type DeletePatientRequest = {
  caseId: CaseInstanceId | DraftId
}

const deleteCase = (api: ApiClient) => async (request: DeletePatientRequest): Promise<boolean> => {
  try {
    await api.delete(`/case-instances/delete/${request.caseId}`)

    return true
  } catch (err) {}

  return false
}

const deleteDraftCase = (api: ApiClient) => async (request: DeletePatientRequest): Promise<boolean> => {
  try {
    await api.delete(`/case-instances/draft/${request.caseId}/delete`)

    return true
  } catch (err) {}

  return false
}

export type GetTasksRequest = ChunkedRequest &
  SortedRequest & {
    caseInstanceId?: CaseInstanceId
    state?: TaskState
    name?: string // searches only certain tasks, ie. "Join Call"
  }

const getTasks = (api: ApiClient) => async (args?: GetTasksRequest): Promise<TaskWithCaseInfo[] | undefined> => {
  try {
    const req = createChunkedRequest(args)
    const res = await api.post<TaskWithCaseInfo[]>("/tasks/search", req)

    return res.data
  } catch (err) {}
}

export type GetPendingTasksRequest = ChunkedRequest &
  SortedRequest & {
    state?: TaskState
    skipContributors?: boolean
  }

const getPendingTasks = (api: ApiClient) => async (): Promise<TaskWithCaseInfo[] | undefined> => {
  try {
    const args: GetPendingTasksRequest = {
      state: TaskState.Pending,
      skipContributors: true,
    }

    const req = createChunkedRequest(args)
    const res = await api.post<TaskWithCaseInfo[]>("/tasks/search", req)

    return res.data
  } catch (err) {}
}

export type GetDeliveries = ChunkedRequest &
  SortedRequest & {
    caseInstanceIds?: CaseInstanceId[]
  }

const getDeliveries = (api: ApiClient) => async (args?: GetDeliveries): Promise<Delivery[] | undefined> => {
  try {
    const req = createChunkedRequest(args ?? {})
    const res = await api.post<Delivery[]>("/deliveries/search", req)

    return res.data
  } catch (err) {}
}

const getAppointments = (api: ApiClient) => async (): Promise<Appointment[] | undefined> => {
  try {
    const res = await api.get<Appointment[]>("/appointments")

    return res.data
  } catch (err) {}
}

const getTask = (api: ApiClient) => async (taskId: TaskId): Promise<TaskWithVariables | undefined> => {
  try {
    const res = await api.get<TaskWithVariables>(`/tasks/${taskId}`)

    return res.data
  } catch (err) {}
}

const getCaseDetailsForm = (api: ApiClient) => async (caseId: CaseInstanceId): Promise<FormDefinition | undefined> => {
  try {
    const res = await api.get<FormDefinition>(`/case-instances/${caseId}/work-form`)

    return res.data
  } catch (err) {}
}

export type CreatTaskFormRequest = { taskId: TaskId; completed?: boolean }

const getTaskForm = (api: ApiClient) => async (req: CreatTaskFormRequest): Promise<FormDefinition | undefined> => {
  try {
    const params = createCreateTaskFormParams(req)
    // Let the backend know whether it has to ask for the completed tasks or not
    const res = await api.get<FormDefinition>(`/tasks/${req.taskId}/form`, { params })

    return res.data
  } catch (err) {}
}

export type CreateCaseDraftRequest = {
  patient: PatientData
  case: {
    caseOwnerB2cUserId: string
    contributorsB2cUserIds: string[]
    caseType: CaseType
    surgeryDate?: ISODate
  }
}

const createCaseDraft = (api: ApiClient) => async (
  args: CreateCaseDraftRequest
): Promise<CaseInstanceDraftResponse> => {
  const body = createCaseInstanceDraftCreationRequestDTO(args)
  const res = await api.post<CaseInstanceDraftResponse>("/case-instances/draft", body)
  return res.data
}

const updateDraft = (api: ApiClient) => async (args: UpdateDraftRequest): Promise<any> => {
  const { caseDraftId, variables } = args
  const res = await api.patch(`/case-instances/draft/${caseDraftId}`, { variables })
  return res.data
}

const getCaseDraft = (api: ApiClient) => async (caseId: string): Promise<CaseInstanceDraftResponse> => {
  const res = await api.get<CaseInstanceDraftResponse>(`/case-instances/draft/${caseId}`)
  return res.data
}

const startCase = (api: ApiClient) => async (args: { id: string }): Promise<CaseInstance> => {
  const res = await api.post<CaseInstance>("/case-instances/start", args)
  return res.data
}

/**
 * @deprecated
 */
const createCaseForm = (api: ApiClient) => async (): Promise<FormDefinition | undefined> => {
  try {
    const res = await api.get("/forms/start-form")

    return res.data
  } catch (err) {}
}

export type SaveTaskRequest = {
  variables: Record<string, string>
}

// todo: figure out return type
const saveTaskForm = (api: ApiClient) => async (taskId: TaskId, form: SaveTaskRequest): Promise<any> => {
  try {
    const res = await api.post(`/tasks/${taskId}/update`, form)

    return res.data
  } catch (err) {}
}

export type CompleteTaskRequest = {
  outcome: string
  variables: Record<string, string>
}

// todo: figure out return type
const completeTaskForm = (api: ApiClient) => async (taskId: TaskId, form: CompleteTaskRequest): Promise<any> => {
  try {
    const res = await api.post(`/tasks/${taskId}/complete`, form)

    return res.data
  } catch (err) {}
}

export type CreateCaseRequest = {
  patientId?: PatientId
  newPatient?: {
    firstName: string
    lastName: string
    birthDate: ISODate
    customPatientId: CustomPatientId
  } | null
  variables: object
}

const saveCaseForm = (api: ApiClient) => async (data: CreateCaseRequest): Promise<CaseInstance | undefined> => {
  try {
    const res = await api.post("/case-instances", data)

    return res.data
  } catch (err) {}
}

// todo: figure out return type
const loadCaseForm = (api: ApiClient) => async (
  caseInstanceId: CaseInstanceId
): Promise<FormDefinition | undefined> => {
  try {
    const res = await api.get(`/case-instances/${caseInstanceId}/form/start-form`)

    return res.data
  } catch (err) {}
}

const loadTaskForm = (api: ApiClient) => async (taskId: TaskId): Promise<FormDefinition | undefined> => {
  try {
    const res = await api.get(`/tasks/${taskId}/form`)

    return res.data
  } catch (err) {}
}

const getDocuments = (api: ApiClient) => async (
  caseInstanceId: CaseInstanceId
): Promise<OnePortalDocument[] | undefined> => {
  try {
    const res = await api.post<OnePortalDocument[]>("/documents/search", { caseInstanceId })
    return res.data
  } catch (err) {}
}

export type UploadFileRequest = {
  taskId?: TaskId
  file: File
  onUploadProgress: (progressEvent: ProgressEvent) => void
  cancelToken: CancelToken
  provisional?: boolean
}

const uploadDocuments = (api: ApiClient) => async (args: UploadFileRequest) => {
  const { taskId, file, onUploadProgress, cancelToken } = args
  const data = new FormData()
  data.set("file", file)

  if (!!taskId) data.set("taskId", taskId)

  const res = await api.post<OnePortalDocument>(`/documents`, data, { onUploadProgress, cancelToken })

  return res.data
}

const removeDocument = (api: ApiClient) => async (taskId: TaskId, documentId: DocumentId): Promise<boolean> => {
  try {
    await api.delete(`/tasks/${taskId}/documents/${documentId}`)

    return true
  } catch (err) {
    return false
  }
}

const getDocumentData = (api: ApiClient, fetchAccessToken: AccessTokenFetcher) => async (documentId: DocumentId) => {
  try {
    submitInvisibleForm(`${api.defaults.baseURL}/documents/${documentId}/data`, {
      access_token: await fetchAccessToken(),
    })
  } catch (err) {
    console.error(err)
  }
}

export type GetDocumentDataByContentStoreIdRequest = {
  contentStoreId: string
  download?: boolean
}

const getDocumentDataByContentStoreId = (api: ApiClient) => async (
  data: GetDocumentDataByContentStoreIdRequest
): Promise<Blob | undefined> => {
  const { contentStoreId, download = true } = data
  try {
    const res = await api.get<Blob>(`/documents/content-store/${contentStoreId}/data`, {
      params: {
        download,
      },
      responseType: "blob",
    })

    return res.data
  } catch (err) {}
}

const getDocumentInBlob = (api: ApiClient) => async (document: OnePortalDocument): Promise<Blob | undefined> => {
  try {
    const res = await api.post<Blob>(`/documents/${document.id}/data`, {}, { responseType: "blob" })

    return new Blob([res.data], { type: document.mimeType })
  } catch (err) {}
}

export type GetPatientsRequest = ChunkedRequest &
  SortedRequest & {
    name?: string
    keyword?: string
    firstName?: string
    lastName?: string
    birthDate?: ISODate
    filter?: string
    customPatientId?: string
  }

const getPatients = (api: ApiClient) => async (
  args?: GetPatientsRequest
): Promise<ChunkedResponse<Patient> | undefined> => {
  try {
    const req = createChunkedRequest(args)
    const res = await api.post<Patient[]>(`/patients/search`, req)

    return createChunkedResponse(req, res.data)
  } catch (err) {}
}

export type GetThreeShapeCasesRequest = ChunkedRequest &
  SortedRequest & {
    searchString: string
  }

const getThreeShapeCases = (api: ApiClient) => async (
  args?: GetThreeShapeCasesRequest
): Promise<ChunkedResponse<ThreeShapeCase> | undefined> => {
  try {
    const req = createChunkedRequest(args)
    const res = await api.post<ThreeShapeCase[]>(`/three-shape-cases/search`, req)

    return createChunkedResponse(req, res.data)
  } catch (err) {}
}

const getInvoices = (api: ApiClient) => async (
  caseInstanceId: CaseInstanceId
): Promise<OnePortalInvoice[] | undefined> => {
  try {
    const res = await api.post<OnePortalInvoice[]>("/documents/search", {
      caseInstanceId,
      type: DocumentType.INVOICE,
    })

    return createInvoiceResponse(res.data)
  } catch (err) {}
}

const getDeliveryAddresses = (api: ApiClient) => async (caseInstanceId: string): Promise<Addresses | undefined> => {
  try {
    const res = await api.get<Addresses>(`/case-instances/${caseInstanceId}/addresses`)
    return res.data
  } catch (err) {}
}

const downloadAllInvoiceInZip = (api: ApiClient, fetchAccessToken: AccessTokenFetcher) => async (
  caseInstanceId: CaseInstanceId
): Promise<ArrayBuffer | undefined> => {
  try {
    const response = await api.post<ArrayBuffer>(
      `/documents/zip-download`,
      {
        caseInstanceId,
        type: DocumentType.INVOICE,
        access_token: await fetchAccessToken(),
      },
      {
        responseType: "arraybuffer",
      }
    )

    return response.data
  } catch (err) {}
}

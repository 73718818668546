import { RouteComponentProps, useHistory, useLocation } from "react-router-dom"
import React, { useEffect, useRef, useState } from "react"
import { LoadedRouteWithComponentAndPreloader } from "./types/LoadedRouteWithComponentAndPreloader"
import { __RouterContext } from "react-router"
import { loadRouteForPath } from "./helpers/loadRouteForPath"
import { RequestToRedirect } from "./types/RequestToRedirect"
import { useShowProgressIndicator } from "../../hooks/useShowProgressIndicator"
import { useShowProgressOverlay } from "../../hooks/useShowProgressOverlay"
import { useAsync } from "../user-async"
import { useIsFirstPageLoad } from "../../hooks/useIsFirstPageLoad"
import { useAlert } from "../../hooks/useAlert"
import { RouterErrorBoundary } from "../../components/RouterErrorBoundary"
import { RouterProps } from "./Router"
import { useLocale } from "../../hooks/useLocale"

export type RoutesProps<TContext> = RouterProps<TContext>

export const Routes = <TContext,>(props: RoutesProps<TContext>) => {
  const { routes, policy, homePath, loginPath, NotFound, RenderErrorFallback, logger, context } = props
  const showProgressIndicator = useShowProgressIndicator()
  const showProgressOverlay = useShowProgressOverlay()
  const isFirstPageLoad = useIsFirstPageLoad()
  const alert = useAlert()
  const loc = useLocale()
  const [renderError, setRenderError] = useState(undefined)
  const isFirstLoad = () => !routeRef.current

  const onLoadingStart = async (firstLoad: boolean) => {
    isFirstPageLoad.set(firstLoad)

    if (firstLoad) {
      showProgressOverlay.set(true)
    } else {
      showProgressIndicator.set(true)
    }
  }

  const onLoadingEnd = async (firstLoad: boolean) => {
    showProgressOverlay.set(false)
    showProgressIndicator.set(false)
    setRenderError(undefined)
  }

  const onLoadingError = (err: any) => {
    alert(loc.oneportal.router.messages.loadError.get(), { variant: "error" })
  }

  const onRenderError = (err: any) => {
    setRenderError(err)
  }

  const location = useLocation()
  const history = useHistory()
  const requestToRedirect: RequestToRedirect = (path: string) => history.replace(path)
  const routeRef = useRef<LoadedRouteWithComponentAndPreloader<TContext> | undefined>()
  const locationRef = useRef<RouteComponentProps["location"]>(location)
  // used to indicate that location ref has changed
  const [locationRefTick, setLocationRefTick] = useState(0)

  const routeHandle = useAsync(async () => {
    try {
      logger?.info("Loading route:", location.pathname)

      const route = await loadRouteForPath({
        routes,
        context,
        path: location.pathname,
        policy,
        requestToRedirect,
        logger,
        homePath,
        loginPath,
        NotFound,
      })

      try {
        if (route.preloader) {
          route.preloadedData = await route.preloader()
        }
      } catch (err) {
        logger?.error("Route preloader failed for path:", location.pathname).error("This error was thrown:", err)

        onLoadingError(err)
      }

      return route
    } catch (err) {
      logger?.error("Handler failed for path:", location.pathname).error("This error was thrown:", err)

      onLoadingError(err)
    }
  }, [policy, location.pathname, routes.length])

  useEffect(() => {
    if (routeHandle.loading) {
      onLoadingStart(isFirstLoad())
    } else {
      onLoadingEnd(isFirstLoad())
    }
  }, [routeHandle.loading])

  useEffect(() => {
    // after a route has been loaded, propagate the result
    // and current location to the respective refs, so everything
    // is rendered properly
    if (routeHandle.result) {
      routeRef.current = routeHandle.result
      locationRef.current = location
      setLocationRefTick(locationRefTick + 1)

      logger?.info("Mounting component for route:", location.pathname)
    }
  }, [routeHandle.result])

  useEffect(() => {
    // keep search params in sync with the current location ref, as long as
    // the path has not been changed, otherwise simply ignore it, as soon as
    // the other route has been fully loaded it will update the location ref
    if (location.pathname === locationRef.current.pathname) {
      locationRef.current.search = location.search
      setLocationRefTick(locationRefTick + 1)
    }
  }, [location.search])

  const Component = routeRef.current?.component ?? (() => null)

  return (
    <__RouterContext.Consumer>
      {(context) => {
        return (
          <__RouterContext.Provider
            value={{
              ...context,
              location: locationRef.current,
              match: routeRef.current?.match ?? (null as any),
            }}
          >
            <RouterErrorBoundary error={renderError} onError={onRenderError} fallback={<RenderErrorFallback />}>
              <Component {...routeRef.current?.preloadedData} />
            </RouterErrorBoundary>
          </__RouterContext.Provider>
        )
      }}
    </__RouterContext.Consumer>
  )
}

import buttercms from "buttercms"
import { PageType } from "./types/PageType"
import { PageSlug } from "./types/PageSlug"
import { ServicePageSchema } from "./types/ServicePageSchema"
import { UploadingGuidePageSchema } from "./types/UploadingGuidePageSchema"
import { getFields } from "./helpers/getFields"
import { ServicePageWidget } from "./types/ServicePageWidget"
import { DiscoveryPageSchema } from "./types/DiscoveryPageSchema"

export type CreateButterCmsServiceArgs = {
  token: string
  defaultLocale: string
}

export const createButterCmsService = (args: CreateButterCmsServiceArgs) => {
  const { token, defaultLocale } = args

  const butter = buttercms(token)

  const getUploadingGuidePage = async (locale = defaultLocale) => {
    try {
      const res = await butter.page.retrieve(PageType.None, PageSlug.UploadingGuide, { locale })

      const data = getFields(res.data.data as UploadingGuidePageSchema)

      return {
        ...data,
        tabs: data.tabs.map((tab) => figureOutWhichSectionHasBottomBorder(getFields(tab))),
      }
    } catch (err) {}
  }

  const getDiscoveryPage = async (slug: PageSlug, locale = defaultLocale) => {
    try {
      const res = await butter.page.retrieve(PageType.Discovery, slug, { locale })

      const page = res.data.data as DiscoveryPageSchema

      if (!page) return
      return page
    } catch (err) {}
  }

  // SERVICE PAGES --------------------------------------------------------------------------------

  // Service page banner

  const getServicePageBanner = async (slug: PageSlug, locale = defaultLocale) => {
    try {
      const res = await butter.page.retrieve(PageType.ServicePage, slug, { locale })
      const page = res.data.data as ServicePageSchema

      if (!page) return

      const {
        title,
        long_description: description,
        service_page_action_buttons,
        picture,
        service_page_steps,
      } = page.fields

      return {
        slug: page.slug,
        title,
        description,
        actionButtons: service_page_action_buttons.map(getFields),
        steps: service_page_steps.map(getFields),
        picture,
      }
    } catch (err) {}
  }

  // Service page widget

  const getServicePageWidget = async (
    slug: PageSlug,
    locale = defaultLocale
  ): Promise<undefined | ServicePageWidget> => {
    try {
      const res = await butter.page.retrieve(PageType.ServicePage, slug, { locale })
      const page = res.data.data as ServicePageSchema

      if (!page) return

      const { title, picture, short_description: description, buttons } = page.fields

      return {
        title,
        picture,
        description,
        buttons: buttons.map(getFields),
      }
    } catch (err) {}
  }

  return {
    getUploadingGuidePage,
    getDiscoveryPage,
    getServicePageBanner,
    getServicePageWidget,
  }
}

type Tab = {
  title: string
  document_description: { description: string; icon: string; section_title: string; sub_section_title: string }[]
}
const figureOutWhichSectionHasBottomBorder = (tab: Tab) => {
  return {
    ...tab,
    document_description: tab.document_description.map((section, index, allSections) => {
      return {
        ...section,
        bottomBorder: index < allSections.length - 1 && !allSections[index + 1].section_title,
      }
    }),
  }
}

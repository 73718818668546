import { useEffect } from "react"
import { useCustomerPortalApiClient } from "../../customerportal/hooks/useCustomerPortalApiClient"
import { DateFormatPreference } from "../../customerportal/services/api/types/DateFormatPreference"
import { useAsync } from "../libraries/user-async"
import { ToothPositionFormat } from "../../registry/components/DentalChart/types/ToothPositionFormat"
import { useTranslator } from "./useTranslator"

export const dateFormatKey = "date-format"
export const toothPositionFormatKey = "tooth-position-format"

export const usePreferencesInterface = () => {
  const api = useCustomerPortalApiClient()
  const defaultDateFormat = DateFormatPreference.DayMonthYear
  const defaultToothPositionFormat = ToothPositionFormat.FDI
  const dateFormatFromLocalStorage = sessionStorage.getItem(dateFormatKey)
  const toothPositionFormatFromLocalStorage = localStorage.getItem(toothPositionFormatKey)
  const profile = useAsync(() =>
    !!dateFormatFromLocalStorage || !!toothPositionFormatFromLocalStorage ? null : api.getMyProfile()
  )
  const translator = useTranslator()

  const currentDateFormat = dateFormatFromLocalStorage
    ? (dateFormatFromLocalStorage as DateFormatPreference)
    : profile.result?.dateFormat ?? defaultDateFormat
  const currentToothPositionFormat: ToothPositionFormat = toothPositionFormatFromLocalStorage
    ? (toothPositionFormatFromLocalStorage as ToothPositionFormat)
    : profile.result?.toothPositionFormat ?? defaultToothPositionFormat
  const currentLanguage = translator.language
  const loading = dateFormatFromLocalStorage ? false : profile.loading

  useEffect(() => {
    if (!dateFormatFromLocalStorage && currentDateFormat) {
      sessionStorage.setItem(dateFormatKey, currentDateFormat.toString())
    }
    if (!toothPositionFormatFromLocalStorage && currentToothPositionFormat) {
      localStorage.setItem(toothPositionFormatKey, currentToothPositionFormat.toString())
    }
  }, [currentDateFormat, currentToothPositionFormat])

  return {
    currentLanguage,
    currentDateFormat,
    currentToothPositionFormat,
    loading,
  }
}

import { theme } from "../../../theme"

type DropdownTheme = {
  titleColor: string
  color: string
  backGround: string
}

export const DropdownDarkTheme: DropdownTheme = {
  titleColor: theme.color.grey2,
  color: theme.color.white3,
  backGround: theme.color.black3,
}

export const DropdownLightTheme: DropdownTheme = {
  titleColor: theme.color.black3,
  color: theme.color.black3,
  backGround: theme.color.white3,
}

import { motion } from "framer-motion"
import styled, { css } from "styled-components"
import { theme } from "../../theme"
import { useLocale } from "../hooks/useLocale"

export enum LegalInfoVariant {
  Vertical = "VERTICAL",
  Horizontal = "HORIZONTAL",
}

export type LegalInfoProps = {
  variant?: LegalInfoVariant
  className?: string
}

export const LegalInfo = (props: LegalInfoProps) => {
  const { variant = LegalInfoVariant.Vertical, className } = props
  const loc = useLocale()
  const myLoc = loc.customerportal.oneportalSidebar

  return (
    <Root
      className={className}
      $variant={variant}
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      transition={{ duration: 0.3 }}
    >
      <Item>{myLoc.labels.copyright.get()}</Item>
      <Item href="https://www.straumann.com/us/en/dental-professionals/imprint.html" target="_blank">
        {myLoc.links.imprint.get()}
      </Item>
      <Item href="https://www.straumann.com/us/en/dental-professionals/privacy-notice.html" target="_blank">
        {myLoc.links.privacyPolicy.get()}
      </Item>
      <Item href="https://www.straumann.com/us/en/dental-professionals/legal-notice.html" target="_blank">
        {myLoc.links.legalNotice.get()}
      </Item>
    </Root>
  )
}

const Root = styled(motion.div)<{ $variant: LegalInfoVariant }>`
  padding: ${theme.spacing.l};
  flex-shrink: 0;
  justify-self: flex-end;
  gap: ${theme.spacing.m};

  ${(p) =>
    p.$variant === LegalInfoVariant.Horizontal &&
    css`
      display: grid;
      grid-auto-flow: column;
    `}

  ${(p) =>
    p.$variant === LegalInfoVariant.Vertical &&
    css`
      display: flex;
      flex-direction: column-reverse;
      width: ${theme.ui.sidebar.width};
      overflow-x: hidden;
    `}
`

const Item = styled.a<{ href?: string }>`
  text-decoration: none;
  color: ${theme.color.black6};
  width: fit-content;

  ${(p) =>
    !!p.href &&
    css`
      color: ${theme.color.grey2};

      &:hover {
        text-decoration: underline;
      }

      &:visited {
        color: ${theme.color.grey2};
      }
    `}
`

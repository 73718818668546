import clsx from "clsx"
import React, { forwardRef, ReactNode, useEffect, useMemo, useState } from "react"
import styled, { keyframes } from "styled-components"
import { GuideButton } from "../components/GuideButton"
import { BackLinkType } from "../components/Navbar/types/BackLinkType"
import { ClickAwayListener } from "@material-ui/core"
import { CheckFirstTimeRegister } from "../components/CheckFirstTimeRegister"
import { FeedbackMarker } from "../components/FeedbackMarker"
import { InstructionStorageKeys, Introduction, showInstruction } from "../../customerportal/components/Introduction"
import { Navbar } from "../components/Navbar/Navbar"
import { OneportalSidebar } from "../../customerportal/sidebars/OneportalSidebar"
import { PageTitle } from "../components/PageTitle"
import { Panel } from "../components/Panel"
import { Sidebar } from "../components/Sidebar/Sidebar"
import { Step } from "react-joyride"
import { theme } from "../../theme"
import { useWindowWidth } from "../hooks/useWindowSize"
import { useShowProgressNotification } from "../hooks/useShowProgressNotification"
import { ContentMediumWidth } from "./partials/ContentMediumWidth"
import { ContentSpaceBetween } from "./partials/ContentSpaceBetween"
import { LegalInfo, LegalInfoVariant } from "../components/LegalInfo"

export type IntroductionParams = {
  storageKey: InstructionStorageKeys
  steps: Step[]
  disableScrolling?: boolean
  onStart?: () => void
}

export type MainLayoutProps = {
  children: ReactNode
  sidebar?: ReactNode
  panel?: ReactNode
  title: string
  backLink?: string
  backLinkType?: BackLinkType
  narrow?: boolean
  medium?: boolean
  className?: string
  showNavbar?: boolean
  customNavbar?: ReactNode
  navbarRightContent?: ReactNode
  introductionParams?: IntroductionParams
  showGuideButton?: boolean
  showBottomLegalInfo?: boolean
  hasStickyBottomBar?: boolean
  onIntroductionReset?: () => void
}

let previousLayoutType: string | undefined = undefined
let previousNavbarType: string | undefined = undefined

const persistedSidebarCollapsed = {
  get: (defaultValue: boolean): boolean =>
    JSON.parse(localStorage.getItem("sidebar-collapsed") ?? JSON.stringify(defaultValue)),
  set: (value: boolean) => localStorage.setItem("sidebar-collapsed", JSON.stringify(value)),
}
const persistedPanelCollapsed = {
  get: (defaultValue: boolean): boolean =>
    JSON.parse(localStorage.getItem("panel-collapsed") ?? JSON.stringify(defaultValue)),
  set: (value: boolean) => localStorage.setItem("panel-collapsed", JSON.stringify(value)),
}

export const MainLayout = forwardRef<HTMLDivElement, MainLayoutProps>((props, ref) => {
  const {
    children,
    title,
    sidebar,
    panel = null,
    backLink,
    backLinkType,
    narrow,
    medium,
    className,
    showNavbar = true,
    customNavbar,
    navbarRightContent,
    introductionParams,
    showGuideButton = true,
    showBottomLegalInfo = false,
    hasStickyBottomBar = false,
    onIntroductionReset,
  } = props
  const progressNotification = useShowProgressNotification()
  const windowWidth = useWindowWidth()
  const isSmallScreen = windowWidth < theme.mui.breakpoints.values.md
  const [sidebarCollapsed, setSidebarCollapsed] = useState(persistedSidebarCollapsed.get(false) || isSmallScreen)
  const [panelCollapsed, setPanelCollapsed] = useState(persistedPanelCollapsed.get(false) || isSmallScreen)
  const [toggleIntroductions, setToggleIntroductions] = useState(true)
  const showPanel = panel !== null
  const showSidebar = sidebar !== null && !showPanel
  const centerContent = !showSidebar && !showPanel

  const layoutType = panel ? "panel" : "sidebar"
  const animateLayout = useMemo(() => previousLayoutType !== undefined && previousLayoutType !== layoutType, [])

  const navbarType = backLink ? "action" : "logo"
  const animateNavbar = useMemo(() => previousNavbarType !== undefined && previousNavbarType !== navbarType, [])

  previousLayoutType = layoutType
  previousNavbarType = navbarType

  const handleToggleSidebar = () => {
    if (!isSmallScreen) {
      persistedSidebarCollapsed.set(!sidebarCollapsed)
    }
    setSidebarCollapsed(!sidebarCollapsed)
  }

  const handleTogglePanel = () => {
    if (!isSmallScreen) {
      persistedPanelCollapsed.set(!panelCollapsed)
    }
    setPanelCollapsed(!panelCollapsed)
  }

  const handleScreenSizeChanges = () => {
    if (isSmallScreen) {
      setSidebarCollapsed(true)
      setPanelCollapsed(true)
    } else {
      setSidebarCollapsed(persistedSidebarCollapsed.get(false) || false)
      setPanelCollapsed(persistedPanelCollapsed.get(false) || false)
    }
  }
  useEffect(handleScreenSizeChanges, [isSmallScreen])

  const handleClickOutsideSidebar = () => {
    if (isSmallScreen) {
      setSidebarCollapsed(true)
    }
  }

  const handleClickOutsidePanel = () => {
    if (isSmallScreen) {
      setPanelCollapsed(true)
    }
  }

  const handleIntroductionReset = async () => {
    if (introductionParams?.onStart) {
      introductionParams.onStart()
    }

    setToggleIntroductions(false)
    setTimeout(() => {
      setToggleIntroductions(true)
      if (onIntroductionReset) {
        onIntroductionReset()
      }
    })
  }

  const showIntroduction = introductionParams && showInstruction(introductionParams.storageKey)

  useEffect(() => {
    if (!!showIntroduction) {
      handleIntroductionReset()
    }
  }, [showIntroduction])

  useEffect(() => {
    if (!progressNotification.isClear) progressNotification.clear()
  }, [])

  return (
    <Root className={className}>
      {showGuideButton && (
        <GuideButton introductionParams={introductionParams} onResetIntroduction={handleIntroductionReset} />
      )}

      {toggleIntroductions && introductionParams && (
        <Introduction
          storageKey={introductionParams.storageKey}
          steps={introductionParams.steps}
          disableScrolling={introductionParams.disableScrolling}
        />
      )}

      {/*detect first time register and show new user preferences */}
      <CheckFirstTimeRegister />
      <PageTitle title={title} />

      {showNavbar && (
        <>
          {customNavbar ? (
            customNavbar
          ) : (
            <Navbar
              backLink={backLink}
              backLinkType={backLinkType}
              layout={navbarType}
              animate={animateNavbar}
              customRightContent={navbarRightContent}
              collapsed={sidebarCollapsed}
            />
          )}
        </>
      )}

      <Layout
        className={clsx(
          layoutType,
          animateLayout && "animate",
          panelCollapsed && "panel-collapsed",
          sidebarCollapsed && "sidebar-collapsed"
        )}
      >
        <Wrapper>
          {showSidebar && (
            <ClickAwayListener onClickAway={handleClickOutsideSidebar}>
              <Sidebar collapsed={sidebarCollapsed} onToggle={handleToggleSidebar}>
                {sidebar ?? <OneportalSidebar />}
              </Sidebar>
            </ClickAwayListener>
          )}

          <ContentSpaceBetween ref={ref} id="main-layout-scroller" centerContent={centerContent}>
            {narrow ? (
              <ContentNarrowWidth>
                {children} <FeedbackMarker />
              </ContentNarrowWidth>
            ) : medium ? (
              <ContentMediumWidth>
                {children} <FeedbackMarker />
              </ContentMediumWidth>
            ) : (
              <ContentMaxWidth>
                {children} <FeedbackMarker />
              </ContentMaxWidth>
            )}
            {showBottomLegalInfo && (
              <HorizontalLegalInfo variant={LegalInfoVariant.Horizontal} $hasStickyBottomBar={hasStickyBottomBar} />
            )}
          </ContentSpaceBetween>

          {showPanel && (
            <ClickAwayListener onClickAway={handleClickOutsidePanel}>
              <Panel collapsed={panelCollapsed} onToggle={handleTogglePanel}>
                {panel}
              </Panel>
            </ClickAwayListener>
          )}
        </Wrapper>
      </Layout>
    </Root>
  )
})

const Root = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  width: 100vw;
  height: 100%;
`

const animateSidebar = keyframes`
  from {
    transform: translateX(-${theme.ui.sidebar.width});
  }

  to {
    transform: translateX(0px);
  }
`

const animateSidebarCollapsed = keyframes`
  from {
    transform: translateX(-${theme.ui.sidebar.widthCollapsed});
  }

  to {
    transform: translateX(0px);
  }
`

const animatePanel = keyframes`
  from {
    transform: translateX(${theme.ui.sidebar.width});
  }

  to {
    transform: translateX(0px);
  }
`

const animatePanelCollapsed = keyframes`
  from {
    transform: translateX(${theme.ui.sidebar.widthCollapsed});
  }

  to {
    transform: translateX(0px);
  }
`

const Layout = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  flex-grow: 1;
  overflow-y: auto;
  height: 300px;

  &.sidebar.animate {
    ${theme.mui.breakpoints.up("md")} {
      animation: ${animateSidebar} 0.2s linear;
    }

    ${theme.mui.breakpoints.down("sm")} {
      animation: ${animateSidebarCollapsed} 0.2s linear;
    }

    &.sidebar-collapsed {
      animation: ${animateSidebarCollapsed} 0.2s linear;
    }
  }

  &.panel.animate {
    ${theme.mui.breakpoints.up("md")} {
      animation: ${animatePanel} 0.2s linear;
    }

    ${theme.mui.breakpoints.down("sm")} {
      animation: ${animatePanelCollapsed} 0.2s linear;
    }

    &.sidebar-collapsed {
      animation: ${animatePanelCollapsed} 0.2s linear;
    }
  }
`

const Wrapper = styled.div`
  display: flex;
  flex-grow: 1;
  min-height: 0;
  position: relative;
  height: 100%;
`

const ContentMaxWidth = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  flex-grow: 1;
  flex-shrink: 0;
  width: 100%;
`

const ContentNarrowWidth = styled.div`
  position: relative;
  flex-grow: 1;
  max-width: ${theme.ui.content.widthNarrow};
  width: 100%;
`

const HorizontalLegalInfo = styled(LegalInfo)<{ $hasStickyBottomBar: boolean }>`
  align-self: flex-start;
  margin-bottom: ${(p) => (p.$hasStickyBottomBar ? theme.ui.bottombar.height : 0)};
`

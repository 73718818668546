import { isObjectLike, keys } from "lodash-es"
import { ObjectShape } from "./types"

export const sanitizeObjectShape = <TValue, TSanitizedValue = TValue>(
  value: TValue,
  objectShape: ObjectShape<any> | undefined
): TSanitizedValue => {
  if (!objectShape || !isObjectLike(value)) return value as any

  keys(objectShape).forEach((shapeKey) => {
    const shapeValue = value[shapeKey]
    const shapeSchema = objectShape[shapeKey]
    value[shapeKey] = shapeSchema.sanitize(shapeValue)
  })

  return value as any
}

import { ChunkedRequest } from "../types/ChunkedRequest"

const defaultLimit = 100
const defaultOffset = 0

export const createChunkedRequest = (req?: Partial<ChunkedRequest>): ChunkedRequest => {
  return {
    ...req,
    offset: req?.offset ?? defaultOffset,
    limit: req?.limit ?? defaultLimit,
  }
}

import { RouteDefinition } from "../types/RouteDefinition"
import { RoutePolicy } from "../types/RoutePolicy"
import React, { ComponentType, ReactElement } from "react"
import { RouteGuard } from "../types/RouteGuard"
import { ComponentWithPreloader, loadComponentFromModule } from "./loadComponentFromModule"
import { RequestToRedirect } from "../types/RequestToRedirect"
import { RequestToLoadRouteForDifferentPath } from "../types/RequestToLoadRouteForDifferentPath"
import { GenericLogger } from "../../logger/GenericLogger"

export type LoadComponentForRouteArgs<TContext> = {
  route: RouteDefinition<TContext> | undefined
  policy: RoutePolicy
  requestToLoadRouteForDifferentPath: RequestToLoadRouteForDifferentPath
  requestToRedirect: RequestToRedirect
  homePath: string
  loginPath: string
  NotFound: ComponentType
  logger?: GenericLogger
}

export type ElementWithPreloader = {
  element: ReactElement
  preloader?: () => any
}

export const loadComponentForRoute = async <TContext,>(
  args: LoadComponentForRouteArgs<TContext>
): Promise<ComponentWithPreloader | undefined> => {
  const {
    route,
    policy,
    requestToLoadRouteForDifferentPath,
    requestToRedirect,
    logger,
    homePath,
    loginPath,
    NotFound,
  } = args

  if (route?.redirect) {
    requestToRedirect(route.redirect)

    return
  }

  const routePolicy = route?.policy ?? RoutePolicy.ForAuthenticated
  const guard = route?.guard ?? RouteGuard.RedirectHomeOrShowLogin

  const pageNotFound = !route
  const accessDenied = ![policy, RoutePolicy.ForAnyone].includes(routePolicy)

  // handle 404
  if (pageNotFound) {
    logger?.info("No route matches:", location.pathname).info("Displaying 404 page")

    return { component: NotFound }
  }

  // handle access denied
  if (accessDenied) {
    logger?.info("Route:", route?.path, "is not visible because of policy:", routePolicy)

    if (guard === RouteGuard.ShowPageNotFound) {
      logger?.info("Show 404 for route:", route?.path, "because of guard:", guard)

      return { component: NotFound }
    }

    if (guard === RouteGuard.RedirectHomeOrShowLogin) {
      // do not redirect to login, simply display the route
      if (routePolicy === RoutePolicy.ForAuthenticated) {
        logger?.info("Display route:", loginPath, "instead of route:", route?.path, "because of guard:", guard)

        // render login page without a redirect
        return requestToLoadRouteForDifferentPath(loginPath)
      }

      logger?.info("Redirect route:", route?.path, "to:", homePath, "because of guard:", guard)

      requestToRedirect(homePath)

      return
    }

    if (guard === RouteGuard.RedirectHomeOrLogin) {
      // do not redirect to login, simply display the route
      if (routePolicy === RoutePolicy.ForAuthenticated) {
        logger?.info("Redirect route:", route?.path, "to:", loginPath, "because of guard:", guard)

        requestToRedirect(loginPath)

        return
      }

      logger?.info("Redirect route:", route?.path, "to:", homePath, "because of guard:", guard)

      requestToRedirect(homePath)

      return
    }
  }

  if (!route?.load) {
    logger?.error("Cannot load route:", route?.path, ", method: load() is not configured")

    return
  }

  return loadComponentFromModule(route?.load)
}

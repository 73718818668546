export type AppConfig = ReturnType<typeof getAppConfig>

// hide config inside a factory to prevent global imports
export const getAppConfig = () => {
  return {
    msal: {
      clientId: process.env.REACT_APP_MSAL_CLIENT_ID!,
      authority: process.env.REACT_APP_MSAL_AUTHORITY!,
      redirectUri: process.env.REACT_APP_MSAL_REDIRECT_URI!,
      scopes: [process.env.REACT_APP_MSAL_SCOPE_ACCESS!],
      knownAuthorities: [process.env.REACT_APP_MSAL_KNOWN_AUTHORITIES!],
      extraQueryParameters: { domain_hint: process.env.REACT_APP_MSAL_DOMAIN! },
    },

    api: {
      customerPortalEndpoint: process.env.REACT_APP_CUSTOMER_PORTAL_API_ENDPOINT!,
      registryEndpoint: process.env.REACT_APP_REGISTRY_API_ENDPOINT!,
      siabEndpoint: process.env.REACT_APP_SIAB_API_ENDPOINT!,
      clearCorrectEndpoint: process.env.REACT_APP_CLEAR_CORRECT_API_ENDPOINT!,
      patientHubEndpoint: process.env.REACT_APP_PATIENT_HUB_API_ENDPOINT!,
    },

    aftershipUrl: (trackingNumber: string) => `https://straumann6h.aftership.com/${trackingNumber}`,

    attachment: {
      extensions: process.env.REACT_APP_ATTACHEMENT_EXTENSIONS?.split(",") ?? [],
    },

    butterCms: {
      token: process.env.REACT_APP_BUTTER_CMS_TOKEN!,
      defaultLocale: "en",
    },

    reCaptcha: {
      key: process.env.REACT_APP_RECAPTCHA_KEY,
    },

    gigya: {
      profileUpdate: {
        screenSet: "Straumann-ProfileUpdate",
        startScreen: "straumann-update-profile",
      },
      consents: {
        screenSet: "Straumann-ProfileUpdate",
        startScreen: "straumann-view-consents",
      },
      communicationPreferences: {
        screenSet: "Straumann-ProfileUpdate",
        startScreen: "straumann-update-communication-preferences",
      },
      password: {
        screenSet: "Straumann-ProfileUpdate",
        startScreen: "straumann-gigya-change-password-screen",
      },
    },

    scandit: {
      engineLocation: "https://cdn.jsdelivr.net/npm/scandit-sdk@5.x/build",
      licenseKey: process.env.REACT_APP_SCANDIT_LICENSE_KEY,
    },

    logger: {
      enabled: process.env.REACT_APP_ENABLE_LOGS === "true",
    },

    login: {
      // delay manual login, needed for animations
      timeout: 500,
    },

    logout: {
      // delay manual logout, needed for animations
      timeout: 500,
    },

    changePasswordUrl: (redirectUrl: string) =>
      process.env.REACT_APP_CHANGE_PASSWORD_ENDPOINT!.replace("REDIRECT_URL", redirectUrl),

    autoLogout: {
      enabled: process.env.REACT_APP_ENABLE_AUTO_LOGOUT === "true",
      // check once every 10 seconds
      interval: 10 * 1000,
      // user is considered idle after 20 minutes
      threshold: 20 * 60 * 1000,
      // warn user about the possible logout after 18 minutes
      notificationThreshold: 18 * 60 * 1000,
    },

    page: {
      titleTemplate: (page?: string) => (page ? `${page} | Straumann` : "Straumann"),
    },

    forms: {
      defaultMinLength: 2,
      defaultMaxLength: 60,
      defaultTextareaMaxLength: 1000,
      nameValidationRegex: new RegExp(/^[A-Za-zÀ-ÖØ-öø-ÿ-'. 0-9]+$/), // (at least one char)
      phoneValidationRegex: new RegExp(/^[ +\-\d]{1,30}$/),
      emailValidationRegex: new RegExp(/^[\w-]+@[\w-]+\.\w+$/),
    },

    translator: {
      fallbackLanguage: process.env.REACT_APP_TRANSLATOR_FALLBACK_LANGUAGE,
      debug: process.env.REACT_APP_ENABLE_LOGS === "true",
    },

    link: {
      guaranteeLink: process.env.REACT_APP_REPORT_COMPLICATION_GUARANTEE_LINK!,
      termsAndConditionsLink: process.env.REACT_APP_TERMS_AND_CONDITIONS_LINK!,
      scanLink: process.env.REACT_APP_SCAN_LINK!,
    },

    featureFlag: {
      connectivity: process.env.REACT_APP_FEATURE_FLAG_CP_CONNECTIVITY_ENABLED === "true",
      network: process.env.REACT_APP_FEATURE_FLAG_CP_NETWORK_ENABLED === "true",
      askForHelp: process.env.REACT_APP_FEATURE_FLAG_SIAB_ASK_FOR_HELP_ENABLED === "true",
      uploadDocument: process.env.REACT_APP_FEATURE_FLAG_SIAB_UPDATE_A_DOCUMENT_ENABLED === "true",
      shareCase: process.env.REACT_APP_FEATURE_FLAG_SIAB_SHARE_CASE_ENABLED === "true",
      upcomingShipments: process.env.REACT_APP_FEATURE_FLAG_CP_UPCOMING_SHIPMENTS_ENABLED === "true",
      invoicesTab: process.env.REACT_APP_FEATURE_FLAG_SIAB_INVOICES_TAB_ENABLED === "true",
      profileV2: process.env.REACT_APP_FEATURE_FLAG_CP_PROFILE_V2_ENABLED === "true",
      languageFrench: process.env.REACT_APP_FEATURE_FLAG_CP_FRENCH_ENABLED === "true",
      siabApp: process.env.REACT_APP_FEATURE_FLAG_SIAB_APP_ENABLED === "true",
      addressesInCreateV2AndDetails:
        process.env.REACT_APP_FEATURE_FLAG_SIAB_ADDRESSES_IN_CREATE_V2_AND_DETAILS_ENABLED === "true",
    },

    applicationInsights: {
      key: process.env.REACT_APP_APPLICATION_INSIGHTS_KEY,
    },

    firebase: {
      config: {
        apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
        authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
        projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
        storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
        messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
        appId: process.env.REACT_APP_FIREBASE_APP_ID,
      },
      vapidKey: process.env.REACT_APP_FIREBASE_VAPID_KEY,
    },
  }
}

import React, { useEffect } from "react"
import styled from "styled-components"
import { introductionsElementIds } from "../../../customerportal/components/Introduction"
import { useCustomerPortalApiClient } from "../../../customerportal/hooks/useCustomerPortalApiClient"
import { theme } from "../../../theme"
import { useProfileInfo } from "../../hooks/useProfileInfo"
import { useAsync } from "../../libraries/user-async"
import { LogoutIcon, MyAccountIcon, OrganisationIcon } from "../../../../assets/icons"
import { ProfileMenu } from "@op-str/op-component-library"
import { useLinks } from "../../hooks/useLinks"
import { useLocale } from "../../hooks/useLocale"

export const Profile = () => {
  const api = useCustomerPortalApiClient()
  const profile = useAsync(() => api.getMyProfile())
  const avatar = useAsync(() => api.getMyAvatar())
  const profileValue = useProfileInfo()
  const profileInfo = profileValue.get()
  const links = useLinks()
  const loc = useLocale()

  if (!profile) return null

  useEffect(() => {
    profileValue.set(profile.result)
  }, [profile.result])

  return (
    <Root id={introductionsElementIds.dashboard.profileButton}>
      <ProfileMenu
        noWrap={true}
        individual={{
          firstName: profileInfo?.firstName,
          lastName: profileInfo?.lastName,
          organization: profileInfo?.organization?.name,
          avatar: avatar.result,
        }}
        items={[
          {
            link: links.customerportal.myProfile(),
            label: loc.oneportal.profileMenu.links.profile.get(),
            icon: <MyAccountIcon />,
          },
          {
            link: links.customerportal.organisationProfile(),
            label: loc.oneportal.profileMenu.links.organisation.get(),
            icon: <OrganisationIcon />,
          },
          {
            link: links.oneportal.logout(),
            label: loc.oneportal.profileMenu.links.logout.get(),
            icon: <LogoutIcon />,
          },
        ]}
      />
    </Root>
  )
}

const Root = styled.div`
  display: flex;
  z-index: ${theme.zIndex.low};
  width: 100%;
  height: 100%;
`

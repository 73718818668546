import { Chip, Tooltip } from "@material-ui/core"
import { Add } from "@material-ui/icons"
import { MouseEvent, ReactNode } from "react"
import { useHistory } from "react-router"
import styled from "styled-components"
import { introductionsElementIds } from "../../../customerportal/components/Introduction"
import { theme } from "../../../theme"
import { useLocale } from "../../hooks/useLocale"
import { useIntegrationFeatureFlag } from "../../hooks/useProductionFeatureFlag"
import { ButtonArea } from "../buttons/ButtonArea"
import { ButtonLinkArea } from "../buttons/ButtonLinkArea"
import { TextBold } from "../text/TextBold"
import { TextSmall } from "../text/TextSmall"

type SidebarItem = {
  text: string
  icon: ReactNode
  link: string
  addLink?: string
  addLinkTooltip?: string
  exact?: boolean
  show?: boolean
  new?: boolean
}

export type SidebarSectionProps = {
  title: string
  items?: (SidebarItem | false | null | undefined)[]
  myServices?: (SidebarItem | false | null | undefined)[]
  connectedServices?: (SidebarItem | false | null | undefined)[]
  myStraumannSettings?: (SidebarItem | false | null | undefined)[]
}

export const SidebarSection = (props: SidebarSectionProps) => {
  const { title } = props
  const loc = useLocale()
  const integrationFeatureFlag = useIntegrationFeatureFlag()
  const history = useHistory()

  const isSidebarItem = (item: SidebarItem | false | null | undefined): item is SidebarItem =>
    !!item && item.show !== false

  const items = props.items?.filter(isSidebarItem) ?? []
  const myServices = props.myServices?.filter(isSidebarItem) ?? []
  const connectedServices = props.connectedServices?.filter(isSidebarItem) ?? []
  const myStraumannSettings = props.myStraumannSettings?.filter(isSidebarItem) ?? []

  const handleClickAddLink = (e: MouseEvent<HTMLButtonElement>, item: SidebarItem) => {
    e.preventDefault()
    history.push(item.addLink ?? "/")
  }

  const renderItem = (item: SidebarItem, key: number) => (
    <ListItem key={key}>
      {item?.link && (
        <ListItemLink to={item.link} exact={item?.exact}>
          {item?.icon}
          <span>{item.text}</span>
          {item.new && <NewFlag>{loc.customerportal.dashboard.labels.new.get()}</NewFlag>}
          {item.addLink && (
            <Tooltip title={item.addLinkTooltip ?? ""}>
              <AddButtonContainer>
                <AddButton onClick={(e) => handleClickAddLink(e, item)} value={item.addLink}>
                  <Add color="primary" />
                </AddButton>
              </AddButtonContainer>
            </Tooltip>
          )}
        </ListItemLink>
      )}
    </ListItem>
  )

  return (
    <Root>
      <TitleWrapper>
        <Title>{title}</Title>
        <Chip label="Beta" size="small" />
      </TitleWrapper>
      <List>
        {items.map((item, index) => renderItem(item, index))}
        {!!myServices?.length && (
          <MyServices id={introductionsElementIds.dashboard.myServicesList}>
            <CustomTextSmall>{loc.customerportal.oneportalSidebar.labels.myServices.get()}</CustomTextSmall>
            <List>{myServices.map((myService, index) => renderItem(myService, index))}</List>
          </MyServices>
        )}
        {integrationFeatureFlag && !!connectedServices?.length && (
          <ConnectedServices>
            <CustomTextSmall>{loc.customerportal.oneportalSidebar.labels.connectedServices.get()}</CustomTextSmall>
            <List>{connectedServices.map((connectedService, index) => renderItem(connectedService, index))}</List>
          </ConnectedServices>
        )}
        {!!myStraumannSettings?.length && (
          <MyStraumannSettings>
            <CustomTextSmall>{loc.customerportal.myProfile.labels.myStraumannSettings.get()}</CustomTextSmall>
            <List>{myStraumannSettings.map((myStraumannSetting, index) => renderItem(myStraumannSetting, index))}</List>
          </MyStraumannSettings>
        )}
      </List>
    </Root>
  )
}

const Root = styled.div``

const MyServices = styled.div``

const ConnectedServices = styled.div``

const MyStraumannSettings = styled.div``

const CustomTextSmall = styled(TextSmall)`
  display: flex;
  height: 48px;
  opacity: 1;
  overflow: hidden;
  padding: 24px 22px ${theme.spacing.m};
  transition: opacity 0.2s linear, height 0.2s linear, padding 0.2s linear;
  border-bottom: 1px solid ${theme.color.white1};

  .collapsed & {
    opacity: 0;
    height: 0px;
    padding: 0px 0px 0px 22px;
    transition: opacity 0.2s linear, height 0.2s linear, padding 0.2s linear;
    border-bottom: none;
  }
`

const Title = styled(TextBold)`
  display: flex;
  align-items: center;
  padding: 0px ${theme.spacing.s} 0px ${theme.spacing.xl};
  text-transform: uppercase;
  overflow: hidden;
  transition: opacity 0.2s linear, height 0.2s linear;
  white-space: nowrap;

  .collapsed & {
    opacity: 0;
    height: 0px;
    width: 0px;
    padding: 0px 6px;
    transition: opacity 0.2s linear, height 0.2s linear;
  }
`

const List = styled.ul`
  list-style-type: none;
  padding: 0px;
  margin: 0px;
`

const ListItem = styled.li`
  white-space: nowrap;

  svg {
    margin-right: ${theme.spacing.l};
    width: 25px;
    flex-shrink: 0;
  }
`

const ListItemLink = styled(ButtonLinkArea)`
  display: flex;
  align-items: center;
  color: inherit;
  border-bottom: 1px solid ${theme.color.white1};
  height: 49px;
  font-size: ${theme.fontSize.m};
  flex-grow: 1;
  padding-left: ${parseInt(theme.spacing.l) + parseInt(theme.spacing.m)}px;
  transition: border-left 0.2s linear, padding-left 0.2s linear;

  &:hover {
    background: ${theme.color.blue9};
  }

  &.active {
    background: ${theme.color.blue9};
    border-left: ${theme.spacing.m} solid ${theme.color.primary};
    padding-left: ${theme.spacing.l};
  }

  .collapsed & {
    border-left-width: 0px;
    padding-left: 24px;
    transition: border-left-width 0.2s linear, padding-left 0.2s linear;

    span {
      opacity: 0;
      transition: opacity 0.2s linear;
    }
  }
`

const TitleWrapper = styled.div`
  display: flex;
  align-items: center;
  border-bottom: 1px solid ${theme.color.white1};
  height: 49px;
`

const ListItemRightContent = styled.span`
  margin-left: auto;

  svg {
    margin-right: 0;
  }
`

const NewFlag = styled(ListItemRightContent)`
  margin-right: ${theme.spacing.l};
  text-transform: uppercase;
  font-size: ${theme.fontSize.s};
  font-family: ${theme.font.bold};
  color: ${theme.color.blue2};
`

const AddButtonContainer = styled(ListItemRightContent)`
  margin-right: ${theme.spacing.s};
`

const AddButton = styled(ButtonArea)`
  border-radius: 3px;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;

  &:hover {
    background-color: ${theme.color.primary100};
  }
`

import { AddPendingProductsRequest } from "./types/AddPendingProductsRequest"
import { AnesthesiaType } from "./types/AnesthesiaType"
import { ApiClient } from "../../../oneportal/services/api/ApiClient"
import { PendingProduct } from "./types/PendingProduct"
import { PendingProductStatus } from "./types/PendingProductStatus"
import { BoneAugmentationType } from "./types/BoneAugmentationType"
import { BoneType } from "./types/BoneType"
import { ChunkedRequest } from "../../../oneportal/services/api/types/ChunkedRequest"
import { ChunkedResponse } from "../../../oneportal/services/api/types/ChunkedResponse"
import { Complication } from "./types/Complication"
import { ComplicationEvent } from "./types/ComplicationEvent"
import { ComplicationId } from "./types/ComplicationId"
import { createChunkedRequest } from "../../../oneportal/services/api/helpers/createChunkedRequest"
import { createChunkedResponse } from "../../../oneportal/services/api/helpers/createChunkedResponse"
import { CreateExtractTeethFormType } from "../../components/DentalChart/createExtractTeethForm"
import { CreateFollowUpFormType } from "../../components/PatientDetails/FollowUp/createFollowUpForm"
import { Device } from "./types/Device"
import { DeviceId } from "../../../oneportal/services/api/types/DeviceId"
import { DeviceWithQuantity } from "./types/DeviceWithQuantity"
import { DisplayImport } from "./types/DisplayImport"
import { Encounter } from "./types/Encounter"
import { EncounterClass } from "./types/EncounterClass"
import { EncounterDetails } from "./types/EncounterDetails"
import { EncounterId } from "./types/EncounterId"
import { EncounterSearchRequest } from "./types/EncounterSearchRequest"
import { EncounterStatus } from "./types/EncounterStatus"
import { EncounterType } from "./types/EncounterType"
import { ExtractionType } from "./types/ExtractionType"
import { FollowUp } from "./types/FollowUp"
import { FollowUpId } from "./types/FollowUpId"
import { GeneralStats } from "./types/GeneralStats"
import { HealingTime } from "./types/HealingTime"
import { Import } from "./types/Import"
import { ImportId } from "./types/ImportId"
import { InsertionTorque } from "./types/InsertionTorque"
import { ISODate } from "../../../oneportal/helpers/types/ISODate"
import { MaterialNumber } from "../../../oneportal/services/api/types/MaterialNumber"
import { OsseointegrationVerificationMethod } from "./types/OsseointegrationVerificationMethod"
import { Patient } from "../../../oneportal/services/api/types/Patient"
import { PatientId } from "../../../oneportal/services/api/types/PatientId"
import { PatientOralHygiene } from "./types/PatientOralHygiene"
import { PatientSmokingStatus } from "./types/PatientSmokingStatus"
import { Practitioner } from "./types/Practitioner"
import { PractitionerId } from "./types/PractitionerId"
import { Procedure } from "./types/Procedure"
import { ProcedureAbutmentPlacement } from "./types/ProcedureAbutmentPlacement"
import { ProcedureAnesthesia } from "./types/ProcedureAnesthesia"
import { ProcedureBoneAugmentation } from "./types/ProcedureBoneAugmentation"
import { ProcedureExtraction } from "./types/ProcedureExtraction"
import { ProcedureHealingAbutment } from "./types/ProcedureHealingAbutment"
import { ProcedureId } from "../../../oneportal/services/api/types/ProcedureId"
import { ProcedureImplantPlacement } from "./types/ProcedureImplantPlacement"
import { ProcedureOsseointegrationVerification } from "./types/ProcedureOsseointegrationVerification"
import { ProcedureRestorativeMaterial } from "./types/ProcedureRestorativeMaterial"
import { ProcedureWithDevices } from "./types/ProcedureWithDevices"
import { Product } from "./types/Product"
import { ProductAddRequest } from "./types/ProductAddRequest"
import { ProductAddType } from "./types/ProductAddType"
import { ProductClass } from "./types/ProductClass"
import { UpdateDeviceRequest } from "./types/UpdateDeviceRequest"
import { Retention } from "./types/Retention"
import { SapOrganizationId } from "../../../customerportal/services/api/types/SapOrganizationId"
import { SortedRequest } from "../../../oneportal/services/api/types/SortedRequest"
import { Tooth } from "./types/Tooth"
import { ToothPositionFHIR, toothPositionsFHIR } from "../../components/DentalChart/types/ToothPositionFHIR"
import { ToothPositionFormat } from "../../components/DentalChart/types/ToothPositionFormat"
import { ToothPositionISO } from "../../components/DentalChart/types/ToothPositionISO"
import { uniqBy } from "lodash-es"
import { UserId } from "../../../customerportal/services/api/types/UserId"
import { EncounterWithProcedures } from "./types/EncounterWithProcedures"
import { PendingProductId } from "./types/PendingProductId"
import { MaterialNumberType } from "./types/MaterialNumberType"
import { PendingProductContext } from "./types/PendingProductContext"
import { CustomPatientId } from "../../../oneportal/services/api/types/CustomPatientId"
import { LabOrderRestoration } from "./types/LabOrderRestoration"
import { BillingType } from "./types/BillingType"
import { RestorativeMaterialType } from "./types/RestorativeMaterialType"
import { RemoteError } from "../../../oneportal/services/api/types/RemoteError"
import { ApiError } from "../../../oneportal/services/api/types/ApiError"
import { ProductSource } from "./types/ProductSource"
import { ProductType } from "./types/ProductType"
import { AnalyticsPatient } from "./types/AnalyticsPatient"
import { AnalyticsIndications } from "./types/AnalyticsIndications"
import { AnalyticsDatasetsCompletedness } from "./types/AnalyticsDatasetsCompletedness"
import { AnalyticsAdverseEvents } from "./types/AnalyticsAdverseEvents"
import { AnalyticsAdverseEventsRanking } from "./types/AnalyticsAdverseEventsRanking"
import { HealingSubmersionType } from "./types/HealingSubmersionType"
import { AnalyticsExportInformation } from "./types/AnalyticsExportInformation"
import { ProductCode } from "../../../oneportal/services/api/types/ProductCode"
import { DeviceClass } from "./types/DeviceClass"

export const createRegistryApiClient = (api: ApiClient) => {
  return {
    createPatient: createPatient(api),
    getGeneralStats: getGeneralStats(api),
    getPatients: getPatients(api),
    getPatient: getPatient(api),
    initDevice: initDevice(api),
    deleteDevice: deleteDevice(api),
    updateDevice: updateDevice(api),
    getTeeth: getTeeth(api),
    getTooth: getTooth(api),
    initializeAnesthesia: initializeAnesthesia(api),
    updateAnesthesia: updateAnesthesia(api),
    deleteAnesthesia: deleteAnesthesia(api),
    initializeAbutmentPlacement: initializeAbutmentPlacement(api),
    updateAbutmentPlacement: updateAbutmentPlacement(api),
    deleteAbutmentPlacement: deleteAbutmentPlacement(api),
    initializeBoneAugmentation: initializeBoneAugmentation(api),
    updateBoneAugmentation: updateBoneAugmentation(api),
    deleteBoneAugmentation: deleteBoneAugmentation(api),
    initializeExtraction: initializeExtraction(api),
    deleteExtraction: deleteExtraction(api),
    initializeHealingAbutment: initializeHealingAbutment(api),
    updateHealingAbutment: updateHealingAbutment(api),
    deleteHealingAbutment: deleteHealingAbutment(api),
    initializeImplantPlacement: initializeImplantPlacement(api),
    updateImplantPlacementProcedure: updateImplantPlacementProcedure(api),
    deleteImplantPlacement: deleteImplantPlacement(api),
    initializeOsseointegrationVerification: initializeOsseointegrationVerification(api),
    updateOsseointegrationVerification: updateOsseointegrationVerification(api),
    deleteOsseointegrationVerification: deleteOsseointegrationVerification(api),
    initializeRestorativeMaterial: initializeRestorativeMaterial(api),
    updateRestorativeMaterial: updateRestorativeMaterial(api),
    deleteRestorativeMaterial: deleteRestorativeMaterial(api),
    getQRCode: getQRCode(api),
    getDevices: getDevices(api),
    getAssignedDevices: getAssignedDevices(api),
    getAssignedDevicesExportFile: getAssignedDevicesExportFile(api),
    getPendingProducts: getPendingProducts(api),
    assignPendingProduct: assignPendingProduct(api),
    getProducts: getProducts(api),
    addProduct: addProduct(api),
    addPendingProducts: addPendingProducts(api),
    updatePendingProduct: updatePendingProduct(api),
    deletePendingProduct: deletePendingProduct(api),
    getProcedureById: getProcedureById(api),
    getProcedureByIdWithDevices: getProcedureByIdWithDevices(api),
    cancelTreatment: cancelTreatment(api),
    createComplication: createComplication(api),
    getComplication: getComplication(api),
    getComplications: getComplications(api),
    createProcedure: createProcedure(api),
    updateComplication: updateComplication(api),
    resolveComplication: resolveComplication(api),
    cancelComplication: cancelComplication(api),
    deleteComplicationDevice: deleteComplicationDevice(api),
    extractTeeth: extractTeeth(api),
    getSurgeryReportFile: getSurgeryReportFile(api),
    getEncounterDetails: getEncounterDetails(api),
    createEncounter: createEncounter(api),
    updateEncounter: updateEncounter(api),
    deleteEncounter: deleteEncounter(api),
    getFollowUp: getFollowUp(api),
    createFollowUp: createFollowUp(api),
    updateFollowUp: updateFollowUp(api),
    getPatientEncounters: getPatientEncounters(api),
    getPatientEncounterDetails: getPatientEncounterDetails(api),
    updatePreferences: updatePreferences(api),
    getPractitioners: getPractitioners(api),
    createPractitioner: createPractitioner(api),
    importPatients: importPatients(api),
    exportPatients: exportPatients(api),
    getMyImports: getMyImports(api),
    getImportResult: getImportResult(api),
    getImportErrorFile: getImportErrorFile(api),
    unassignProduct: unassignProduct(api),
    createLabOrder: createLabOrder(api),
    getExportInformationAnalytics: getExportInformationAnalytics(api),
    getPatientAnalytics: getPatientAnalytics(api),
    getIndicationAnalytics: getIndicationAnalytics(api),
    getDatasetsCompletednessAnalytics: getDatasetsCompletednessAnalytics(api),
    getAdverseEventsAnalytics: getAdverseEventsAnalytics(api),
    getAdverseEventsRankingAnalytics: getAdverseEventsRankingAnalytics(api),
  }
}

const addProduct = (api: ApiClient) => async (
  patientId: PatientId,
  productAddRequest: ProductAddRequest,
  onSuccess?: () => void,
  onError?: () => void
): Promise<Device | undefined> => {
  try {
    const response = await api.put<Device>(`/products/add/${patientId}`, productAddRequest)

    onSuccess && onSuccess()
    return response.data
  } catch (err) {
    onError && onError()
  }
}

const addPendingProducts = (api: ApiClient) => async (
  req: AddPendingProductsRequest
): Promise<PendingProduct[] | undefined> => {
  try {
    const response = await api.post<PendingProduct[]>("/pending-products", req)

    return response.data
  } catch (err) {}
}

export type UpdatePendingProductRequest = {
  pendingProductId: PendingProductId
  lotNumber: string
  expirationDate: ISODate
}

const updatePendingProduct = (api: ApiClient) => async (
  req: UpdatePendingProductRequest
): Promise<PendingProduct | undefined> => {
  try {
    const response = await api.patch<PendingProduct>(`/pending-products/${req.pendingProductId}`, req)

    return response.data
  } catch (err) {}
}

const deletePendingProduct = (api: ApiClient) => async (pendingProductId: PendingProductId): Promise<boolean> => {
  try {
    await api.delete(`/pending-products/${pendingProductId}`)

    return true
  } catch (err) {
    return false
  }
}

export type PatientCreateRequest = {
  firstName: string
  middleName?: string
  lastName: string
  gender: string
  birthDate: ISODate
  referringDentistId?: PractitionerId
  riskFactors?: string[]
  customRisks?: string[]
  oralHygiene?: PatientOralHygiene
  smokingStatus?: PatientSmokingStatus
}

const createPatient = (api: ApiClient) => async (patient: PatientCreateRequest): Promise<Patient | undefined> => {
  try {
    const response = await api.post<Patient>("patients", patient)
    return response.data
  } catch (err) {}
}

const createProcedure = (api: ApiClient) => async (): Promise<Procedure | undefined> => {
  try {
    const response = await api.post<Procedure>("procedure")
    return response.data
  } catch (err) {}
}

const getPatient = (api: ApiClient) => async (id: PatientId): Promise<Patient | undefined> => {
  try {
    const res = await api.get<Patient>(`/patients/${id}`)
    return res.data
  } catch (err) {}
}

export type GetPatientsRequest = ChunkedRequest &
  SortedRequest & {
    name?: string
    keyword?: string
    firstName?: string
    lastName?: string
    birthDate?: ISODate
    filter?: string
    includeLastActivity?: boolean
    customPatientId?: string
    includeAdverseEvents?: boolean
  }

const getPatients = (api: ApiClient) => async (
  args?: GetPatientsRequest
): Promise<ChunkedResponse<Patient> | undefined> => {
  try {
    const req = createChunkedRequest(args)
    const res = await api.post<Patient[]>(`/patients/search`, req)

    return createChunkedResponse(req, res.data)
  } catch (err) {}
}

const getGeneralStats = (api: ApiClient) => async (): Promise<GeneralStats | undefined> => {
  try {
    const statsResponse = await api.get<GeneralStats>(`/statistic/general`)

    return statsResponse.data
  } catch (err) {}
}

export type InitDeviceRequest = {
  patientId: PatientId
  procedureId: ProcedureId
  deviceClass: DeviceClass
}

const initDevice = (api: ApiClient) => async (req: InitDeviceRequest): Promise<Device | undefined> => {
  try {
    const response = await api.post<Device>("/devices/init", req)

    return response.data
  } catch (err) {}
}

const deleteDevice = (api: ApiClient) => async (
  deviceId?: DeviceId,
  procedureId?: ProcedureId,
  onSuccess?: () => void,
  onError?: () => void
): Promise<boolean> => {
  try {
    await api.delete(`/devices/${deviceId}/${procedureId}`)

    onSuccess && onSuccess()
    return true
  } catch (err) {
    onError && onError()
    return false
  }
}

const updateDevice = (api: ApiClient) => async (req: UpdateDeviceRequest): Promise<boolean> => {
  try {
    await api.patch(`/devices/${req.deviceId}`, req)

    return true
  } catch (err) {
    return false
  }
}

const getTeeth = (api: ApiClient) => async (patientId: PatientId): Promise<Tooth[] | undefined> => {
  try {
    const response = await api.post<Tooth[]>("/tooth/search", { patientId: patientId, limit: 100 })
    const mockedTeeth: Tooth[] = toothPositionsFHIR.map((position) => {
      return {
        patientId: patientId,
        positionISO: position,
        status: null,
        adverseEvents: undefined,
        devices: undefined,
        restorativeMaterials: undefined,
      }
    })

    return uniqBy([...response.data, ...mockedTeeth], (tooth) => tooth.positionISO)
  } catch (err) {}
}

const getTooth = (api: ApiClient) => async (tooth: Tooth): Promise<Tooth | undefined> => {
  try {
    const response = await api.post("/tooth/search", {
      patientId: tooth.patientId,
      toothPosition: tooth.positionISO,
      limit: 100,
    })

    return response.data.find((newTooth) => newTooth.positionISO === tooth.positionISO)
  } catch (err) {}
}

const extractTeeth = (api: ApiClient) => async (
  patientId: PatientId,
  toothPositions: ToothPositionFHIR[],
  extractionOptions: CreateExtractTeethFormType
): Promise<Procedure[] | undefined> => {
  try {
    return await Promise.all(
      toothPositions.map(async (toothPosition) => {
        const response = await api.post(`/tooth/extraction`, { ...extractionOptions, patientId, toothPosition })
        return response.data
      })
    )
  } catch (err) {}
}

export type GetQRCodeRequest = {
  productAddType: ProductAddType
  elementIdentifier: string
  callbackUrl: string
}

const getQRCode = (api: ApiClient) => async (req: GetQRCodeRequest): Promise<string | undefined> => {
  try {
    const response = await api.post<string>(`/qr/product`, req)

    return response.data
  } catch (err) {}
}

const getProcedureById = (api: ApiClient) => async (procedureId: ProcedureId): Promise<Procedure | undefined> => {
  try {
    const response = await api.get<Procedure>(`/procedure/${procedureId}`)
    return response.data
  } catch (err) {}
}

const getProcedureByIdWithDevices = (api: ApiClient) => async (
  procedureId: ProcedureId
): Promise<ProcedureWithDevices | undefined> => {
  try {
    const response = await api.get<ProcedureWithDevices>(`/procedure/details/${procedureId}`)
    return response.data
  } catch (err) {}
}

const cancelTreatment = (api: ApiClient) => async (
  tooth: Tooth,
  onSuccess: () => void,
  onError: () => void
): Promise<Tooth | undefined> => {
  try {
    await api.post(`/tooth/cancel`, {
      patientId: tooth.patientId,
      toothPosition: tooth.positionISO,
    })

    onSuccess()

    return tooth
  } catch (err) {
    onError()
  }
}

const getComplication = (api: ApiClient) => async (
  adverseEventId: ComplicationId
): Promise<Complication | undefined> => {
  try {
    const response = await api.get<Complication>(`/adverse-events/${adverseEventId}`)

    return response.data
  } catch (err) {}
}

export type GetComplicationsRequest = {
  patientId?: PatientId
  adverseEventId?: ComplicationId
}

const getComplications = (api: ApiClient) => async (
  req: GetComplicationsRequest
): Promise<Complication[] | undefined> => {
  try {
    const response = await api.post<Complication[]>(`/adverse-events/search`, req)

    return response.data
  } catch (err) {}
}

export type CreateComplicationRequest = {
  patientId: PatientId
  encounterId: EncounterId
  occurrenceDate: ISODate
  event: ComplicationEvent
  toothPositions?: ToothPositionFHIR[]
  comment?: string
  pendingProductIds: PendingProductId[]
}

const createComplication = (api: ApiClient) => async (
  req: CreateComplicationRequest
): Promise<Complication | undefined> => {
  try {
    const response = await api.post<Complication>("/adverse-events", req)

    return response.data
  } catch (err) {}
}

export type UpdateComplicationRequest = {
  patientId: PatientId
  occurrenceDate: ISODate
  event: ComplicationEvent
  toothPositions?: ToothPositionFHIR[]
  comment?: string
}

const updateComplication = (api: ApiClient) => async (
  complicationId: ComplicationId,
  req: UpdateComplicationRequest
): Promise<Complication | undefined> => {
  try {
    const response = await api.patch<Complication>(`/adverse-events/${complicationId}`, req)

    return response.data
  } catch (err) {}
}

const resolveComplication = (api: ApiClient) => async (
  complicationId: ComplicationId
): Promise<Complication | undefined> => {
  try {
    const response = await api.put<Complication>(`/adverse-events/${complicationId}/archive`)

    return response.data
  } catch (err) {}
}

const cancelComplication = (api: ApiClient) => async (complicationId: ComplicationId): Promise<boolean | undefined> => {
  try {
    await api.delete(`/adverse-events/${complicationId}`)

    return true
  } catch (err) {
    return false
  }
}

export type DeleteComplicationDeviceRequest = {
  complicationId: ComplicationId
  deviceId: DeviceId
}

const deleteComplicationDevice = (api: ApiClient) => async (req: DeleteComplicationDeviceRequest): Promise<boolean> => {
  try {
    const { complicationId, deviceId } = req
    await api.delete(`/adverse-events/${complicationId}/device/${deviceId}`)
    return true
  } catch (err) {
    return false
  }
}

export type GetSurgeryReportFileRequest = {
  toothPositions: ToothPositionFHIR[]
  toothPositionFormat: ToothPositionFormat
  locale: string
  timezone: string
  logo?: string
}

const getSurgeryReportFile = (api: ApiClient) => async (
  patientId: PatientId,
  req: GetSurgeryReportFileRequest
): Promise<ArrayBuffer | undefined> => {
  try {
    const response = await api.post<ArrayBuffer>(`/encounters/patient/${patientId}/export`, req, {
      responseType: "arraybuffer",
    })

    return response.data
  } catch (err) {}
}

const getEncounterDetails = (api: ApiClient) => async (
  encounterId: EncounterId
): Promise<EncounterDetails | undefined> => {
  try {
    const response = await api.get<EncounterDetails>(`/encounters/${encounterId}`)

    return response.data
  } catch (err) {}
}

export type CreateEncounterRequest = {
  status: EncounterStatus
  encounterClass: EncounterClass
  types: EncounterType[]
  subject: PatientId
  ownerId: PractitionerId
  participants: PractitionerId[]
  periodStart: ISODate
}

const createEncounter = (api: ApiClient) => async (req: CreateEncounterRequest): Promise<Encounter | undefined> => {
  try {
    const response = await api.post<Encounter>("/encounters", req)

    return response.data
  } catch (err) {}
}

export type UpdateEncounterRequest = {
  encounterId: EncounterId
  periodStart: ISODate
  participants: PractitionerId[]
}

const updateEncounter = (api: ApiClient) => async (req: UpdateEncounterRequest): Promise<Encounter | undefined> => {
  try {
    const response = await api.patch<Encounter>(`/encounters/${req.encounterId}`, req)

    return response.data
  } catch (err) {}
}

const deleteEncounter = (api: ApiClient) => async (encounterId: EncounterId): Promise<boolean> => {
  try {
    await api.delete(`/encounters/${encounterId}`)

    return true
  } catch (err) {
    return false
  }
}

export type FollowUpRequest = CreateFollowUpFormType & {
  patientId: PatientId
  encounterId: EncounterId
}

const createFollowUp = (api: ApiClient) => async (request: FollowUpRequest): Promise<FollowUp | undefined> => {
  try {
    const response = await api.post<FollowUp>("/follow-ups", request)

    return response.data
  } catch (err) {}
}

const getFollowUp = (api: ApiClient) => async (followUpId: FollowUpId): Promise<FollowUp | undefined> => {
  try {
    const response = await api.get<FollowUp>(`/follow-ups/${followUpId}`)

    return response.data
  } catch (err) {}
}

const updateFollowUp = (api: ApiClient) => async (
  followUpId: FollowUpId,
  request: FollowUpRequest
): Promise<FollowUp | undefined> => {
  try {
    const response = await api.patch<FollowUp>(`/follow-ups/${followUpId}`, request)

    return response.data
  } catch (err) {}
}

const getPatientEncounters = (api: ApiClient) => async (patientId: PatientId): Promise<Encounter[] | undefined> => {
  try {
    const response = await api.get<Encounter[]>(`/encounters/patient/${patientId}`)

    return response.data
  } catch (err) {}
}

const getPatientEncounterDetails = (api: ApiClient) => async (
  searchRequest: EncounterSearchRequest
): Promise<EncounterDetails[] | undefined> => {
  try {
    const response = await api.post(`/encounters/search`, searchRequest)

    return response.data
  } catch (err) {}
}

export type UpdatePreferencesRequest = {
  sms?: boolean
  email?: boolean
  push?: boolean
  lang?: string
}

const updatePreferences = (api: ApiClient) => async (req: UpdatePreferencesRequest): Promise<boolean> => {
  try {
    await api.post<Complication>("/preferences", req)
    return true
  } catch (err) {}
  return false
}

export type GetDevicesRequest = SortedRequest &
  ChunkedRequest & {
    patientId?: PatientId
    keyword?: string
    deviceClass?: ProductClass
    bodySites?: ToothPositionISO[]
  }

const getDevices = (api: ApiClient) => async (req: GetDevicesRequest): Promise<Device[] | undefined> => {
  try {
    const response = await api.post<Device[]>("/devices/search", req)

    return response.data
  } catch (err) {}
}

export type GetAssignedDevicesRequest = SortedRequest &
  ChunkedRequest & {
    materialNumberType?: MaterialNumberType
    keyword?: string
    fromDate?: ISODate
    toDate?: ISODate
  }

const getAssignedDevices = (api: ApiClient) => async (
  req: GetAssignedDevicesRequest
): Promise<ChunkedResponse<DeviceWithQuantity> | undefined> => {
  try {
    const request = createChunkedRequest(req)
    const response = await api.post<DeviceWithQuantity[]>("/devices/assigned/search", request)

    return createChunkedResponse(request, response.data)
  } catch (err) {}
}

export type GetAssignedDevicesExportFileRequest = {
  devices: {
    name: string
    materialNumber: MaterialNumber
    quantityUsed: number
    quantityToOrder: number
  }[]
  locale: string
}

const getAssignedDevicesExportFile = (api: ApiClient) => async (
  req: GetAssignedDevicesExportFileRequest
): Promise<ArrayBuffer | undefined> => {
  try {
    const response = await api.post<ArrayBuffer>("/devices/assigned/export", req, {
      responseType: "arraybuffer",
    })

    return response.data
  } catch (err) {}
}

export type GetProductsRequest = {
  searchText?: string
  productCodes?: ProductCode[]
  materialNumbers?: MaterialNumber[]
  type?: ProductType
  productClass?: ProductClass
}

const getProducts = (api: ApiClient) => async (req: GetProductsRequest): Promise<Product[] | undefined> => {
  try {
    const response = await api.post<Product[]>("/products/search", req)

    return response.data
  } catch (err) {}
}

export type GetPendingProductsRequest = SortedRequest &
  ChunkedRequest & {
    encounterId?: EncounterId
    keyword?: string
    status?: PendingProductStatus
    context?: PendingProductContext
  }

const getPendingProducts = (api: ApiClient) => async (
  args: GetPendingProductsRequest
): Promise<PendingProduct[] | undefined> => {
  try {
    const req = createChunkedRequest({ ...args, limit: 100 })
    const response = await api.post<PendingProduct[]>(`/pending-products/search`, req)

    return response.data
  } catch (err) {}
}

export type AssignPendingProductRequest = {
  encounterId: EncounterId
  patientId: PatientId
  performedDate: ISODate
  toothPositions: ToothPositionFHIR[]
  productClass: ProductClass
  pendingProductId: PendingProductId
  source: ProductSource
  expirationDate?: ISODate
  lotNumber?: string
  type?: BoneAugmentationType
  temporary?: boolean
}

export type UnAssignPendingProductRequest = {
  encounterId: EncounterId
  deviceId: DeviceId
}

const assignPendingProduct = (api: ApiClient) => async (
  req: AssignPendingProductRequest
): Promise<EncounterWithProcedures> => {
  try {
    const response = await api.post<EncounterWithProcedures>(`/pending-products/assign`, req)

    return response.data
  } catch (err) {
    const error = err as ApiError<RemoteError>
    throw Error(error.response?.data?.code)
  }
}

const unassignProduct = (api: ApiClient) => async (
  req: UnAssignPendingProductRequest
): Promise<PendingProduct | undefined> => {
  try {
    const response = await api.post<PendingProduct>(`/pending-products/unassign`, req)

    return response.data
  } catch (err) {}
}

export type InitialzeProcedureRequest = {
  patientId: PatientId
  encounterId: EncounterId
  performedDate?: ISODate
  toothPositions?: ToothPositionFHIR[]
}

export type DeleteProcedureRequest = {
  patientId: PatientId
  procedureId: ProcedureId
}

const initializeAnesthesia = (api: ApiClient) => async (
  req: InitialzeProcedureRequest
): Promise<ProcedureAnesthesia | undefined> => {
  try {
    const response = await api.post<ProcedureAnesthesia>("/anesthesias", req)

    return response.data
  } catch (err) {}
}

export type UpdateAnesthesiaRequest = InitialzeProcedureRequest & {
  type: AnesthesiaType
}

const updateAnesthesia = (api: ApiClient) => async (
  id: ProcedureId,
  req: UpdateAnesthesiaRequest
): Promise<ProcedureAnesthesia | undefined> => {
  try {
    const response = await api.patch<ProcedureAnesthesia>(`/anesthesias/${id}`, req)

    return response.data
  } catch (err) {}
}

const deleteAnesthesia = (api: ApiClient) => async (req: DeleteProcedureRequest): Promise<boolean> => {
  try {
    await api.delete(`/anesthesias/${req.patientId}/${req.procedureId}`)

    return true
  } catch (err) {
    return false
  }
}

export type InitialzeAbutmentPlacementRequest = InitialzeProcedureRequest & {
  temporary?: boolean
}

const initializeAbutmentPlacement = (api: ApiClient) => async (
  req: InitialzeAbutmentPlacementRequest
): Promise<ProcedureAbutmentPlacement[] | undefined> => {
  try {
    const response = await api.post<ProcedureAbutmentPlacement[]>("/abutment-placements", req)

    return response.data
  } catch (err) {}
}

export type UpdateAbutmentPlacementRequest = InitialzeAbutmentPlacementRequest & {
  retentionMethod: Retention
}

const updateAbutmentPlacement = (api: ApiClient) => async (
  id: ProcedureId,
  req: UpdateAbutmentPlacementRequest
): Promise<ProcedureAbutmentPlacement | undefined> => {
  try {
    const response = await api.patch<ProcedureAbutmentPlacement>(`/abutment-placements/${id}`, req)

    return response.data
  } catch (err) {}
}

const deleteAbutmentPlacement = (api: ApiClient) => async (req: DeleteProcedureRequest): Promise<boolean> => {
  try {
    await api.delete(`/abutment-placements/${req.patientId}/${req.procedureId}`)

    return true
  } catch (err) {
    return false
  }
}

const initializeBoneAugmentation = (api: ApiClient) => async (
  req: InitialzeProcedureRequest
): Promise<ProcedureBoneAugmentation | undefined> => {
  try {
    const response = await api.post<ProcedureBoneAugmentation>("/bone-augmentations", req)

    return response.data
  } catch (err) {}
}

export type UpdateBoneAugmentationRequest = InitialzeProcedureRequest & {
  type: BoneAugmentationType
}

const updateBoneAugmentation = (api: ApiClient) => async (
  id: ProcedureId,
  req: UpdateBoneAugmentationRequest
): Promise<ProcedureBoneAugmentation | undefined> => {
  try {
    const response = await api.patch<ProcedureBoneAugmentation>(`/bone-augmentations/${id}`, req)

    return response.data
  } catch (err) {}
}

const deleteBoneAugmentation = (api: ApiClient) => async (req: DeleteProcedureRequest): Promise<boolean> => {
  try {
    await api.delete(`/bone-augmentations/${req.patientId}/${req.procedureId}`)

    return true
  } catch (err) {
    return false
  }
}

export type InitialzeExtractRequest = InitialzeProcedureRequest & {
  type: ExtractionType
}

const initializeExtraction = (api: ApiClient) => async (
  req: InitialzeExtractRequest
): Promise<ProcedureExtraction[] | undefined> => {
  try {
    const response = await api.post<ProcedureExtraction[]>("/extractions", req)

    return response.data
  } catch (err) {}
}

const deleteExtraction = (api: ApiClient) => async (req: DeleteProcedureRequest): Promise<boolean> => {
  try {
    await api.delete(`/extractions/${req.patientId}/${req.procedureId}`)

    return true
  } catch (err) {
    return false
  }
}

const initializeHealingAbutment = (api: ApiClient) => async (
  req: InitialzeProcedureRequest
): Promise<ProcedureHealingAbutment[] | undefined> => {
  try {
    const response = await api.post<ProcedureHealingAbutment[]>("/healing-abutments", req)

    return response.data
  } catch (err) {}
}

export type UpdateHealingAbutmentRequest = InitialzeProcedureRequest & {
  healingSubmersion: HealingSubmersionType
}

const updateHealingAbutment = (api: ApiClient) => async (
  id: ProcedureId,
  req: UpdateHealingAbutmentRequest
): Promise<ProcedureHealingAbutment | undefined> => {
  try {
    const response = await api.patch<ProcedureHealingAbutment>(`/healing-abutments/${id}`, req)

    return response.data
  } catch (err) {}
}

const deleteHealingAbutment = (api: ApiClient) => async (req: DeleteProcedureRequest): Promise<boolean> => {
  try {
    await api.delete(`/healing-abutments/${req.patientId}/${req.procedureId}`)

    return true
  } catch (err) {
    return false
  }
}

const initializeImplantPlacement = (api: ApiClient) => async (
  req: InitialzeProcedureRequest
): Promise<ProcedureImplantPlacement[] | undefined> => {
  try {
    const response = await api.post<ProcedureImplantPlacement[]>("/implant-placements", req)

    return response.data
  } catch (err) {}
}

export type UpdateImplantPlacement = InitialzeProcedureRequest & {
  implantInsertionTorque: InsertionTorque
  primaryImplantStability: boolean
  osstellISQ: number
  digitalPlanningPerformed: boolean
  guidedDrilling: boolean
  guidedImplantInsertion: boolean
  flaplessSurgery: boolean
  proposedHealingTime: HealingTime
  boneType: BoneType
  lateralSinusAugmentation: boolean
  verticalSinusAugmentation: boolean
}

const updateImplantPlacementProcedure = (api: ApiClient) => async (
  id: ProcedureId,
  req: UpdateImplantPlacement
): Promise<ProcedureImplantPlacement | undefined> => {
  try {
    const response = await api.patch<ProcedureImplantPlacement>(`/implant-placements/${id}`, req)

    return response.data
  } catch (err) {}
}

const deleteImplantPlacement = (api: ApiClient) => async (req: DeleteProcedureRequest): Promise<boolean> => {
  try {
    await api.delete(`/implant-placements/${req.patientId}/${req.procedureId}`)

    return true
  } catch (err) {
    return false
  }
}

const initializeOsseointegrationVerification = (api: ApiClient) => async (
  req: InitialzeProcedureRequest
): Promise<ProcedureOsseointegrationVerification[] | undefined> => {
  try {
    const response = await api.post<ProcedureOsseointegrationVerification[]>("/osseointegration-verifications", req)

    return response.data
  } catch (err) {}
}

export type UpdateOsseointegrationVerificationRequest = InitialzeProcedureRequest & {
  method: OsseointegrationVerificationMethod
  result: boolean
}

const updateOsseointegrationVerification = (api: ApiClient) => async (
  id: ProcedureId,
  req: UpdateOsseointegrationVerificationRequest
): Promise<ProcedureOsseointegrationVerification | undefined> => {
  try {
    const response = await api.patch<ProcedureOsseointegrationVerification>(
      `/osseointegration-verifications/${id}`,
      req
    )

    return response.data
  } catch (err) {}
}

const deleteOsseointegrationVerification = (api: ApiClient) => async (
  req: DeleteProcedureRequest
): Promise<boolean> => {
  try {
    await api.delete(`/osseointegration-verifications/${req.patientId}/${req.procedureId}`)

    return true
  } catch (err) {
    return false
  }
}

export type InitialzeRestorativeMaterialRequest = InitialzeProcedureRequest & {
  temporary?: boolean
}

const initializeRestorativeMaterial = (api: ApiClient) => async (
  req: InitialzeRestorativeMaterialRequest
): Promise<ProcedureRestorativeMaterial[] | undefined> => {
  try {
    const response = await api.post<ProcedureRestorativeMaterial[]>("/restorative-materials", req)

    return response.data
  } catch (err) {}
}

export type UpdateRestorativeMaterial = InitialzeRestorativeMaterialRequest & {
  restorativeMaterial: RestorativeMaterialType
}

const updateRestorativeMaterial = (api: ApiClient) => async (
  id: ProcedureId,
  req: UpdateRestorativeMaterial
): Promise<ProcedureRestorativeMaterial | undefined> => {
  try {
    const response = await api.patch<ProcedureRestorativeMaterial>(`/restorative-materials/${id}`, req)

    return response.data
  } catch (err) {}
}

const deleteRestorativeMaterial = (api: ApiClient) => async (req: DeleteProcedureRequest): Promise<boolean> => {
  try {
    await api.delete(`/restorative-materials/${req.patientId}/${req.procedureId}`)

    return true
  } catch (err) {
    return false
  }
}

export type GetPractitionersRequest = ChunkedRequest &
  SortedRequest & {
    ids?: PractitionerId[]
    organizationIdentifier?: SapOrganizationId
    userId?: UserId
    firstName?: string
    lastName?: string
    getOrganization?: boolean
  }

const getPractitioners = (api: ApiClient) => async (
  args: GetPractitionersRequest
): Promise<Practitioner[] | undefined> => {
  try {
    const req = createChunkedRequest(args)
    const response = await api.post<Practitioner[]>("/practitioners/search", req)

    return response.data
  } catch (err) {}
}

export type CreatePractitionerRequest = {
  firstName: string
  lastName: string
}

const createPractitioner = (api: ApiClient) => async (
  req: CreatePractitionerRequest
): Promise<Practitioner | undefined> => {
  try {
    const response = await api.post<Practitioner>("/practitioners", req)

    return response.data
  } catch (err) {}
}

export type ExportPatientsRequest = {
  encounterFrom: ISODate | undefined
  encounterTo: ISODate | undefined
}

const exportPatients = (api: ApiClient) => async (exportPatientsRequest: ExportPatientsRequest) => {
  try {
    const encounterFrom: string = exportPatientsRequest.encounterFrom ? exportPatientsRequest.encounterFrom : ""
    const encounterTo: string = exportPatientsRequest.encounterTo ? exportPatientsRequest.encounterTo : ""
    const response = await api.get<ArrayBuffer | undefined>("/export", {
      responseType: "arraybuffer",
      params: {
        encounterFrom,
        encounterTo,
      },
    })

    return response.data
  } catch (err) {}
}

export type ImportPatientsRequest = {
  locale: string
  timezone: string
  file: File
  isValidation: boolean
}

const importPatients = (api: ApiClient) => async (args: ImportPatientsRequest): Promise<boolean> => {
  try {
    const formData = new FormData()

    formData.append("file", args.file, args.file.name)
    formData.append("isValidation", args.isValidation.toString())
    formData.append("locale", args.locale)
    formData.append("timezone", args.timezone)

    await api.post<DisplayImport[]>("/import", formData)

    return true
  } catch (err) {
    return false
  }
}

const getMyImports = (api: ApiClient) => async (): Promise<Import[] | undefined> => {
  try {
    const response = await api.get<Import[]>("/import/own")

    return response.data
  } catch (err) {}
}

const getImportResult = (api: ApiClient) => async (id: ImportId): Promise<Import | undefined> => {
  try {
    const response = await api.get<Import>(`/import/${id}`)

    return response.data
  } catch (err) {}
}

const getImportErrorFile = (api: ApiClient) => async (id: ImportId) => {
  try {
    const response = await api.get<ArrayBuffer | undefined>(`import/download/${id}`, {
      responseType: "arraybuffer",
    })

    return response.data
  } catch (err) {}
}

export type CreateLabOrderRequest = {
  patientId: PatientId
  restorations: LabOrderRestoration[]
  customerPatientId: CustomPatientId
  patientAge: number
  patientAllergies: string
  laboratoryEmail: string
  orderWishDate: ISODate
  orderBillingType: BillingType
  orderOtherBillingInfo: string
  patientBruxism: boolean
  patientKnownMetalAllergies: boolean
}

const createLabOrder = (api: ApiClient) => async (req: CreateLabOrderRequest): Promise<boolean> => {
  try {
    await api.post("/lab-orders", req)

    return true
  } catch (err) {
    return false
  }
}

const getPatientAnalytics = (api: ApiClient) => async (): Promise<AnalyticsPatient | undefined> => {
  try {
    const response = await api.get<AnalyticsPatient>("/customer-analytics/patients")

    return response.data
  } catch (err) {
    const error = err as ApiError<RemoteError>
    throw Error(error.response?.data?.code)
  }
}

const getExportInformationAnalytics = (api: ApiClient) => async (): Promise<AnalyticsExportInformation | undefined> => {
  try {
    const response = await api.get<AnalyticsExportInformation>("/customer-analytics/informations")

    return response.data
  } catch (err) {
    const error = err as ApiError<RemoteError>
    throw Error(error.response?.data?.code)
  }
}

const getIndicationAnalytics = (api: ApiClient) => async (): Promise<AnalyticsIndications | undefined> => {
  try {
    const response = await api.get<AnalyticsIndications>("/customer-analytics/indications")

    return response.data
  } catch (err) {
    const error = err as ApiError<RemoteError>
    throw Error(error.response?.data?.code)
  }
}

const getDatasetsCompletednessAnalytics = (api: ApiClient) => async (): Promise<
  AnalyticsDatasetsCompletedness | undefined
> => {
  try {
    const response = await api.get<AnalyticsDatasetsCompletedness>("/customer-analytics/datasets-completedness")

    return response.data
  } catch (err) {
    const error = err as ApiError<RemoteError>
    throw Error(error.response?.data?.code)
  }
}

const getAdverseEventsAnalytics = (api: ApiClient) => async (): Promise<AnalyticsAdverseEvents | undefined> => {
  try {
    const response = await api.get<AnalyticsAdverseEvents>("/customer-analytics/adverse-events")

    return response.data
  } catch (err) {
    const error = err as ApiError<RemoteError>
    throw Error(error.response?.data?.code)
  }
}

const getAdverseEventsRankingAnalytics = (api: ApiClient) => async (): Promise<
  AnalyticsAdverseEventsRanking[] | undefined
> => {
  try {
    const response = await api.get<AnalyticsAdverseEventsRanking[]>("/customer-analytics/adverse-events-ranking")

    return response.data
  } catch (err) {
    const error = err as ApiError<RemoteError>
    throw Error(error.response?.data?.code)
  }
}

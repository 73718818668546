import styled from "styled-components"
import { Button, ClickAwayListener } from "@material-ui/core"
import { IntroductionParams } from "../layouts/MainLayout"
import {
  InstructionStorageKeys,
  introductionsElementIds,
  setShowInstruction,
  showInstruction,
} from "../../customerportal/components/Introduction"
import { theme } from "../../theme"
import { useLocale } from "../hooks/useLocale"
import { useState } from "react"
import { ButtonArea } from "./buttons/ButtonArea"
import { motion } from "framer-motion"
import { useMsalProfile } from "../hooks/useMsalProfile"
import { GTMEvent, pushToDataLayer } from "../helpers/pushToDataLayer"

export type GuideButtonProps = {
  introductionParams?: IntroductionParams
  onResetIntroduction: () => void
}

export const GuideButton = (props: GuideButtonProps) => {
  const { introductionParams, onResetIntroduction } = props
  const loc = useLocale()
  const profile = useMsalProfile()
  const myLoc = loc.oneportal.guideMenu
  const [showMenu, setShowMenu] = useState(false)

  const resetAllOnBoardingInstructions = () => {
    const onBoardingStorageKeys = [
      InstructionStorageKeys.Dashboard,
      InstructionStorageKeys.Encounters,
      InstructionStorageKeys.EncounterDetails,
      InstructionStorageKeys.AddProductCard,
      InstructionStorageKeys.CreateCaseGuide,
    ]

    onBoardingStorageKeys.forEach((key) => {
      if (!showInstruction(key)) {
        setShowInstruction(key, true)
      }
    })
  }

  const handleIntroductionReset = () => {
    if (introductionParams?.onStart) {
      introductionParams.onStart()
    }

    resetAllOnBoardingInstructions()
    setShowMenu(false)
    onResetIntroduction()
  }

  const handleToggleMenu = () => {
    if (!showMenu) {
      pushToDataLayer(GTMEvent.showOnboardingMenu, profile!)
    }
    setShowMenu((prevState) => !prevState)
  }

  return (
    <Root>
      <GuideIntroduction id={introductionsElementIds.encounterDetails.guideButton} onClick={handleToggleMenu}>
        ?
      </GuideIntroduction>
      {showMenu && (
        <ClickAwayListener onClickAway={() => setShowMenu(false)}>
          <MenuContainer
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            transition={{ duration: 0.3 }}
          >
            <MenuItem onClick={handleIntroductionReset}>{myLoc.actions.resetOnboarding.get()}</MenuItem>
          </MenuContainer>
        </ClickAwayListener>
      )}
    </Root>
  )
}

const buttonWidth = `40px`

const Root = styled.div`
  position: fixed;
  bottom: 0;
  right: 0;
  z-index: ${theme.zIndex.superHigh};
  display: flex;
  flex-direction: column-reverse;
`

const MenuContainer = styled(motion.div)`
  display: flex;
  flex-direction: column;
  color: ${theme.color.white};
  border-radius: 3px;
  padding: ${theme.spacing.l};
  background-color: ${theme.color.black3};
`

const MenuItem = styled(ButtonArea)``

const GuideIntroduction = styled(Button)`
  background-color: ${theme.color.black3};
  color: ${theme.color.white};
  height: ${buttonWidth};
  width: ${buttonWidth};
  border: none;
  border-radius: 100%;
  min-width: auto;
  font-size: ${theme.fontSize.m};
  font-family: ${theme.font.bold};
  padding: ${theme.spacing.l};
  align-self: flex-end;
  margin-right: ${theme.spacing.l};
  margin-bottom: ${theme.spacing.l};

  &:hover {
    background-color: ${theme.color.black5};
  }

  .MuiButton-text {
    padding: 0;
  }

  .MuiButton-label {
    height: ${buttonWidth};
  }
`

import { AppConfig } from "../config"
import { MsalProfilePermissions } from "../oneportal/hooks/useMsalProfilePermissions"
import { RouteDefinition } from "../oneportal/libraries/router/types/RouteDefinition"
import { RoutePolicy } from "../oneportal/libraries/router/types/RoutePolicy"
import { PatientsLinks } from "./links"

const privateRoutes = <TContext>(routes: RouteDefinition<TContext>[]): RouteDefinition<TContext>[] =>
  routes.map((route) => ({ ...route, policy: RoutePolicy.ForAuthenticated }))

// hide routes inside a factory to prevent global imports
export const getPatientsRoutes = (
  config: AppConfig,
  links: PatientsLinks
): RouteDefinition<MsalProfilePermissions>[] => {
  return [
    ...privateRoutes<MsalProfilePermissions>([
      {
        exact: true,
        path: links.patients(),
        load: () => import("./pages/Patients"),
      },
      {
        path: links.patientCreate(),
        load: () => import("./pages/Patients"),
        check: (ctx) => ctx.showRegistry,
      },
    ]),
  ]
}

import { ISODate } from "../../../oneportal/helpers/types/ISODate"
import { ApiClient } from "../../../oneportal/services/api/ApiClient"
import { createChunkedRequest } from "../../../oneportal/services/api/helpers/createChunkedRequest"
import { createChunkedResponse } from "../../../oneportal/services/api/helpers/createChunkedResponse"
import { ChunkedRequest } from "../../../oneportal/services/api/types/ChunkedRequest"
import { PatientId } from "../../../oneportal/services/api/types/PatientId"
import { SortedRequest } from "../../../oneportal/services/api/types/SortedRequest"
import { PatientOralHygiene } from "../../../registry/services/api/types/PatientOralHygiene"
import { PatientSmokingStatus } from "../../../registry/services/api/types/PatientSmokingStatus"
import { PractitionerId } from "../../../registry/services/api/types/PractitionerId"
import { Patient as PatientFull } from "./../../../oneportal/services/api/types/Patient"
import { ActivitySource } from "./types/ActivitySource"
import { ActivityType } from "./types/ActivityType"
import { Patient } from "./types/Patient"
import { PatientActivity } from "./types/PatientActivity"

export const createPatientHubApiClient = (api: ApiClient) => {
  return {
    getPatients: getPatients(api),
    createPatient: createPatient(api),
    updatePatient: updatePatient(api),
    deletePatient: deletePatient(api),
    getPatientActivities: getPatientActivities(api),
  }
}

export type GetPatientsRequest = ChunkedRequest &
  SortedRequest & {
    keyword?: string
    includeLastActivity?: boolean
  }

const getPatients = (api: ApiClient) => async (args: GetPatientsRequest) => {
  try {
    const req = createChunkedRequest(args)
    const res = await api.post<Patient[]>("/patients/search", req)

    return createChunkedResponse(req, res.data)
  } catch (err) {}
}

export type PatientCreateRequest = {
  firstName: string
  middleName?: string
  lastName: string
  gender: string
  birthDate: ISODate
  referringDentistId?: PractitionerId
  riskFactors?: string[]
  customRisks?: string[]
  oralHygiene?: PatientOralHygiene
  smokingStatus?: PatientSmokingStatus
}

const createPatient = (api: ApiClient) => async (patient: PatientCreateRequest): Promise<Patient | undefined> => {
  try {
    const response = await api.post<Patient>("patients", patient)
    return response.data
  } catch (err) {}
}

const updatePatient = (api: ApiClient) => async (updatedPatient: PatientFull) => {
  try {
    const res = await api.put<PatientFull>(`patients/${updatedPatient.id}`, updatedPatient)
    return res.data
  } catch (err) {}
}

const deletePatient = (api: ApiClient) => async (patientId?: PatientId): Promise<boolean> => {
  try {
    await api.delete<Patient[]>(`/patients/${patientId}`)

    return true
  } catch (err) {}

  return false
}

export type GetPatientActivitiesRequest = ChunkedRequest &
  SortedRequest & {
    patientId?: PatientId
    activityTypes?: ActivityType[]
    activitySource?: ActivitySource
  }

const getPatientActivities = (api: ApiClient) => async (args: GetPatientActivitiesRequest) => {
  try {
    const req = createChunkedRequest(args)
    const res = await api.post<PatientActivity[]>("/activity/search", req)

    return createChunkedResponse(req, res.data)
  } catch (err) {}
}

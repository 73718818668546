import { ReactNode } from "react"
import { motion } from "framer-motion"
import { useLocale } from "../../hooks/useLocale"
import styled from "styled-components"
import { ButtonArea } from "../buttons/ButtonArea"
import { theme } from "../../../theme"
import { TextBold } from "../text/TextBold"
import { Close } from "@material-ui/icons"

export type PopoverContainerProps = {
  title?: string
  show: boolean
  onClose: () => void
  children?: ReactNode
  crossIcon?: boolean
}

export const PopoverContainer = (props: PopoverContainerProps) => {
  const { title, children, onClose, crossIcon } = props
  const loc = useLocale()

  return (
    <DropdownMenu>
      {title && (
        <DropdownMenuHeader>
          <DropdownMenuTitle>
            <TextBold>{title}</TextBold>
          </DropdownMenuTitle>
          <DropdownMenuCancel onClick={onClose}>
            {crossIcon ? <Close /> : loc.oneportal.cardDropdown.actions.cancel.get()}
          </DropdownMenuCancel>
        </DropdownMenuHeader>
      )}
      <DropdownMenuContent>{children}</DropdownMenuContent>
    </DropdownMenu>
  )
}

const DropdownMenuCancel = styled(ButtonArea)`
  padding: ${theme.spacing.s};
  border-radius: ${theme.borderRadius};
  color: ${(props) => props.theme.color};
`

const DropdownMenu = styled(motion.div)`
  display: flex;
  flex-direction: column;
  min-width: 350px;
  color: ${(props) => props.theme.color};
  background: ${(props) => props.theme.backGround};
  padding: ${theme.spacing.l};
  border-radius: ${theme.borderRadius};
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.25);
  z-index: ${theme.zIndex.medium};
`

const DropdownMenuHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 0;
  margin-bottom: ${theme.spacing.l};
`

const DropdownMenuTitle = styled.span`
  color: ${theme.color.grey2};
`

const DropdownMenuContent = styled.div`
  padding-top: 0;
`

import { ApiClient } from "../../../oneportal/services/api/ApiClient"
import { createChunkedRequest } from "../../../oneportal/services/api/helpers/createChunkedRequest"
import { createChunkedResponse } from "../../../oneportal/services/api/helpers/createChunkedResponse"
import { ChunkedRequest } from "../../../oneportal/services/api/types/ChunkedRequest"
import { SortedRequest } from "../../../oneportal/services/api/types/SortedRequest"
import { ChunkedResponse } from "../../../oneportal/services/api/types/ChunkedResponse"
import { ClearCorrectCase } from "./types/ClearCorrectCase"

export const createClearCorrectApiClient = (api: ApiClient) => {
  return {
    getCases: getCases(api),
    getCaseUrl: getCaseUrl(api),
  }
}

export type GetCasesRequest = ChunkedRequest &
  SortedRequest & {
    keyword?: string
  }

const getCases = (api: ApiClient) => async (
  args?: GetCasesRequest
): Promise<ChunkedResponse<ClearCorrectCase> | undefined> => {
  try {
    const req = createChunkedRequest(args)
    const res = await api.post<ClearCorrectCase[]>(`/cases/search`, req)

    return createChunkedResponse(req, res.data)
  } catch (err) {}
}

const getCaseUrl = (api: ApiClient) => async (uuid: string): Promise<string> => {
  try {
    const response = await api.get<string>(`/cases/url/${uuid}`)

    return response.data
  } catch (err) {
    return ""
  }
}

import { Skeleton } from "@material-ui/lab"

export type FullWidthSkeletonProps = {
  height: number
}

const SKELETON_HEIGHT = 40 // px

export const FullWidthSkeleton = (props: FullWidthSkeletonProps) => (
  <>
    {new Array(Math.floor(props.height / SKELETON_HEIGHT)).fill(null).map((_, index) => (
      <Skeleton key={index} animation={"wave"} height={SKELETON_HEIGHT} width={"100%"} />
    ))}
  </>
)

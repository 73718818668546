import { History } from "history"
import { ReactPlugin } from "@microsoft/applicationinsights-react-js"
import { ApplicationInsights } from "@microsoft/applicationinsights-web"

export type CreateApplicationInsightsArgs = {
  key: string
  browserHistory: History
}

export const createApplicationInsights = (args: CreateApplicationInsightsArgs) => {
  const { key, browserHistory } = args

  const context = new ReactPlugin()
  const instance = new ApplicationInsights({
    config: {
      instrumentationKey: key,
      extensions: [context],
      extensionConfig: {
        [context.identifier]: { history: browserHistory },
      },
    },
  })
  instance.loadAppInsights()

  return { context, instance }
}

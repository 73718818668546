export enum PageSlug {
  Dashboard = "dashboard",
  Network = "network",
  Registry = "implant-registry",
  SmileInABox = "smile-in-a-box",
  ThreeShapeTrios = "3shape-trios",
  UploadingGuide = "uploading-guide",
  ClearCorrect = "clear-correct",
  CoDianostix = "codiagnostix",
  MeditIos = "medit-ios",
  HowToCompresszipFiles = "how-to-compresszip-files",
}

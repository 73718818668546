import { useEffect } from "react"
import styled from "styled-components"
import { DropdownValueVariant } from "../../../oneportal/components/dropdowns/inputs/DropdownValue"
import { RadioGroupPopover } from "../../../oneportal/components/dropdowns/inputs/RadioGroupPopover"
import { createMuiFormBinder } from "../../../oneportal/helpers/createMuiFormBinder"
import { useAvailableLanguages } from "../../../oneportal/hooks/useAvailableLanguages"
import { useLocale } from "../../../oneportal/hooks/useLocale"
import {
  dateFormatKey,
  toothPositionFormatKey,
  usePreferencesInterface,
} from "../../../oneportal/hooks/usePreferencesInterface"
import { useShowProgressNotification } from "../../../oneportal/hooks/useShowProgressNotification"
import { useTranslator } from "../../../oneportal/hooks/useTranslator"
import { useForm } from "../../../oneportal/libraries/use-form"
import { ToothPositionFormat } from "../../../registry/components/DentalChart/types/ToothPositionFormat"
import { FullWidthSkeleton } from "../../../siab/components/FullWidthSkeleton"
import { theme } from "../../../theme"
import { useCustomerPortalApiClient } from "../../hooks/useCustomerPortalApiClient"
import { UpdateMyProfileRequest } from "../../services/api/createCustomerPortalApiClient"
import { createPreferencesInterfaceForm, PreferencesInterfaceFormType } from "./createPreferencesInterfaceForm"
import { DateFormatPreference } from "../../services/api/types/DateFormatPreference"

export type PreferencesInterfaceFormProps = {
  isLanguageEnabled?: boolean
}

export const PreferencesInterfaceForm = (props: PreferencesInterfaceFormProps) => {
  const { isLanguageEnabled = true } = props
  const loc = useLocale()
  const api = useCustomerPortalApiClient()
  const translator = useTranslator()
  const { currentLanguage, currentToothPositionFormat, currentDateFormat, loading } = usePreferencesInterface()
  const form = useForm<PreferencesInterfaceFormType>(() => createPreferencesInterfaceForm())
  const bind = createMuiFormBinder(form)
  const progressNotification = useShowProgressNotification()

  const availableLanguages = useAvailableLanguages()
  const availableToothPositionFormats = [ToothPositionFormat.FDI, ToothPositionFormat.US]
  const availableDateFormats = Object.values(DateFormatPreference)

  const initForm = () => {
    form.set({
      language: currentLanguage,
      toothPositionFormat: currentToothPositionFormat,
      dateFormat: currentDateFormat,
    })
  }

  useEffect(() => {
    initForm()
  }, [])

  const handleFieldSubmit = (field: string) => async (newValue) => {
    form.setAt(field, newValue)
    await handleSubmit({ [field]: newValue })
  }

  const handleSubmit = async (newValues: Partial<UpdateMyProfileRequest>) => {
    try {
      progressNotification.startSaving()
      const updatedMyProfile = await api.updateMyProfile(newValues)

      if (updatedMyProfile) {
        form.set({ ...form.get(), ...newValues })

        if (newValues.language) {
          translator.changeLanguage(newValues.language)
        }
        if (newValues.toothPositionFormat) {
          localStorage.setItem(toothPositionFormatKey, newValues.toothPositionFormat.toString())
        }
        if (newValues.dateFormat) {
          sessionStorage.setItem(dateFormatKey, newValues.dateFormat.toString())
        }

        progressNotification.finishSaving()
      } else {
        initForm()
        progressNotification.setError()
      }
    } catch (err) {
      progressNotification.setError()
    }
  }

  return (
    <>
      {loading && <FullWidthSkeleton height={240} />}
      {!loading && (
        <>
          {isLanguageEnabled && (
            <Row>
              <Label>{loc.customerportal.myPreferences.labels.language.get()}</Label>
              <CustomRadioGroupPopover
                {...bind.radioGroupDropdown("language")}
                options={availableLanguages}
                formatOption={(option) => loc.customerportal.myPreferences.labels.getAt(option.toLocaleLowerCase())}
                onSubmit={handleFieldSubmit("language")}
                variant={DropdownValueVariant.LinkWithArrow}
              />
            </Row>
          )}
          <Row>
            <Label>{loc.customerportal.myPreferences.labels.dentalChartNumbering.get()}</Label>
            <CustomRadioGroupPopover
              {...bind.radioGroupDropdown("toothPositionFormat")}
              options={availableToothPositionFormats}
              onSubmit={handleFieldSubmit("toothPositionFormat")}
              variant={DropdownValueVariant.LinkWithArrow}
            />
          </Row>
          <Row>
            <Label>{loc.customerportal.myPreferences.labels.dateFormat.get()}</Label>
            <CustomRadioGroupPopover
              {...bind.radioGroupDropdown("dateFormat")}
              options={availableDateFormats}
              formatOption={(option) => loc.customerportal.myPreferences.labels.dateFormatPreference.getAt(option)}
              onSubmit={handleFieldSubmit("dateFormat")}
              variant={DropdownValueVariant.LinkWithArrow}
            />
          </Row>
        </>
      )}
    </>
  )
}

const Row = styled.div`
  display: flex;
  align-items: center;
  padding: ${theme.spacing.lx} 0;
  &:not(:last-child) {
    border-bottom: 1px solid ${theme.color.white1};
  }
`

const Label = styled.span`
  margin-top: ${theme.spacing.m};
  width: 250px;
  color: ${theme.color.black4};
  font-size: ${theme.fontSize.m};
`

const CustomRadioGroupPopover = styled(RadioGroupPopover)`
  flex-grow: 1;
`

import ReactJoyride, { CallBackProps, Step, TooltipRenderProps } from "react-joyride"
import styled, { css } from "styled-components"
import { Button } from "@material-ui/core"
import { ButtonPrimary } from "../../oneportal/components/buttons/ButtonPrimary"
import { Heading2 } from "../../oneportal/components/text/Heading2"
import { theme } from "../../theme"
import { useLocale } from "../../oneportal/hooks/useLocale"
import { useState } from "react"

export enum InstructionStorageKeys {
  Dashboard = "first-time-dashboard",
  Encounters = "first-time-encounter",
  EncounterDetails = "first-time-encounter-details",
  AddProductCard = "first-time-add-product-card",
  Register = "first-time-register",
  ThreeShape = "first-time-threeShape-alert",
  CreateCaseGuide = "first-time-siab-encounter",
}

export const toothNumberForInstruction = 11

export type IntroductionProps = {
  storageKey: InstructionStorageKeys
  steps: Step[]
  disableScrolling?: boolean
}

export const showInstruction = (key: InstructionStorageKeys, defaultValue = true): boolean =>
  JSON.parse(localStorage.getItem(key) ?? JSON.stringify(defaultValue))

export const setShowInstruction = (key: InstructionStorageKeys, value: boolean) =>
  localStorage.setItem(key, JSON.stringify(value))

export const Introduction = (props: IntroductionProps) => {
  const { storageKey, steps, disableScrolling = false } = props

  const [showIntroduction, setShowIntroduction] = useState(showInstruction(storageKey))

  const handleCallback = (data: CallBackProps) => {
    if (data.status === "skipped" || data.status === "finished") {
      setShowIntroduction(false)
      setShowInstruction(storageKey, false)
    }
  }

  return (
    <ReactJoyride
      steps={steps}
      showProgress
      continuous
      disableScrollParentFix
      run={showIntroduction}
      tooltipComponent={IntroductionTooltip}
      callback={handleCallback}
      scrollOffset={70}
      scrollToFirstStep={false}
      disableScrolling={disableScrolling}
    />
  )
}

const IntroductionTooltip = (props: TooltipRenderProps) => {
  const { step, size, index, continuous = true, backProps, skipProps, primaryProps, tooltipProps } = props

  const loc = useLocale()

  const nextButtonLabel =
    index + 1 === size
      ? loc.registry.introduction.actions.close.get({
          progress: `${size}/${size}`,
        })
      : loc.registry.introduction.actions.next.get({
          progress: `${index + 1}/${size}`,
        })
  const showSkipButton = index + 1 !== size && step.showSkipButton
  const showBackButton = index > 0 && !step.hideBackButton

  return (
    <TooltipRoot {...tooltipProps}>
      {step.title && <TooltipTitle>{step.title}</TooltipTitle>}
      <TooltipContent>{step.content}</TooltipContent>
      {!step.hideFooter && (
        <TooltipActions hasOnlyOneButton={!showSkipButton}>
          {showSkipButton && (
            <Button color="primary" {...skipProps}>
              {loc.registry.introduction.actions.dismiss.get()}
            </Button>
          )}

          <div>
            {showBackButton && (
              <Button color="primary" {...backProps}>
                {loc.registry.introduction.actions.back.get()}
              </Button>
            )}
            {continuous && <ButtonPrimary {...primaryProps}>{nextButtonLabel}</ButtonPrimary>}
          </div>
        </TooltipActions>
      )}
    </TooltipRoot>
  )
}

const TooltipRoot = styled.div`
  width: 472px;
  border-radius: ${theme.borderRadius};
  background-color: white;
  padding: ${theme.spacing.lx};
`

const TooltipActions = styled.div<{ hasOnlyOneButton: boolean }>`
  display: flex;
  justify-content: space-between;
  margin-top: ${theme.spacing.l};

  ${(p) =>
    p.hasOnlyOneButton &&
    css`
      justify-content: flex-end;
    `}
`

const TooltipTitle = styled(Heading2)`
  margin-bottom: ${theme.spacing.l};
`

const TooltipContent = styled.div``

export const introductionsElementIds = {
  dashboard: {
    profileButton: "profile-button",
    myServicesList: "my-services-list",
    connectedServices: "connected-services",
  },
  patientDetails: {
    createEncounterButton: "create-encounter-button",
  },
  encounterDetails: {
    introductionTooth: "introduction-tooth",
    procedureList: "procedure-lists",
    assignProductsButton: "assign-products-button",
    complicationsButton: "complications-button",
    guideButton: "guide-button",
    feedbackButton: "QSIFeedbackButton-btn", // Managed by Qualtrics
    emptyProductCard: "empty-product-card",
    progressNotificationChip: "progress-notification-chip",
  },
  cases: {
    addCaseButton: "add-case-button",
  },
}

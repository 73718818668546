import { useConfig } from "./useConfig"

const flags = useConfig().featureFlag

/* Customer portal features*/
export const useIntegrationFeatureFlag = () => flags.connectivity
export const useNetworkFeatureFlag = () => flags.network
export const useUpcomingShipmentsFeatureFlag = () => flags.upcomingShipments
export const useProfileV2FeatureFlag = () => flags.profileV2
export const useLanguageFrenchFeatureFlag = () => flags.languageFrench

/* SIAB features */
export const useSiabAppFeatureFlag = () => flags.siabApp
export const useAskForHelpFeatureFlag = () => flags.askForHelp
export const useUploadDocumentFeatureFlag = () => flags.uploadDocument
export const useShareCaseFeatureFlag = () => flags.shareCase
export const useInvoicesTabFeatureFlag = () => flags.invoicesTab
export const useAddressesInCreateV2AndDetails = () => flags.addressesInCreateV2AndDetails

import { getAppConfig } from "./config"
import { IntegrationName } from "./customerportal/services/api/types/Integration"
import { PageSlug } from "./oneportal/services/butterCms/types/PageSlug"

export type AppLinks = ReturnType<typeof getAppLinks>

// hide links inside a factory to prevent global imports
export const getAppLinks = () => {
  const config = getAppConfig()

  const oneportal = {
    home: () => "/",
    logout: () => "/logout",
    login: () => "/login",
    register: () => "/register",
    discovery: (pageSlug: PageSlug = ":pageSlug" as PageSlug) => `/discovery/${pageSlug}`,
    uploadingGuide: () => "/uploading-guide",
    unexpectedError: () => "/unexpected-error",
    authenticationError: () => "/authentication-error",
  }

  const customerportal = {
    dashboard: () => "/dashboard",
    reporting: () => "/reporting",

    myProfile: () => "/me/profile",
    myPreferences: () => "/me/preferences",
    myConsents: () => "/me/consents",
    myCommunicationPreference: () => "/me/communication-preferences",
    myPassword: () => "/me/change-password",

    myIntegration: () => "/me/connectivity",
    integrationRedirect: (integrationNameSlug: IntegrationName = ":integrationNameSlug" as IntegrationName) =>
      `/connectivity/${integrationNameSlug}/code`,
    threeShapePair: () => "/connectivity/three-shape/pair",

    patients: () => "/patients",
    serviceOrders: () => "/service-orders",

    organisationProfile: () => "/organisation/profile",
    organisationMembers: () => "/organisation/members",
    organisationNetwork: () => "/organisation/network",
    organisationPreferences: () => "/organisation/preferences",
  }

  const external = {
    changePassword: (redirectUrl: string) => config.changePasswordUrl(redirectUrl),
  }

  return {
    oneportal,
    customerportal,
    external,
  }
}

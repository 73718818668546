import { PublicClientApplication } from "@azure/msal-browser"
import { initializeApp } from "firebase/app"
import { createBrowserHistory } from "history"
import i18next from "i18next"
import languageDetector from "i18next-browser-languagedetector"
import { ReactNode } from "react"
import { initReactI18next } from "react-i18next"
import { getClearCorrectLinks } from "./clearcorrect/links"
import { getClearCorrectRoutes } from "./clearcorrect/routes"
import { createClearCorrectApiClient } from "./clearcorrect/services/api/createClearCorrectApiClient"
import { getAppConfig } from "./config"
import { createCustomerPortalApiClient } from "./customerportal/services/api/createCustomerPortalApiClient"
import { CustomerProfile } from "./customerportal/services/api/types/CustomerProfile"
import { getAppLinks } from "./links"
import { createAccessibleLocale, getAppLocales } from "./locales"
import { ProgressNotification } from "./oneportal/components/Navbar/types/ProgressNotification"
import { fetchMsalAccessToken } from "./oneportal/helpers/fetchMsalAccessToken"
import { translatorLogger } from "./oneportal/helpers/translatorLogger"
import { AccessTokenFetcher } from "./oneportal/helpers/types/AccessTokenFetcher"
import { Logger } from "./oneportal/libraries/logger"
import { createValue } from "./oneportal/libraries/value"
import { createApiClient } from "./oneportal/services/api/createApiClient"
import { createButterCmsService } from "./oneportal/services/butterCms/createButterCmsService"
import { createApplicationInsights } from "./oneportal/services/insights/createApplicationInsights"
import { createLogoutService } from "./oneportal/services/logout/createLogoutService"
import { getPatientsLinks } from "./patients/links"
import { getPatientsRoutes } from "./patients/routes"
import { createPatientHubApiClient } from "./patients/services/api/createPatientsHubApiClient"
import { getRegistryLinks } from "./registry/links"
import { getRegistryRoutes } from "./registry/routes"
import { createRegistryApiClient } from "./registry/services/api/createRegistryApiClient"
import { getAppRoutes } from "./routes"
import { getScanLinks } from "./scan/links"
import { getScanRoutes } from "./scan/routes"
import { getSiabLinks } from "./siab/links"
import { getSiabRoutes } from "./siab/routes"
import { createSiabApiClient } from "./siab/services/api/createSiabApiClient"
import { createCustomerPortalNoAuthApiClient } from "./customerportal/services/api/createCustomerPortalNoAuthApiClient"

const config = getAppConfig()
const locales = getAppLocales(config)

const links = getAppLinks()
const routes = getAppRoutes(config, links)

const siabLinks = getSiabLinks()
const routesSiab = getSiabRoutes(config, siabLinks)

const registryLinks = getRegistryLinks()
const routesRegistry = getRegistryRoutes(config, registryLinks)

const scanLinks = getScanLinks()
const routesScan = getScanRoutes(config, scanLinks)

const clearCorrectLinks = getClearCorrectLinks()
const routesClearCorrect = getClearCorrectRoutes(config, clearCorrectLinks)

const patientsLinks = getPatientsLinks()
const routesPatients = getPatientsRoutes(config, patientsLinks)

// controls the global progress overlay
const showProgressOverlay = createValue(true)
// controls progress bar that you see between page transitions
const showProgressIndicator = createValue(false)
// indicates that some api calls are being executed
const showApiCallsIndicator = createValue(false)
// controls progress bar that you see when update profile
const showProgressNotification = createValue<ProgressNotification>(ProgressNotification.None)
// indicates whether current page load is the first one, useful for animations
const isFirstPageLoad = createValue(true)
// controls title of navbar
const navbarTitle = createValue<ReactNode | undefined>(undefined)
// controls link of navbar
const navbarLink = createValue<string | undefined>(undefined)
// profile info
const profileInfo = createValue<CustomerProfile | undefined>(undefined)

// msal configuration
const msal = new PublicClientApplication({
  auth: {
    clientId: config.msal.clientId,
    authority: config.msal.authority,
    redirectUri: config.msal.redirectUri,
    knownAuthorities: config.msal.knownAuthorities,
  },
  cache: {
    cacheLocation: "localStorage",
  },
})
// scopes that are used during a login request
const msalScopes = config.msal.scopes

const fetchAccessToken: AccessTokenFetcher = (logger) => fetchMsalAccessToken({ msal, msalScopes, logger })

// customer portal api client
const customerPortalApiClient = createCustomerPortalApiClient(
  createApiClient({
    endpoint: config.api.customerPortalEndpoint,
    fetchAccessToken,
    logger: new Logger("CustomerPortal"),
    indicator: showApiCallsIndicator,
  })
)

// customer portal api client (without jwt token)
const customerPortalNoAuthApiClient = createCustomerPortalNoAuthApiClient(
  createApiClient({
    endpoint: config.api.customerPortalEndpoint,
    fetchAccessToken: async () => {
      return ""
    },
    logger: new Logger("CustomerPortalNoAuth"),
    indicator: showApiCallsIndicator,
  })
)

// registry api client
const registryApiClient = createRegistryApiClient(
  createApiClient({
    endpoint: config.api.registryEndpoint,
    fetchAccessToken,
    logger: new Logger("Registry"),
    indicator: showApiCallsIndicator,
  })
)

// siab api client
const siabApiClient = createSiabApiClient(
  createApiClient({
    endpoint: config.api.siabEndpoint,
    fetchAccessToken,
    logger: new Logger("Siab"),
    indicator: showApiCallsIndicator,
  }),
  fetchAccessToken
)

// clear correct api client
const clearCorrectApiClient = createClearCorrectApiClient(
  createApiClient({
    endpoint: config.api.clearCorrectEndpoint,
    fetchAccessToken,
    logger: new Logger("ClearCorrect"),
    indicator: showApiCallsIndicator,
  })
)

// patient hub api client
const patientHubApiClient = createPatientHubApiClient(
  createApiClient({
    endpoint: config.api.patientHubEndpoint,
    fetchAccessToken,
    logger: new Logger("PatientHub"),
    indicator: showApiCallsIndicator,
  })
)

const firebase = initializeApp(config.firebase.config)

const logoutService = createLogoutService(customerPortalApiClient, msal, firebase)

const butterCmsService = createButterCmsService({
  token: config.butterCms.token,
  defaultLocale: config.butterCms.defaultLocale,
})

const browserHistory = createBrowserHistory()
const applicationInsights = createApplicationInsights({ key: config.applicationInsights.key!, browserHistory })

const translator = i18next
  .createInstance({
    debug: true,
    fallbackLng: config.translator.fallbackLanguage,
    supportedLngs: Object.keys(locales),
    resources: locales,
    interpolation: { escapeValue: false },
  })
  .use(translatorLogger)
  .use(languageDetector)
  .use(initReactI18next)

// initialize translator immediately
translator.init()

// create a dynamically accessible dictionary for translations
const accessibleLocale = createAccessibleLocale(translator)

export const getAppContainer = () => ({
  // application wide config
  config,
  // routes for all modules
  routes,
  routesSiab,
  routesRegistry,
  routesScan,
  routesClearCorrect,
  routesPatients,
  // links for all modules
  links,
  siabLinks,
  registryLinks,
  scanLinks,
  clearCorrectLinks,
  patientsLinks,

  browserHistory,

  // global progress indicators
  showProgressOverlay,
  showProgressIndicator,
  showApiCallsIndicator,
  showProgressNotification,
  isFirstPageLoad,

  profileInfo,

  // title for navbar
  navbarTitle,

  // links for navbar
  navbarLink,

  // msal authentication
  msal,

  // i18n translation service
  translator,
  // local translations
  locales,
  // dynamically accessible locales object
  accessibleLocale,

  // api clients
  customerPortalApiClient,
  customerPortalNoAuthApiClient,
  registryApiClient,
  siabApiClient,
  clearCorrectApiClient,
  patientHubApiClient,

  // services
  butterCmsService,
  applicationInsights,

  // firebase
  firebase,

  // logout
  logoutService,
})

export type AppContainer = ReturnType<typeof getAppContainer>

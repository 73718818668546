import { ProgressNotification } from "../components/Navbar/types/ProgressNotification"
import { useValue } from "../libraries/use-value"
import { useContainer } from "./useContainer"

export const useShowProgressNotification = () => {
  const store = useValue(useContainer().showProgressNotification)
  const status = store.get()
  const isClear = status === ProgressNotification.None
  const isSaving = status === ProgressNotification.Saving
  const isSaved = status === ProgressNotification.Saved
  const isError = status === ProgressNotification.Error

  const clear = () => {
    store.set(ProgressNotification.None)
  }

  const startSaving = () => {
    store.set(ProgressNotification.Saving)
  }

  const finishSaving = () => {
    store.set(ProgressNotification.Saved)
  }

  const setError = () => {
    store.set(ProgressNotification.Error)
  }

  return { status, isClear, isSaving, isSaved, isError, clear, startSaving, finishSaving, setError }
}

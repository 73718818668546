import { useEffect } from "react"
import { useCustomerPortalApiClient } from "../../../customerportal/hooks/useCustomerPortalApiClient"
import { getMessaging, getToken, onMessage } from "firebase/messaging"
import { AddRecipientRequest } from "../../../customerportal/services/api/createCustomerPortalApiClient"
import { useConfig } from "../../hooks/useConfig"
import { useMsalProfile } from "../../hooks/useMsalProfile"
import { useFirebase } from "../../hooks/useFirebase"

export const Firebase = () => {
  const profile = useMsalProfile()
  if (profile) {
    const api = useCustomerPortalApiClient()
    const firebase = useFirebase()
    const messaging = getMessaging(firebase)
    const config = useConfig()

    const fetchToken = async () => {
      try {
        const serviceWorkerRegistration = await navigator.serviceWorker.register("/firebase-messaging-sw.js")
        const currentToken = await getToken(messaging, {
          vapidKey: config.firebase.vapidKey,
          serviceWorkerRegistration,
        })
        if (currentToken) {
          const request: AddRecipientRequest = { pushToken: currentToken }
          api.addRecipient(request)
          onMessage(messaging, (payload) => {
            if (payload.notification) {
              serviceWorkerRegistration.showNotification(payload.notification.title ?? "", {
                body: payload.notification.body,
                data: payload.data,
              })
            }
          })
        }
      } catch (err) {}
    }

    useEffect(() => {
      fetchToken()
    }, [])
  }
  return null
}

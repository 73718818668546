import { IPublicClientApplication } from "@azure/msal-browser"
import { deleteToken, getMessaging } from "firebase/messaging"
import { CustomerPortalApiClient } from "../../../customerportal/services/api/CustomerPortalApiClient"

export const createLogoutService = (api: CustomerPortalApiClient, msal: IPublicClientApplication, firebase: any) => {
  return {
    logout: logout(api, msal, firebase),
  }
}

const logout = (api: CustomerPortalApiClient, msal: IPublicClientApplication, firebase: any) => async () => {
  await api.logout()

  try {
    const messaging = getMessaging(firebase)
    await deleteToken(messaging)
  } catch (err) {}

  try {
    await msal.logout()
  } catch (err) {}
}

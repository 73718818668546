import { NavLink, NavLinkProps } from "react-router-dom"
import styled from "styled-components"
import React from "react"
import { ButtonBase } from "@material-ui/core"

// basically the same as the ButtonArea component, except that your
// goal is to create an area that is a link and not just a button
export const ButtonLinkArea = (props: NavLinkProps) => <ButtonBase component={StyledLink} {...props} />

const StyledLink = styled(NavLink)`
  position: relative;
  display: flex;
  flex-grow: inherit;
  color: inherit;
  text-decoration: inherit;
  cursor: pointer;
  font-size: inherit;
  align-items: inherit;
  justify-content: inherit;
`

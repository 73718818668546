import { KeyboardEvent, useEffect, useState } from "react"
import styled from "styled-components"
import { Divider, FormControlLabel, FormLabel, IconButton, InputAdornment, Switch, TextField } from "@material-ui/core"
import CloseOutlinedIcon from "@material-ui/icons/CloseOutlined"
import { createMuiFormBinder } from "../../../oneportal/helpers/createMuiFormBinder"
import { useLocale } from "../../../oneportal/hooks/useLocale"
import { useForm } from "../../../oneportal/libraries/use-form"
import { useAsync } from "../../../oneportal/libraries/user-async"
import { theme } from "../../../theme"
import { useCustomerPortalApiClient } from "../../hooks/useCustomerPortalApiClient"
import { AddRecipientRequest } from "../../services/api/createCustomerPortalApiClient"
import { RecipientPushType } from "../../services/api/types/RecipientPushType"
import { UserPreferenceRecipient } from "../../services/api/types/UserPreferenceRecipient"
import { createUpdateNotificationsForm } from "./createUpdateNotificationsForm"

export type UpdateNotificationsFormProps = {
  isValidating?: boolean
  onValidate?: (isValid: boolean) => void
}

export const UpdateNotificationsForm = (props: UpdateNotificationsFormProps) => {
  const { isValidating, onValidate } = props
  const loc = useLocale()
  const focusedPhoneNumberPlaceholder = loc.customerportal.myPreferences.labels.focusedPhoneNumberPlaceholder.get()
  const blurredPhoneNumberPlaceholder = loc.customerportal.myPreferences.labels.blurredPhoneNumberPlaceholder.get()
  const focusedEmailPlaceholder = loc.customerportal.myPreferences.labels.focusedEmailPlaceholder.get()
  const blurredEmailPlaceholder = loc.customerportal.myPreferences.labels.blurredEmailPlaceholder.get()
  const addAction = loc.customerportal.myPreferences.actions.add.get()
  const activateToDefineRecipients = loc.customerportal.myPreferences.labels.activateToDefineRecipients.get()
  const smsToggleLabel = loc.customerportal.myPreferences.labels.sms.get()
  const emailToggleLabel = loc.customerportal.myPreferences.labels.email.get()

  const api = useCustomerPortalApiClient()
  const form = useForm(createUpdateNotificationsForm(loc))
  const bind = createMuiFormBinder(form)

  const [placeholderPhoneNumber, setPlaceholderPhoneNumber] = useState(blurredPhoneNumberPlaceholder)
  const [placeholderEmail, setPlaceholderEmail] = useState(blurredEmailPlaceholder)

  const [loading, setLoading] = useState(false)
  const [isValidatingForm, setIsValidatingForm] = useState(false)

  const newPhoneNumberValue = form.get().newPhoneNumber?.trim()
  const newEmailValue = form.get().newEmail?.trim()

  const isChecked = (value: any): boolean => [true, 1, "1", "true", "on"].includes(value)

  const hasPhoneNumberError =
    (!!form.getErrorsAt("phoneNumbers") && !newPhoneNumberValue && isChecked(form.get().notifySms)) ||
    (!!form.getErrorsAt("newPhoneNumber") && !!newPhoneNumberValue && isChecked(form.get().notifySms))
  const hasEmailError =
    (!!form.getErrorsAt("emails") && !newEmailValue && isChecked(form.get().notifyEmail)) ||
    (!!form.getErrorsAt("newEmail") && isChecked(form.get().notifyEmail))

  useEffect(() => {
    setIsValidatingForm(!!isValidating)

    if (isValidatingForm) {
      validateForm()
      submitForm()
    }
    return () => {
      setIsValidatingForm(false)
    }
  }, [isValidating, isValidatingForm])

  const requestPreference = useAsync(async () => {
    const data = await api.getMyNotificationPreferences()

    form.setAt("notifyBrowser", data?.WEB_PUSH?.enabled || false)
    form.setAt("notifySms", data?.SMS?.enabled || isChecked(form.getAt("notifySms")))
    form.setAt("notifyEmail", data?.EMAIL?.enabled || isChecked(form.getAt("notifyEmail")))
    form.setAt("phoneNumbers", data?.SMS?.recipients?.filter((r) => r !== undefined) || [])
    form.setAt("emails", data?.EMAIL?.recipients?.filter((r) => r !== undefined) || [])

    return data
  })

  const addRecipient = async (request: AddRecipientRequest) => {
    const response = await api.addRecipient(request)
    if (response) {
      await api.toggleRecipient({
        recipientId: response.id,
        enabled: true,
      })
      await requestPreference.reload()
      if (request.email) {
        form.setAt("newEmail", "")
      }
      if (request.phoneNumber) {
        form.setAt("newPhoneNumber", "")
      }
    }
  }

  const validateForm = async () => {
    await form.validate()
  }

  const submitForm = async () => {
    const isValid = !hasPhoneNumberError && !hasEmailError

    if (isValid) {
      const request: AddRecipientRequest = {
        ...(newPhoneNumberValue ? { phoneNumber: newPhoneNumberValue } : {}),
        ...(newEmailValue ? { email: newEmailValue } : {}),
      }

      if (Object.keys(request).length > 0) {
        setLoading(true)
        await addRecipient(request)
        setLoading(false)
      }
    }
    if (onValidate) {
      onValidate(isValid)
    }
  }

  const updateNotifyStatus = (pushType: RecipientPushType): void => {
    if (pushType === RecipientPushType.EMAIL && form.get().emails.length === 0) {
      form.setAt("notifyEmail", false)
    }
    if (pushType === RecipientPushType.SMS && form.get().phoneNumbers.length === 0) {
      form.setAt("notifySms", false)
    }
  }

  const handleAddRecipient = async (pushType: RecipientPushType) => {
    const request = pushType === RecipientPushType.SMS ? { phoneNumber: newPhoneNumberValue } : { email: newEmailValue }
    setLoading(true)
    await addRecipient(request)
    setLoading(false)
  }

  const handleRemoveRecipient = async (recipient: UserPreferenceRecipient, pushType: RecipientPushType) => {
    setLoading(true)
    await api.removeRecipient({
      id: recipient.id,
    })
    await requestPreference.reload()
    updateNotifyStatus(pushType)
    setLoading(false)
  }

  const handleToggleGroup = async (pushType: RecipientPushType, enabled: boolean) => {
    if (pushType === RecipientPushType.EMAIL) {
      form.setAt("notifyEmail", enabled)
      if (!enabled) {
        form.setAt("newEmail", "")
        form.clearErrorsAt("newEmail")
        form.clearErrorsAt("emails")
      }
    } else {
      form.setAt("notifySms", enabled)
      if (!enabled) {
        form.setAt("newPhoneNumber", "")
        form.clearErrorsAt("newPhoneNumber")
        form.clearErrorsAt("phoneNumbers")
      }
    }
    if (enabled) {
      validateForm()
    }
    await api.toggleRecipientGroup({
      pushType,
      enabled,
    })
  }

  const handleAddNewOnPressEnter = (e: KeyboardEvent, field: RecipientPushType) => {
    if (e.key === "Enter") {
      e.preventDefault()
      e.stopPropagation()
      field === RecipientPushType.EMAIL ? handleAddNewEmail() : handleAddNewPhoneNumber()
    }
  }

  const handleAddNewPhoneNumber = async () => {
    await form.validate()

    if (!form.hasErrorsAt("newPhoneNumber") && newPhoneNumberValue) {
      await handleAddRecipient(RecipientPushType.SMS)
    }
  }

  const handleAddNewEmail = async () => {
    await form.validate()

    if (!form.hasErrorsAt("newEmail") && newEmailValue) {
      await handleAddRecipient(RecipientPushType.EMAIL)
    }
  }

  const applyAfterFormValidation = (callback: () => void): void => {
    form.validate().then(() => callback())
  }

  const handleFocusField = (field: RecipientPushType) => {
    field === RecipientPushType.EMAIL
      ? setPlaceholderEmail(focusedEmailPlaceholder)
      : setPlaceholderPhoneNumber(focusedPhoneNumberPlaceholder)
  }

  const handleBlurField = (field: RecipientPushType) => {
    applyAfterFormValidation(() => {
      if (field === RecipientPushType.EMAIL) {
        setPlaceholderEmail(blurredEmailPlaceholder)
      } else {
        setPlaceholderPhoneNumber(blurredPhoneNumberPlaceholder)
      }
    })
  }

  const combineErrors = (formControlName1: string, formControlName2: string): string => {
    return [...(form.getErrorsAt(formControlName1) || []), ...(form.getErrorsAt(formControlName2) || [])].join(", ")
  }

  return (
    <>
      <List>
        <FormControlLabel
          control={
            <Switch
              {...bind.switch("notifySms")}
              color="primary"
              onChange={(e) => handleToggleGroup(RecipientPushType.SMS, isChecked(e.target.checked))}
            />
          }
          label={smsToggleLabel}
        />
        <ListItems isDisplayed={!!form.getAt("notifySms")}>
          {form.get().phoneNumbers?.map((recipient: UserPreferenceRecipient) => (
            <RecipientContainer key={recipient.id}>
              <RecipientFormLabel>{recipient.phoneNumber}</RecipientFormLabel>
              <IconButton onClick={() => handleRemoveRecipient(recipient, RecipientPushType.SMS)}>
                <RemoveRecipientIcon />
              </IconButton>
            </RecipientContainer>
          ))}
          <RecipientFormControlLabel
            disabled={loading}
            control={
              <>
                <TextField
                  {...bind.textField("newPhoneNumber")}
                  error={hasPhoneNumberError}
                  helperText={
                    hasPhoneNumberError
                      ? !!newPhoneNumberValue
                        ? combineErrors("phoneNumbers", "newPhoneNumber")
                        : form.getErrorsAt("phoneNumbers")
                      : ""
                  }
                  placeholder={placeholderPhoneNumber}
                  onKeyUp={(event) => handleAddNewOnPressEnter(event, RecipientPushType.SMS)}
                  onFocus={() => {
                    handleFocusField(RecipientPushType.SMS)
                  }}
                  onBlur={() => {
                    handleBlurField(RecipientPushType.SMS)
                  }}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <AddButton onClick={handleAddNewPhoneNumber} hidden={loading}>
                          {addAction}
                        </AddButton>
                      </InputAdornment>
                    ),
                  }}
                  fullWidth
                />
              </>
            }
            label=""
          />
        </ListItems>
        <ActivateToDefineRecipients isDisplayed={!form.getAt("notifySms")}>
          {activateToDefineRecipients}
        </ActivateToDefineRecipients>
      </List>
      <Divider />
      <List>
        <FormControlLabel
          control={
            <Switch
              {...bind.switch("notifyEmail")}
              color="primary"
              onChange={(e) => handleToggleGroup(RecipientPushType.EMAIL, isChecked(e.target.checked))}
            />
          }
          label={emailToggleLabel}
        />
        <ListItems isDisplayed={!!form.getAt("notifyEmail")}>
          {form.get().emails?.map((recipient: UserPreferenceRecipient) => (
            <RecipientContainer key={recipient.id}>
              <RecipientFormLabel>{recipient.email}</RecipientFormLabel>
              <IconButton onClick={() => handleRemoveRecipient(recipient, RecipientPushType.EMAIL)}>
                <RemoveRecipientIcon />
              </IconButton>
            </RecipientContainer>
          ))}
          <RecipientFormControlLabel
            disabled={loading}
            control={
              <>
                <TextField
                  {...bind.textField("newEmail")}
                  error={hasEmailError}
                  helperText={hasEmailError ? combineErrors("emails", "newEmail") : ""}
                  fullWidth
                  onKeyUp={(event) => handleAddNewOnPressEnter(event, RecipientPushType.EMAIL)}
                  placeholder={placeholderEmail}
                  onFocus={() => {
                    handleFocusField(RecipientPushType.EMAIL)
                  }}
                  onBlur={() => {
                    handleBlurField(RecipientPushType.EMAIL)
                  }}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <AddButton onClick={handleAddNewEmail} hidden={loading}>
                          {addAction}
                        </AddButton>
                      </InputAdornment>
                    ),
                  }}
                />
              </>
            }
            label=""
          />
        </ListItems>
        <ActivateToDefineRecipients isDisplayed={!form.getAt("notifyEmail")}>
          {activateToDefineRecipients}
        </ActivateToDefineRecipients>
      </List>
    </>
  )
}

const List = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin: ${theme.spacing.xl} 0;

  > * {
    &:first-child {
      align-self: flex-start;
      width: 300px;
    }
  }
`

const ListItems = styled.div<{ isDisplayed: boolean }>`
  display: flex;
  flex-direction: column;
  flex-grow: 2;
  width: 100%;
  display: ${(props) => (props.isDisplayed ? "inherit" : "none")};
`

const RemoveRecipientIcon = styled(CloseOutlinedIcon)`
  font-size: ${theme.fontSize.xl};
`

const RecipientContainer = styled.div`
  display: flex;
  align-items: center;
`

const RecipientFormLabel = styled(FormLabel)`
  flex: 1 0 auto;
`

const RecipientFormControlLabel = styled(FormControlLabel)`
  margin-left: 0;
  .MuiFormHelperText-root.Mui-error {
    word-break: break-all;
  }
`

const AddButton = styled.div`
  text-transform: uppercase;
  cursor: pointer;
  color: ${theme.color.primary};
  font-family: ${theme.font.bold};
`

const ActivateToDefineRecipients = styled.span<{ isDisplayed: boolean }>`
  color: ${theme.color.grey2};
  display: ${(props) => (props.isDisplayed ? "inherit" : "none")};
  width: 100%;
`

import styled from "styled-components"
import React from "react"
import { ButtonLinkArea } from "../buttons/ButtonLinkArea"
import { theme } from "../../../theme"
import { NavbarProgressNotification } from "./NavbarProgressNotification"
import { useNavbarTitle } from "../../hooks/useNavbarTitle"
import { useNavbarLink } from "../../hooks/useNavbarLink"

export const NavbarLinks = () => {
  const navbarTitle = useNavbarTitle().get() ?? ""
  const navbarLink = useNavbarLink().get() ?? "#"

  return (
    <List>
      <ListItem>
        <ListItemLink to={navbarLink}>{navbarTitle}</ListItemLink>
        <NavbarProgressNotification />
      </ListItem>
    </List>
  )
}

const List = styled.ul`
  align-items: center;
  margin: 0px;
  display: flex;
  list-style: none;
  height: 100%;
  overflow-x: auto;
`

const ListItem = styled.li`
  display: flex;
  height: 40px;
  border-radius: ${theme.borderRadius};
  overflow: hidden;
  margin-right: ${theme.spacing.m};
  flex-shrink: 0;
  align-items: center;
`

const ListItemLink = styled(ButtonLinkArea)`
  align-items: center;
  font-size: ${theme.fontSize.xl};
  padding: 0px ${theme.spacing.m};
  margin-right: ${theme.spacing.m};
`

import { createTheme } from "@material-ui/core"
import { SnackbarOrigin } from "notistack"

// generate color accents: http://scg.ar-ch.org/
const palette = {
  blue1: "#003a73",
  blue2: "#2362a2", // default blue
  blue3: "#2a74b4",
  blue4: "#3486c6",
  blue5: "#3d92d2",
  blue6: "#5e90d4",
  blue7: "#54a1d8",
  blue8: "#6eb1de",
  blue9: "#e4f1f9",
  blue10: "#e6edef",
  blue11: "#6fb1de",
  blue12: "#bcdcf1",

  cyan1: "#72d3bb",
  cyan2: "#a8dcd0",
  cyan3: "#c3ebf4",

  black: "#000000",
  black1: "#171819", // default black
  black2: "#222425",
  black3: "#37393a",
  black4: "#686b6b",
  black5: "#36393a",
  black6: "#161819",

  grey1: "#696b6b",
  grey2: "#9a9c9c",
  grey3: "#cdcdce",
  grey4: "#5A5A5A",

  white: "#ffffff",
  white1: "#ebebeb",
  white2: "#f4f4f4",
  white3: "#fcfcfc", // default white
  white4: "#fafafa",

  red1: "#b3012d", // darken(red2, 5%)
  red2: "#cc0133", // default red
  red3: "#e50139", // lighten(red2, 5%)
  red4: "#f1dfe4",

  yellow1: "#f8ba00", // darken(yellow2, 5%)
  yellow2: "#ffc413", // default yellow
  yellow3: "#ffca2c", // lighten(yellow2, 5%)

  orange1: "#fca11b",

  pink1: "#ffe6f2",

  green1: "#006c49", // darken(green2, 5%)
  green2: "#00865a", // default green
  green3: "#009f6b", // lighten(green2, 5%)
  green4: "#bad36b",
  green5: "#f4f7e5",
  green6: "#41b39a",
  green7: "#749D00",

  mood1: "#fec5bb",
  mood2: "#d8e2dc",
  mood3: "#fec79a",
  mood4: "#bbd5e6",
  mood5: "#fad2e0",
  mood6: "#bee3db",
  mood7: "#ddbea9",
  mood8: "#cdc2eb",

  app1: "#fe9c8b",
  app8: "#a195ed",
}

// custom colors
const color = {
  ...palette,

  primaryDark: palette.blue1,
  primary: palette.blue2,
  primaryLight: palette.blue6,
  primary300: palette.blue11,
  primary100: palette.blue12,
  primary50: palette.blue9,

  errorLight: palette.red3,
  error: palette.red2,
  errorDark: palette.red1,

  warningDark: palette.yellow1,
  warning: palette.yellow2,
  warningLight: palette.yellow3,

  successDark: palette.green1,
  success: palette.green2,
  successLight: palette.green3,

  disabled: palette.grey3,
}

// custom spacings margins and paddings
const spacing = {
  xxs: "1px",
  xs: "2px",
  s: "4px",
  m: "8px",
  l: "16px",
  lx: "24px",
  xl: "32px",
  xxl: "64px",
}

const deviceWidth = {
  mobile: "425px",
  tablet: "768px",
  laptop: "1024px",
  desktop: "2560px",
}

const font = {
  regular: "TheSansSemiLight",
  bold: "TheSansBold",
}

const fontSize = {
  xxxl: "58px",
  xxl: "28px",
  xl: "20px",
  l: "18px",
  m: "15px",
  s: "12px",
  xs: "9px",
}

const lineHeight = {
  m: "24px",
  s: "16px",
}

const zIndex = {
  low: "1",
  medium: "100",
  high: "999",
  veryHigh: "2000",
  superHigh: "10000",
}

const borderRadius = "3px"

const transitionDuration = 300

// component specific settings
const ui = {
  main: {
    backgroundColor: color.white4,
  },
  progressIndicator: {
    backgroundColor: color.white3,
    height: "4px",
  },
  content: {
    width: "1440px",
    widthMedium: "900px",
    widthNarrow: "790px",
  },
  navbar: {
    height: "70px",
    backgroundColor: color.black1,
    logoWidth: "260px",
    logoWidthSmall: "70px",
    actionWidth: "70px",
  },
  whiteNavbar: {
    height: "106px",
    backgroundColor: "rgba(255, 255, 255, 0.8)",
  },
  blurBackdrop: {
    backgroundColor: "rgba(0, 29, 57, 0.1)",
    backdropFilter: "blur(4px)",
  },
  sidebar: {
    width: "260px",
    widthCollapsed: "70px",
  },
  panel: {
    width: "440px",
    widthCollapsed: "70px",
  },
  snackbar: {
    anchorOrigin: <SnackbarOrigin>{
      vertical: "bottom",
      horizontal: "center",
    },
  },
  bottombar: {
    height: "66px",
  },
}

// Material UI theme
const mui = createTheme({
  typography: {
    fontFamily: font.regular,
    htmlFontSize: parseInt(fontSize.m),
    h1: {
      fontSize: fontSize.xxl,
      lineHeight: "34px",
      fontFamily: font.bold,
    },
    h2: {
      fontSize: fontSize.xl,
      lineHeight: "24px",
      fontFamily: font.bold,
    },
    h3: {
      fontSize: fontSize.m,
      lineHeight: "24px",
      fontFamily: font.bold,
      textTransform: "uppercase",
    },
    h4: {
      fontSize: fontSize.m,
      lineHeight: "24px",
      fontFamily: font.bold,
    },
    body1: {
      fontSize: fontSize.m,
      lineHeight: "24px",
    },
    body2: {
      fontSize: fontSize.s,
      lineHeight: "16px",
    },
    button: {
      fontFamily: font.bold,
      fontSize: fontSize.m,
    },
  },
  palette: {
    common: {
      black: color.black1,
      white: color.white3,
    },

    background: {
      default: color.white2,
      paper: color.white3,
    },

    primary: {
      dark: color.primaryDark,
      main: color.primary,
      light: color.primaryLight,
    },
    error: {
      dark: color.errorDark,
      main: color.error,
      light: color.errorLight,
    },
    warning: {
      dark: color.warningDark,
      main: color.warning,
      light: color.warningLight,
    },
    success: {
      dark: color.successDark,
      main: color.success,
      light: color.successLight,
    },
  },
  shape: {
    borderRadius: 2,
  },
  overrides: {
    MuiButton: {
      root: {
        borderRadius: 3,
      },
    },
    MuiTooltip: {
      tooltip: {
        fontSize: fontSize.m,
      },
    },
    MuiRadio: {
      root: {
        "& svg:first-child": {
          transform: "scale(1)",
        },
      },
    },
    MuiTextField: {
      root: {
        "& .MuiOutlinedInput-root": {
          borderRadius: "3px",
        },
      },
    },
  },
})

// combine all theme objects into one, make sure there are no name conflicts
// with the Material UI theme properties, since they all share the same object
export const theme = {
  color,
  spacing,
  deviceWidth,
  font,
  fontSize,
  lineHeight,
  zIndex,
  borderRadius,
  transitionDuration,
  ui,
  mui,
}

import { ClearCorrectLinks } from "../../clearcorrect/links"
import { AppLinks } from "../../links"
import { PatientsLinks } from "../../patients/links"
import { RegistryLinks } from "../../registry/links"
import { ScanLinks } from "../../scan/links"
import { SiabLinks } from "../../siab/links"
import { useContainer } from "./useContainer"

export const useLinks = (): AppLinks => useContainer().links
export const useSiabLinks = (): SiabLinks => useContainer().siabLinks
export const useRegistryLinks = (): RegistryLinks => useContainer().registryLinks
export const useScanLinks = (): ScanLinks => useContainer().scanLinks
export const useClearCorrectLinks = (): ClearCorrectLinks => useContainer().clearCorrectLinks
export const usePatientsLinks = (): PatientsLinks => useContainer().patientsLinks

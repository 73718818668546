import { useLocale } from "../../../hooks/useLocale"
import { ReactNode } from "react"
import { ProgressStatus } from "../../../helpers/types/ProgressStatus"
import { ArrowDropDown } from "@material-ui/icons"
import { DropdownValueVariant } from "./DropdownValue"
import { Chip } from "@material-ui/core"
import AddIcon from "@material-ui/icons/Add"
import styled, { css } from "styled-components"
import { theme } from "../../../../theme"
import { ChipLabel } from "../../Chip/ChipLabel"
import { TextRegular } from "../../text/TextRegular"
import { ButtonArea } from "../../buttons/ButtonArea"

export type TextAlign = "start" | "center" | "end"

export enum ChipColor {
  default = "default",
  primary = "primary",
  secondary = "secondary",
}

export type PopoverValueProps = {
  onClick: (event) => void
  value: any
  containsHtml?: boolean
  placeholder?: ReactNode
  status?: ProgressStatus
  textAlign?: TextAlign
  readonly?: boolean
  variant?: DropdownValueVariant
  isAddress?: boolean
  popoverColor?: ChipColor
  className?: string
}

export const PopoverValue = (props: PopoverValueProps) => {
  const {
    placeholder,
    value,
    containsHtml = false,
    onClick,
    textAlign = "start",
    readonly = false,
    variant,
    isAddress,
    popoverColor,
    className,
  } = props
  const loc = useLocale()

  const elementType = readonly ? "div" : ButtonArea

  const renderCustomText = () => {
    if (containsHtml) {
      return <CustomText $readonly={readonly} $isAddress={isAddress} dangerouslySetInnerHTML={{ __html: value }} />
    }
    return (
      <CustomText $readonly={readonly} $isAddress={isAddress}>
        {value}
      </CustomText>
    )
  }

  const renderEmptyListItemContent = () => {
    if (variant === DropdownValueVariant.Chip) {
      return (
        <CustomChip
          label={
            <ChipLabel>
              <TextRegular>{placeholder}</TextRegular> <ArrowDropDown />
            </ChipLabel>
          }
          color={popoverColor}
        />
      )
    }

    return (
      placeholder || (
        <>
          <AddIcon />
          {loc.registry.patientProfile.actions.add.get()}
        </>
      )
    )
  }

  const renderListItemContent = () => {
    switch (variant) {
      case DropdownValueVariant.Chip:
        return (
          <CustomChip
            label={
              <ChipLabel>
                {!containsHtml && <TextRegular>{value}</TextRegular>}
                {containsHtml && <TextRegular dangerouslySetInnerHTML={{ __html: value }} />}
                <ArrowDropDown />
              </ChipLabel>
            }
            color={popoverColor}
          />
        )

      case DropdownValueVariant.LinkWithArrow:
        return (
          <CustomTextWithArrow>
            {renderCustomText()}
            {!readonly && <ArrowDropDown />}
          </CustomTextWithArrow>
        )

      case DropdownValueVariant.Link:
      default:
        return renderCustomText()
    }
  }

  return (
    <ListItemValue
      className={className}
      textAlign={textAlign}
      $readonly={readonly}
      onClick={onClick}
      $variant={variant}
    >
      {!value && !readonly ? (
        <ListItemValueAddLink>{renderEmptyListItemContent()}</ListItemValueAddLink>
      ) : (
        <ListItemValueAddLink as={elementType}>{renderListItemContent()}</ListItemValueAddLink>
      )}
    </ListItemValue>
  )
}

const ListItemValue = styled.span<{ textAlign: TextAlign; $readonly: boolean; $variant?: DropdownValueVariant }>`
  display: flex;
  align-items: center;
  color: ${theme.color.primary};
  font-size: ${theme.fontSize.m};
  line-height: 24px;
  ${(p) =>
    css`
      text-align: ${p.textAlign};
      flex-grow: ${p.$variant === DropdownValueVariant.LinkWithArrow ? 1 : "inherit"};
      &:hover {
        cursor: ${p.$readonly ? "default" : "pointer"};
      }
    `}
`

const ListItemValueAddLink = styled(ButtonArea)`
  display: flex;
  align-items: center;

  p {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  svg {
    font-size: ${theme.fontSize.m};
  }
`

const CustomText = styled(TextRegular)<{ $readonly: boolean; $isAddress: boolean | undefined }>`
  ${(p) =>
    css`
      font-family: ${p.$readonly || p.$isAddress ? theme.font.regular : theme.font.bold};
      color: ${p.$readonly || p.$isAddress ? theme.color.black1 : theme.color.primary};
      > div:first-child {
        color: ${p.$isAddress ? theme.color.grey1 : "inherit"};
      }
    `}
`

const CustomChip = styled(Chip)`
  ${(p) =>
    p.color === ChipColor.primary &&
    css`
      color: ${theme.color.white2};
      background: ${theme.color.black4};

      &:hover,
      &:focus {
        color: ${theme.color.white2};
        background: ${theme.color.black4};
      }
    `}
`

const CustomTextWithArrow = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: flex-start;
`

import styled from "styled-components"
import { ButtonBase } from "@material-ui/core"

// this component is meant to be used whenever you have something that is clickable,
// but you don't want to reset all the styles that are inherited from a button yourself
export const ButtonArea = styled(ButtonBase)`
  display: flex;
  flex-grow: inherit;
  align-items: inherit;
  justify-content: inherit;
  padding: 0px;
  margin: 0px;
  color: inherit;
  text-transform: inherit;
  font-weight: inherit;
  text-align: inherit;
  line-height: inherit;
  font-size: inherit;
  font-family: inherit;
  overflow: hidden;

  > span:first-child {
    height: 100%;
  }
`

import { ProcedureId } from "../oneportal/services/api/types/ProcedureId"
import { ComplicationId } from "../registry/services/api/types/ComplicationId"
import { EncounterId } from "../registry/services/api/types/EncounterId"
import { ProductAddType } from "../registry/services/api/types/ProductAddType"
import { ProductClass } from "../registry/services/api/types/ProductClass"

export type ScanLinks = ReturnType<typeof getScanLinks>

// hide links inside a factory to prevent global imports
export const getScanLinks = () => {
  return {
    scan: () => "/scan",
    scanProduct: (
      elementId: ProcedureId | ComplicationId = ":elementId" as any,
      productClass: ProductClass = ":productClass" as any
    ) => `/scan/${elementId}/${productClass}`,
    batchScan: (
      encounterId: EncounterId = ":encounterId" as EncounterId,
      productAddType: ProductAddType = ":productAddType" as ProductAddType
    ) => `/batchScan/${encounterId}/${productAddType}`,
  }
}

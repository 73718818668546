import { MainLayout } from "../layouts/MainLayout"
import { Heading2 } from "./text/Heading2"
import { useLocale } from "../hooks/useLocale"
import { PageHeader } from "./PageHeader/PageHeader"

export const RouteRenderErrorFallback = () => {
  const loc = useLocale()

  return (
    <MainLayout title="Error">
      <PageHeader>
        <Heading2>{loc.oneportal.router.messages.renderError.get()}</Heading2>
      </PageHeader>
    </MainLayout>
  )
}

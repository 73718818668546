import { useEffect, useRef, useState } from "react"
import { useMsalProfile } from "../hooks/useMsalProfile"
import { useLocation } from "react-router-dom"
import { GTMEvent, pushToDataLayer } from "../helpers/pushToDataLayer"

export const Qualtrix = () => {
  const [eventSent, setEventSent] = useState(false)
  const location = useLocation()
  const previousLocationPathnameRef = useRef<string | undefined>()

  const profile = useMsalProfile()

  useEffect(() => {
    if (!profile) return

    pushToDataLayer(GTMEvent.Navigate, profile, {
      previousLocation: previousLocationPathnameRef.current,
      currentLocation: location.pathname,
    })

    previousLocationPathnameRef.current = location.pathname
  }, [location.pathname])

  useEffect(() => {
    if (!profile || eventSent) {
      return
    }

    writeDataLayer({
      event: "page meta",
      userId: profile.id,
      email: profile.email,
      profileLanguage: profile.language,
      country: profile.countryCode,
      firstName: profile.firstName,
      lastName: profile.lastName,
    })

    setEventSent(true)
  }, [JSON.stringify(profile)])

  return null
}

const writeDataLayer = (data: Record<any, any>) => {
  try {
    window["dataLayer"] = window["dataLayer"] || []
    window["dataLayer"][0] = { ...(window["dataLayer"][0] ?? {}), ...data }
    window["dataUser"] = { ...(window["dataUser"] ?? {}), ...data }
  } catch (err) {
    console.error("Could not write into dataLayer", err)
  }
}

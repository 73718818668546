import { useContainer } from "./useContainer"

//TODO: 4804 - Split FE
export const useRoutes = () => [
  ...useContainer().routes,
  ...useContainer().routesSiab,
  ...useContainer().routesPatients,
  ...useContainer().routesRegistry,
  ...useContainer().routesScan,
  ...useContainer().routesClearCorrect,
]

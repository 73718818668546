import { AppConfig } from "./config"
import { AppLinks } from "./links"
import { MsalProfilePermissions } from "./oneportal/hooks/useMsalProfilePermissions"
import { RouteDefinition } from "./oneportal/libraries/router/types/RouteDefinition"
import { RouteGuard } from "./oneportal/libraries/router/types/RouteGuard"
import { RoutePolicy } from "./oneportal/libraries/router/types/RoutePolicy"
import { UserRole } from "./customerportal/services/api/types/UserRole"

const publicRoutes = <TContext>(routes: RouteDefinition<TContext>[]): RouteDefinition<TContext>[] =>
  routes.map((route) => ({ ...route, policy: RoutePolicy.ForGuests }))

const privateRoutes = <TContext>(routes: RouteDefinition<TContext>[]): RouteDefinition<TContext>[] =>
  routes.map((route) => ({ ...route, policy: RoutePolicy.ForAuthenticated }))

const otherRoutes = <TContext>(routes: RouteDefinition<TContext>[]): RouteDefinition<TContext>[] =>
  routes.map((route) => ({ ...route, policy: RoutePolicy.ForAnyone }))

// hide routes inside a factory to prevent global imports
export const getAppRoutes = (config: AppConfig, links: AppLinks): RouteDefinition<MsalProfilePermissions>[] => {
  const oneportal = [
    ...publicRoutes<MsalProfilePermissions>([
      {
        path: links.oneportal.login(),
        load: () => import("./oneportal/pages/Login"),
      },
      {
        path: links.oneportal.register(),
        load: () => import("./oneportal/pages/Register"),
      },
      {
        path: links.oneportal.authenticationError(),
        load: () => import("./oneportal/pages/AuthenticationFailed"),
      },
    ]),

    ...privateRoutes<MsalProfilePermissions>([
      {
        exact: true,
        path: links.oneportal.home(),
        redirect: links.customerportal.dashboard(),
      },
      {
        path: links.oneportal.logout(),
        load: () => import("./oneportal/pages/Logout"),
        guard: RouteGuard.RedirectHomeOrLogin,
      },
      {
        path: links.oneportal.discovery(),
        load: () => import("./oneportal/pages/Discovery"),
      },
      {
        path: links.oneportal.uploadingGuide(),
        load: () => import("./oneportal/pages/UploadingGuide"),
      },
      {
        path: links.oneportal.unexpectedError(),
        load: () => import("./oneportal/pages/UnexpectedError"),
      },
    ]),
  ]

  const customerportal = [
    ...privateRoutes<MsalProfilePermissions>([
      {
        // show dashboard where possible
        path: links.customerportal.dashboard(),
        load: () => import("./customerportal/pages/Dashboard"),
        check: (ctx) => ctx.showDashboard,
        guard: RouteGuard.RedirectHomeOrLogin,
      },
      {
        // redirect to registry if possible
        path: links.customerportal.dashboard(),
        redirect: "/registry",
        check: (ctx) => ctx.showRegistry,
      },
      {
        path: links.customerportal.myProfile(),
        load: () => import("./customerportal/pages/MyProfile"),
        check: (ctx) => ctx.showProfileV2,
      },
      {
        path: links.customerportal.myConsents(),
        load: () => import("./customerportal/pages/MyConsents"),
        check: (ctx) => ctx.showProfileV2,
      },
      {
        path: links.customerportal.myPreferences(),
        load: () => import("./customerportal/pages/MyPreferences"),
        check: (ctx) => ctx.showProfileV2,
      },
      {
        path: links.customerportal.organisationProfile(),
        load: () => import("./customerportal/pages/OrganisationProfile"),
      },
      {
        path: links.customerportal.organisationMembers(),
        load: () => import("./customerportal/pages/OrganisationMembers"),
        check: (ctx) => ctx.hasRole(UserRole.Admin),
      },
      {
        path: links.customerportal.organisationNetwork(),
        load: () => import("./customerportal/pages/OrganizationNetwork"),
        check: (ctx) => ctx.showNetwork,
      },
      {
        path: links.customerportal.organisationPreferences(),
        load: () => import("./customerportal/pages/OrganisationPreferences"),
        check: (ctx) => ctx.hasRole(UserRole.Admin),
      },
      {
        path: links.customerportal.myIntegration(),
        load: () => import("./customerportal/pages/MyIntegrations"),
        check: (ctx) => ctx.showIntegrations,
      },
      {
        path: links.customerportal.integrationRedirect(),
        load: () => import("./customerportal/pages/IntegrationLandingRedirectUri"),
        check: (ctx) => ctx.showIntegrations,
      },
      {
        path: links.customerportal.threeShapePair(),
        load: () => import("./customerportal/pages/IntegrationRedirectUri"),
        check: (ctx) => ctx.showIntegrations,
      },
      {
        path: links.customerportal.myCommunicationPreference(),
        load: () => import("./customerportal/pages/MyCommunicationPreferences"),
        check: (ctx) => ctx.showProfileV2,
      },
      {
        path: links.customerportal.myPassword(),
        load: () => import("./customerportal/pages/MyPassword"),
        check: (ctx) => ctx.showProfileV2,
      },
    ]),
  ]

  return [...oneportal, ...customerportal]
}

import { AppConfig } from "../config"
import { MsalProfilePermissions } from "../oneportal/hooks/useMsalProfilePermissions"
import { RouteDefinition } from "../oneportal/libraries/router/types/RouteDefinition"
import { RoutePolicy } from "../oneportal/libraries/router/types/RoutePolicy"
import { RegistryLinks } from "./links"

const privateRoutes = <TContext>(routes: RouteDefinition<TContext>[]): RouteDefinition<TContext>[] =>
  routes.map((route) => ({ ...route, policy: RoutePolicy.ForAuthenticated }))

// hide routes inside a factory to prevent global imports
export const getRegistryRoutes = (
  config: AppConfig,
  links: RegistryLinks
): RouteDefinition<MsalProfilePermissions>[] => {
  return [
    ...privateRoutes<MsalProfilePermissions>([
      {
        exact: true,
        path: links.registry(),
        load: () => import("./pages/Registry"),
        check: (ctx) => ctx.showRegistry,
      },
      {
        path: links.encounterCreate(),
        load: () => import("./pages/Registry"),
        check: (ctx) => ctx.showRegistry,
      },
      {
        path: links.patientsImportResult(),
        load: () => import("./pages/Registry"),
        check: (ctx) => ctx.showRegistry,
      },
      {
        path: links.patientsImportAnalysis(),
        load: () => import("./pages/Registry"),
        check: (ctx) => ctx.showRegistry,
      },
      {
        path: links.patientsDataExport(),
        load: () => import("./pages/Registry"),
        check: (ctx) => ctx.showRegistry,
      },
      {
        path: links.patientsImport(),
        load: () => import("./pages/Registry"),
        check: (ctx) => ctx.showRegistry,
      },
      {
        exact: true,
        path: links.patientDetails(),
        load: () => import("./pages/PatientDetails"),
        check: (ctx) => ctx.showRegistry,
      },
      {
        path: links.encounterDetails(),
        load: () => import("./pages/EncounterDetails"),
        check: (ctx) => ctx.showRegistry,
      },
      {
        path: links.surgeryReport(),
        load: () => import("./pages/SurgeryReport"),
        check: (ctx) => ctx.showRegistry,
      },
      {
        path: links.stockManagement(),
        load: () => import("./pages/StockManagement"),
        check: (ctx) => ctx.showRegistry,
      },
      {
        path: links.labOrder(),
        load: () => import("./pages/LabOrder"),
        check: (ctx) => ctx.showRegistry,
      },
    ]),
  ]
}
